/** @format */
import React from 'react'

import Logics from '../../../../logics'
import LUtils from '../../../../logics/utils'

export default function useServerData ({
  include = false,
  meta = {},
  model = ''
}) {
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    if (include) {
      fetchNext({ page: 1 })
    }
  }, [include])

  const fetchNext = React.useCallback(
    async ({ word = '', page = 1 } = {}) => {
      const result = await Logics.getAll({
        model,
        query: `?word=${word}&page=${page}`,
        furtif: true
      }).catch(() => null)

      if (result !== null) {
        setData({ ...result, word })
      }
    },
    [setData]
  )

  const fetchNextById = React.useCallback(
    ({ id = null } = {}) => {
      if (id != null) {
        return Promise.resolve(
          Logics.getAll({
            model,
            query: LUtils.toQueryStringFilter({
              filter: { [meta.item_key]: { op: '=', value: id } }
            }),
            furtif: true
          })
            .then(({ data = [] } = {}) => (data || [])[0])
            .catch(() => null)
        )
      }
      return Promise.reject(null)
    },
    [model, meta]
  )

  return include
    ? {
        props: {
          ...data,
          ...meta,
          fetchNext,
          fetchNextById
        }
      }
    : {}
}
