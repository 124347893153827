/** @format */

import React from 'react';

export default function CheckListTitle({ title }) {
  return (
    <span className={'h3 text-info'}>
      Filtrer suivant&nbsp;: &ldquo;&nbsp;<i>{title}</i>&nbsp;&rdquo;
    </span>
  );
}
