/** @format */

import axios from 'axios'

export default {
  connect (data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/modo/login', data)
        .then(r => {
          if (r && r.data && r.data.s == 'ok') {
            resolve(r.data.payload.user)
            this.setAxiosAuth(r.data.payload.access_token)
          } else {
            reject(r.error || r.data.error || 'wrongtoken')
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  checkToken () {
    const tk = localStorage.bagheram_mdg

    if (tk) {
      axios.defaults.headers.common.Authorization = tk
    } else {
      return Promise.reject()
    }

    return new Promise((resolve, reject) => {
      axios
        .get('/v1/modo/profile')
        .then(r => {
          if (r.data && r.data.s == 'ok') {
            resolve({ r: r.data.payload, tk })
          } else {
            reject(r.error || r.data.error || 'wrongtoken')
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  logout () {
    localStorage.removeItem('bagheram_mdg')
    return Promise.resolve()
  },
  setAxiosAuth ({ token, type }) {
    const tk = type + ' ' + token
    axios.defaults.headers.common.Authorization = tk
    localStorage.bagheram_mdg = tk
  }
}
