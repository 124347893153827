/** @format */

import React, { useEffect, useState } from 'react'
import { mapActions, mapGetters } from '../store'
import { NavLink } from 'react-router-dom'

import BagheramCompareDaterangepicker from '../components/admin/Bagheram-compare-daterangepicker'
import BagheramChart from '../components/admin/Bagheram-chart'

import Logics from '../logics'
const moment = window.moment

async function getDashboardStats ({
  _init = false,
  start = null,
  end = null,
  diff = null,
  cb = null
} = {}) {
  const _unit = 'days'
  let _date = {}
  let _diff = 0

  if (_init || !start) {
    _date.start = moment(!end ? undefined : toISOString(end)).subtract(
      diff === 0 ? 1 : diff || 30,
      _unit
    )
  } else {
    _date.start = moment(toISOString(start))
    if (diff === 0) {
      _date.start.add(1, _unit)
    }
  }
  if (_init || !end) {
    _date.end = !start
      ? moment()
      : moment(toISOString(start)).add(diff === 0 ? 1 : diff || 30, _unit)
  } else {
    _date.end = moment(toISOString(end))
    if (diff === 0) {
      _date.end = _date.end.subtract(1, _unit)
    }
  }
  _diff = Math.abs(_date.end.diff(_date.start, _unit))

  if (typeof cb === 'function') {
    cb({
      start: _date.start.clone().format('DD/MM/YYYY'),
      end: _date.end.clone().format('DD/MM/YYYY'),
      diff: _diff
    })
  }

  const { menu: currMenu = {} } = await Logics.getDashboard({
    start: _date.start.clone().format('DD/MM/YYYY'),
    end: _date.end.clone().add(1, _unit).format('DD/MM/YYYY')
  })
  const { menu: prevMenu = {} } = await Logics.getDashboard({
    start: _date.start.clone().subtract(_diff, _unit).format('DD/MM/YYYY'),
    end: _date.start.clone().add(1, _unit).format('DD/MM/YYYY')
  })
  return {
    date: {
      start: _date.start.clone().format('DD/MM/YYYY'),
      end: _date.end.clone().format('DD/MM/YYYY'),
      diff: _diff
    },
    menu: Object.keys(currMenu || {}).reduce(
      (acc, v) => {
        return {
          ...acc,
          [v]: {
            ...acc[v],
            variation: toPercentage({
              v,
              v1: (prevMenu || {})[v].total,
              v2: acc[v].total,
              digits: 2
            })
          }
        }
      },
      { ...currMenu }
    )
  }
}

function toISOString (date) {
  return date && date.split !== undefined
    ? date.split('/').reverse().join('-')
    : date
}

function toPercentage ({ v, v1, v2, digits = 2 } = {}) {
  const r = v1 ? ((v2 - v1) / v1) * 100 : 0
  return typeof r === 'number'
    ? `${Number.isInteger(r) ? r : r.toFixed(digits)}%`
    : r
}

function genUrl2 (key = '', { _date = null, _query = '' } = {}) {
  if (_date !== null && typeof _date === 'object') {
    let _v = { ..._date }
    const q = new URLSearchParams(_query)
    if (q.has(key)) {
      _v = { ..._v, ...JSON.parse(q.get(key)) }
    }
    q.set(key, JSON.stringify(_v))
    return q.toString()
  } else {
    return ''
  }
}

function Dashboard (props = {}) {
  const updateDashboard = mapActions('updateDashboard')
  const dashboard = mapGetters('dashboard')

  const [mounted, setMounted] = useState(false)
  const [modeCompare, setModeCompare] = useState({ first: null, second: null })

  const { history } = props

  const query = props.location.query

  const [date, setDate] = React.useState({ created_at: query })

  const {
    _window: { width: sw }
  } = useWindowDimensions()

  React.useEffect(() => {
    setMounted(false)
    getDashboardStats({
      _init: !Boolean(dashboard.date),
      ...(dashboard.date || {}),
      cb: date => {
        setDate(() => {
          return { created_at: date }
        })
      }
    }).then(({ menu, date: { op, ...dateInterval } } = {}) => {
      updateDashboard({ menu, dateInterval })
      setMounted(true)
    })
  }, [])

  const _next = React.useCallback(
    (url = '') => {
      history.push(url)
    },
    [history]
  )

  const load = React.useCallback(({ op = null, ...date } = {}) => {
    setMounted(false)
    getDashboardStats({
      _init: false,
      ...date,
      cb: date => {
        setDate(() => {
          return { created_at: date }
        })
      }
    }).then(({ menu, date: { op, ...dateInterval } } = {}) => {
      updateDashboard({ menu, dateInterval })
      if (dateInterval.start || dateInterval.end) {
        history.push(
          `/admin?start=${dateInterval.start}&end=${dateInterval.end}&op=${op}`
        )
      } else {
        history.push('/admin')
      }
      setTimeout(() => {
        setMounted(true)
      }, 1000)
    })
  }, [])

  const onCompare = React.useCallback(({ first, second } = {}) => {
    setModeCompare({ first, second })
  }, [])

  const onCloseCompare = React.useCallback(() => {
    setModeCompare({ first: null, second: null })
  }, [])

  const loadNext = React.useCallback(() => {
    const { created_at: { end = null, diff } = {} } = date || {}
    if (end !== null) {
      load({ start: end, diff })
    }
  }, [date])

  const loadPrevious = React.useCallback(() => {
    const { created_at: { start = null, diff } = {} } = date || {}
    if (start !== null) {
      load({ end: start, diff })
    }
  }, [date])

  const disableForwardDate = React.useMemo(() => {
    return (
      moment(toISOString((date || {}).created_at.end) || undefined).diff(
        moment(),
        'days'
      ) >= 0
    )
  }, [date])

  const _TABLE = React.useMemo(() => {
    const { created_at: { start = null, end = null, diff } = {} } = date || {}
    return (
      <div
        className='flex-row'
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <button
          className={'btn btn-primary flex-row'}
          style={{
            marginRight: '5px'
          }}
          onClick={loadPrevious}
        >
          {sw > 1300 ? (
            <>
              <span className={'fa fa-arrow-left'} />
              <span className={'text-capitalize'}>
                &nbsp;Reculer&nbsp;
                <span className={'text-lowercase'}>de {diff || 1} jours</span>
              </span>
            </>
          ) : (
            <>
              <span className={'fa fa-arrow-left'} />
              <span className={'text-lowercase'}>&nbsp;-{diff || 1}j</span>
            </>
          )}
        </button>
        <h4 style={{ padding: '0px 10px' }}>
          <center>
            <span className={'text-capitalize'}>évolution</span> des données sur
            &nbsp;<b>{diff || 1}</b> jour(s): du&nbsp;
            <span
              className='text-primary'
              style={{ fontStyle: 'italic !important', fontWeight: 'bolder' }}
            >
              {start}
            </span>
            {diff > 0 && (
              <>
                <span>&nbsp;à&nbsp;</span>
                <span
                  className='text-primary'
                  style={{
                    fontStyle: 'italic !important',
                    fontWeight: 'bolder'
                  }}
                >
                  {end}
                </span>
              </>
            )}
          </center>
        </h4>
        <div className={'flex-row'} style={{ justifyContent: 'flex-end' }}>
          <button
            className={'btn btn-primary'}
            disabled={disableForwardDate}
            onClick={loadNext}
          >
            {sw > 1300 ? (
              <>
                <span className={'text-capitalize'}>
                  &nbsp;Avancer
                  <span className={'text-lowercase'}>
                    &nbsp;de {diff || 1} jours
                  </span>
                  &nbsp;
                </span>
                <span className={'fa fa-arrow-right'} />
              </>
            ) : (
              <>
                <span className={'text-lowercase'}>+{diff || 1}j&nbsp;</span>
                <span className={'fa fa-arrow-right'} />
              </>
            )}
          </button>
        </div>
      </div>
    )
  }, [date, disableForwardDate, sw])

  return (
    <section className='content'>
      <section className='content-header'>
        <h1>
          Tableau de bord
          <small>Paneau de contrôle</small>
        </h1>
        <ol className='breadcrumb'>
          <li>
            <a href='#'>
              <i className='fa fa-dashboard' /> Accueil
            </a>
          </li>
          <li className='active'>Tableau de bord</li>
        </ol>
      </section>

      <section className='content'>
        <div className='col-12' style={{ padding: '15px 0px' }}>
          <div className='col-12'>
            <BagheramCompareDaterangepicker
              value={date.created_at}
              onChange={load}
              onCompare={onCompare}
              onCloseCompare={onCloseCompare}
              validate
            />
          </div>
        </div>
        <div className='col-12 row'>
          <div className='col-xs-12' style={{ margin: '10px 0px' }}>
            {_TABLE}
          </div>
        </div>
        <div className='row' style={{ marginTop: '15px' }}>
          {mounted &&
            dashboard.menu
              .filter(m => m.model && !dashboard.hideMenu[m.model])
              .map((m, i) => (
                <div key={i} className='col-lg-3 col-xs-6'>
                  <div
                    className={`small-box bg-${
                      m.bgc || 'aqua'
                    } bagheram-box-dashboard`}
                  >
                    <div className='inner'>
                      <span
                        className='flex-row'
                        style={{
                          justifyContent: 'flex-start'
                        }}
                      >
                        <h3>{m.total}</h3>
                        <div
                          className='flex-column'
                          style={{
                            marginLeft: '5px',
                            fontWeight: 200,
                            fontSize: '30px',
                            overflow: 'hidden',
                            width: 'inherit',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          <span>({m.variation})</span>
                          <span style={{ fontSize: '10px' }}>
                            &nbsp;à la période antérieure identique
                          </span>
                        </div>
                      </span>
                      <div className='truncate'>{m.title2 || m.title}</div>
                      <div
                        style={{ maxWidth: '110px', display: 'inline-block' }}
                      >
                        {m.categories && (
                          <span style={{ fontSize: '10px' }}>
                            {' '}
                            (Majeur: {m.categories.major} Mineur:{' '}
                            {m.categories.minor})
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='icon'>
                      <i className={m.icon || 'ion ion-bag'} />
                    </div>
                    <NavLink
                      to={`/admin/${m.model}?${genUrl2('filter', {
                        _date: date,
                        _query: m.query || ''
                      })}`}
                      className='small-box-footer'
                    >
                      Plus d'infos <i className='fa fa-arrow-circle-right' />
                    </NavLink>
                  </div>
                </div>
              ))}
          {!mounted && (
            <div
              className='col-12 d-flex flex-row'
              style={{ height: '150px', alignItems: 'center' }}
            >
              <div className='spinner'>
                <div
                  className='double-bounce1'
                  style={{ backgroundColor: 'orange' }}
                />
                <div
                  className='double-bounce2'
                  style={{ backgroundColor: 'red' }}
                />
              </div>
            </div>
          )}
        </div>
        <div className='col-12 row'>
          <div className='col-xs-12' style={{ margin: '30px 0px 0px 0px' }}>
            <BagheramChart />
          </div>
        </div>
      </section>
    </section>
  )
}

function useWindowDimensions () {
  const [dimensions, setDimensions] = React.useState({})

  const getWindowDimensions = React.useCallback(() => {
    return {
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    }
  }, [])

  React.useEffect(() => {
    function handleWindowResize (e = null) {
      if (e) {
        e.preventDefault()
      }
      setDimensions(() => getWindowDimensions())
    }

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    _window: {
      ...dimensions
    }
  }
}

export default Dashboard
