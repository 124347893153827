import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

export default withRouter(props => {
  const [id, setId] = useState(props.field)

  function onChange (value) {
    console.log(value)
    if (props.onChange && typeof props.onChange == 'function') {
      props.onChange(value)
    }
  }

  useEffect(() => {
    console.log({ datetimepicker: id })

    window.jQuery(`#${id}`).datepicker({
      format: 'dd/mm/yyyy',
      todayHighlight: true,
      autoclose: true,
      clearBtn: true,
      todayBtn: true,
      title: props.field,
      language: 'fr',
      startDate: props.startDate || '01.01.2000',
      templates: {
        leftArrow: '&lt;',
        rightArrow: '&gt;'
      }
    })

    window.jQuery(`#${id}`).on('change', function (e) {
      onChange(e.target.value)
    })
  }, [props.field])

  return (
    <div className='input-group date' data-provide='datepicker' id={id}>
      <input
        type='text'
        className='form-control'
        ref={props.forwardRef}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
      />
      <div className='input-group-addon'>
        <span className='fa fa-calendar' />
      </div>
    </div>
  )
})
