import React from 'react';
import ReactDOM from 'react-dom';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './app-back.scss';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

global.log0 = console.log;

console.log = global.log = function () {
  // return;
  if (arguments && (arguments[0] == 2 || arguments[0] == 'AXIOS')) {
    global.log0.apply(null, arguments);
  }
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
