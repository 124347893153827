/** @format */

import Constants from '../constants'
const moment = window.moment

const OBJ = {
  checkUrl (str) {
    return !str
      ? str
      : str.indexOf('http') < 0
      ? `${Constants.host}/uploads/users/img/${str}`
      : str
  },
  objectEquals (x, y) {
    'use strict'

    var self = this

    if (x === null || x === undefined || y === null || y === undefined) {
      return x === y
    }

    if (x.constructor !== y.constructor) {
      return false
    }

    if (x instanceof Function) {
      return x === y
    }

    if (x instanceof RegExp) {
      return x === y
    }

    if (x === y || x.valueOf() === y.valueOf()) {
      return true
    }

    if (Array.isArray(x) && x.length !== y.length) {
      return false
    }

    if (x instanceof Date) {
      return false
    }

    if (!(x instanceof Object)) {
      return false
    }

    if (!(y instanceof Object)) {
      return false
    }

    var p = Object.keys(x)

    return (
      Object.keys(y).every(function (i) {
        return p.indexOf(i) !== -1
      }) &&
      p.every(function (i) {
        return self.objectEquals(x[i], y[i])
      })
    )
  },
  sortBy (arr, k, rvrs, prmr) {
    function sort_by (field, reverse, primer) {
      const key = primer
        ? function (x) {
            return primer(x[field])
          }
        : function (x) {
            return x[field]
          }

      reverse = !reverse ? 1 : -1

      return function (a, b) {
        return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a))
      }
    }

    return arr.sort(sort_by(k, rvrs || false, prmr || (a => a.toUpperCase())))
  },
  objectDiff (obj1, obj2, strict) {
    var self = this
    // Make sure an object to compare is provided
    if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
      return obj1
    }

    //
    // Variables
    //

    var diffs = {}
    var key

    var arraysMatch = function (arr1, arr2) {
      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) return false

      // Check if all items exist and are in the same order
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false
      }

      // Otherwise, return true
      return true
    }

    //
    // Compare our objects
    //
    var compare = function (item1, item2, key) {
      // Get the object type
      var type1 = Object.prototype.toString.call(item1)
      var type2 = Object.prototype.toString.call(item2)

      // If type2 is undefined it has been removed
      if (type2 === '[object Undefined]') {
        diffs[key] = null
        return
      }

      // If items are different types
      if (type1 !== type2) {
        // number
        if (item1 != item2 || strict != false) {
          diffs[key] = item2
        }
        return
      }

      // If an object, compare recursively
      if (type1 === '[object Object]') {
        var objDiff = self.objectDiff(item1, item2)
        if (Object.keys(objDiff).length > 0) {
          diffs[key] = objDiff
        }
        return
      }

      // If an array, compare
      if (type1 === '[object Array]') {
        if (!arraysMatch(item1, item2)) {
          diffs[key] = item2
        }
        return
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (type1 === '[object Function]') {
        if (item1.toString() !== item2.toString()) {
          diffs[key] = item2
        }
      } else {
        if (item1 !== item2) {
          diffs[key] = item2
        }
      }
    }

    // Loop through the first object
    for (key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        compare(obj1[key], obj2[key], key)
      }
    }

    // Loop through the second object and find missing items
    for (key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (!obj1[key] && obj1[key] !== obj2[key]) {
          diffs[key] = obj2[key]
        }
      }
    }

    // Return the object of differences
    return diffs
  },
  capitalize (str) {
    return str && str.charAt(0).toUpperCase() + str.slice(1)
  },
  formatDate (date) {
    if (!date) return
    if (typeof date == 'string') {
      date = new Date(date)
    }

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    const label = date.toLocaleDateString('fr-FR', options)
    return label.charAt(0).toUpperCase() + label.slice(1)
  },
  pad (s) {
    return s < 10 ? '0' + s : s
  },
  convertDate (inputFormat, split) {
    if (!inputFormat) return
    var d = new Date(inputFormat)
    return [
      this.pad(d.getDate()),
      this.pad(d.getMonth() + 1),
      d.getFullYear()
    ].join(split || '-')
  },
  formatImage (imageStr) {
    imageStr = String(imageStr || '')

    if (imageStr == '') return imageStr

    if (imageStr.indexOf('//') == -1) {
      imageStr = `${Constants.host}/uploads/users/img/${imageStr}`
    } else {
      if (imageStr.indexOf('http') == -1) {
        imageStr = 'https:' + imageStr
      }
    }

    return imageStr
  },
  getGenre (user_gender) {
    return parseInt(user_gender) == 1
      ? 'Apprentie Bagherameur'
      : 'Apprentie Bagherameuse'
  },
  genTimeFromSecond (second) {
    if (!second || second <= 0) return

    const minutes = Math.floor(second / 60)
    const seconds = Math.floor(second - minutes * 60)
    const currentTime =
      (minutes <= 0 ? '00' : minutes) +
      ':' +
      (seconds <= 9 ? '0' + seconds : seconds)
    return currentTime
  },
  genDuration (message_date) {
    var LABELS = {
      there_is_year: 'Cette année | Il y a un an | Il y a {n} ans',
      there_is_month: 'Ce mois-ci | Il y a un mois | Il y a {n} mois',
      there_is_week: 'Cette semaine | Il y a une semaine | Il y a {n} semaines',
      there_is_day: "Aujourd'hui | Hier | Il y a {n} jours",
      there_is_hour: 'Cette heure | Il y a une heure | Il y a {n} heures',
      there_is_minute: 'Maintenant | Il y a une minute | Il y a {n} minutes'
    }

    function $tc (lbl, v) {
      if (LABELS[lbl]) {
        const label = LABELS[lbl].split('|')
        if (v >= 0) {
          lbl = v >= label.length - 1 ? label[label.length - 1] : label[v]

          return lbl.replace('{n}', v)
        }
      }

      return lbl
    }

    var text = ''
    var now = moment().format('DD/MM/YYYY HH:mm:ss')
    var then = moment(message_date).format('DD/MM/YYYY HH:mm:ss')

    const futureDay = moment(
      moment.utc(message_date).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    )

    const timeDiff = moment().diff(futureDay)

    const res = moment.utc(
      moment(now, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(then, 'DD/MM/YYYY HH:mm:ss')
      )
    )

    const theDiffObject = {
      years: moment.duration(timeDiff).years(),
      months: moment.duration(timeDiff).months(),
      weeks: moment.duration(timeDiff).weeks(),
      days: moment.duration(timeDiff).days()
    }

    const duration = {
      ...theDiffObject,
      hours: moment.duration(res.format('HH:mm:ss')).hours(),
      minutes: moment.duration(res.format('HH:mm:ss')).minutes(),
      seconds: moment.duration(res.format('HH:mm:ss')).seconds()
    }
    if (duration.years > 0) {
      text = $tc(duration.years, 'there_is_year')
    } else if (duration.months > 0) {
      text = $tc('there_is_month', duration.months)
    } else if (duration.weeks > 0) {
      text = $tc('there_is_week', duration.weeks)
    } else if (duration.days > 0) {
      text =
        $tc('there_is_day', duration.days) +
        ' à ' +
        moment(message_date).format('HH:mm')
    } else if (duration.hours > 0) {
      text = $tc('there_is_hour', duration.hours)
    } else if (duration.minutes > 0) {
      if (duration.minutes <= 10) {
        text = $tc('there_is_minute', duration.minutes)
      } else {
        text = moment(message_date).format('HH:mm')
      }
    } else {
      text = "A l'instant"
    }

    return text
  },
  range (start = 1, end) {
    if (!end) return []

    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx)
  },
  debounce (cb, timeout) {
    let searchTimeOut = null
    return dt => {
      clearTimeout(searchTimeOut)
      searchTimeOut = setTimeout(() => cb(dt), timeout)
    }
  },
  parseQueryFilter (query = {}) {
    let filter = {}
    try {
      filter = JSON.parse(query.filter)
    } catch (e) {
      filter = {}
    }
    return filter
  },
  toQueryStringFilter ({ filter = {} } = {}) {
    const q = new URLSearchParams()
    q.append('filter', JSON.stringify(filter))
    return `?${q.toString()}`
  }
}

export default OBJ
