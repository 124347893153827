import React from 'react';

export const PluginsItem = () => (
  <div className='col-md-3'>
    <div className='block-white block-plugins'>
      <h2>Mailchimp</h2>
      <img src='dist/img/plugins/mailchimp.png' height='100' alt='Logo' />
      <p>Ouh ouh ah ah !</p>
      <a href='#'>
        <div className='btn btn-delete'>Désactiver</div>
      </a>
    </div>
  </div>
);

// <a href="#"><div className="btn btn-add">Activer</div></a>
