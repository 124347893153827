import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { mapActions, mapGetters } from '../../store'

import csc from '../../logics/csc'

import axios from 'axios'
const { getAllCountries, getCountry, getAllCities, getCity } = csc

export default withRouter(props => {
  const [get_city] = mapActions(['get_city'])
  const city = mapGetters('city')

  const [id, setId] = useState(`${props.model}_${props.field}`)
  const [obj, setObj] = useState({})

  const {
    match: { params },
    location
  } = props

  const { model } = params
  const query = props.location.query
  const search = props.location.search

  function genCities () {
    return get_city({ iso: props.iso }).then(r => {
      if (!r || !r.length) {
        setItem([])
        return
      }
      var res = []
      for (var i = 0, length = r.length; i < length; i++) {
        res.push({ text: r[i].name, value: r[i].id, ...r[i] })
      }

      setItem(res)
    })
  }

  const [item, setItem] = useState([])

  function onChange (data) {
    if (
      props.onChange &&
      typeof props.onChange == 'function' &&
      !props.disabled
    ) {
      props.onChange({ value: data.value, text: data.text, data })
    }
  }

  useEffect(() => {
    if (!props.disabled && !props.country && props.iso) {
      genCities()
    }
  }, [props.iso])

  const groupBy = (items, key, keyValue) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: keyValue
          ? item[keyValue]
          : [...(result[item[key]] || []), item]
      }),
      {}
    )

  useEffect(() => {
    window
      .jQuery(`#${id}`)
      .select2({
        placeholder: props.placeholder,
        allowClear: true,
        tags: true,
        tokenSeparators: [',', ' ']
      })
      .val(props.defaultValue)
      .trigger('change')

    window.jQuery(`#${id}`).on('change', function (e) {
      let data = {}

      if (
        !!e.target.options[e.target.selectedIndex] &&
        e.target.options[e.target.selectedIndex].attributes
      ) {
        const arr =
          Object.values(e.target.options[e.target.selectedIndex].attributes) ||
          []
        data = {
          text:
            e.target.options[e.target.selectedIndex] &&
            e.target.options[e.target.selectedIndex].text,
          ...groupBy(arr, 'name', 'value')
        }
      }

      onChange(data, props.defaultValue != e.target.value)

      e.target.values = data

      e.target.clear = function () {
        window.jQuery(`#${id}`).val(null).trigger('change')
      }
    })
  }, [item])

  return (
    <>
      {/* xx{props.defaultValue} */}

      <select
        id={id}
        // data-placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        // value={props.defaultValue}
        disabled={props.disabled}
        onChange={onChange}
        ref={props.forwardRef}
        className='form-control'
        style={{
          borderRadius: '20px !important',
          height: '40px !important',
          border: 'thin solid black'
        }}
        multiple={props.multiple}
      >
        {item.map((item, i) => (
          <option
            key={i}
            value={item.id}
            id={item.id}
            lat={item.lat}
            lng={item.lng}
          >
            {item.text}
          </option>
        ))}
      </select>
    </>
  )
})
