import React, { useEffect, useState, Suspense } from 'react'

import Constants from '../../constants'

const imgCache = {
  __cache: {},
  read (src) {
    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          this.__cache[src] = true
          resolve(this.__cache[src])
        }
        img.onerror = () => {
          this.__cache[src] = true
          reject(this.__cache[src])
        }
        img.src = src
      }).then(img => {
        this.__cache[src] = true
      })
    }
    if (this.__cache[src] instanceof Promise) {
      throw this.__cache[src]
    }
    return this.__cache[src]
  }
}

export const SuspenseImg = ({ src, ...rest }) => {
  imgCache.read(src)
  return <img src={src} {...rest} />
}

export default props => {
  const [source, setSource] = useState('')

  function isNull (val) {
    return (
      val === undefined ||
      val === null ||
      val === 'undefined' ||
      val === 'null' ||
      String(val).replace(/\s{1,}/g, '') == ''
    )
  }

  useEffect(() => {
    let src = null

    if (!isNull(props.src)) {
      if (
        props.src.indexOf('http') == 0 ||
        props.src.indexOf('./assets') == 0 ||
        props.src.indexOf('data:') == 0
      ) {
        src = props.src
      } else {
        if (props.model) {
          let mdl = props.model
          if (props.model === 'cats' || props.model === 'scats') {
            mdl = 'feelings'
          }
          src = `${Constants.host}/uploads/${mdl}/img/${props.src}`
        }
      }
    }

    setSource(src)
  }, [props.src])

  if (source || !props.title) {
    return (
      <div
        style={{
          height: 40,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...(props.style || {})
        }}
      >
        <Suspense
          fallback={
            <div
              style={{
                borderRadius: 40,
                border: '1px solid #f4f4f4',
                height: 40,
                width: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div id='loading-spinner' style={{ height: 25, width: 25 }}></div>
            </div>
          }
        >
          <SuspenseImg
            src={source || require('../../assets/img/logo.png')}
            alt={props.alt}
            className='img-fluid img-thumbnail'
            style={{ borderRadius: 40, height: 40, width: 40 }}
          />
        </Suspense>
      </div>
    )
  } else {
    return (
      <div
        className='img-fluid img-thumbnail'
        style={{ borderRadius: 40, padding: 0 }}
      >
        <center
          style={{
            borderRadius: 40,
            height: 40,
            width: 40,
            backgroundColor: Constants.baseColor,
            color: Constants.baseWhite,
            fontSize: !props.title ? 6 : 40 / String(props.title).length,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {props.title}
        </center>
      </div>
    )
  }
}
