import React from 'react';
import { Link } from 'react-router-dom';

export const ListeArticlesRow = ({
  id,
  title,
  category,
  createdAt,
  status,
  views
}) => (
  <tr>
    <td>{id}</td>
    <td>{title}</td>
    <td>{category}</td>
    <td>{createdAt} </td>
    <td> {status} </td>
    <td>{views}</td>
    <td>
      <Link to='/article'>
        <i className='fa fa-pencil' aria-hidden='true' />
      </Link>
      <a href='#'>
        <i className='fa fa-trash' aria-hidden='true' />
      </a>
      <a target='_blank' href='#'>
        <i className='fa fa-eye' aria-hidden='true' />
      </a>
    </td>
  </tr>
);
