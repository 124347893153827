import React from 'react'
import { PluginsItem } from '../../components/tables/PluginsItem'
import FilAriane from '../../components/shared/FilAriane'

class Plugins extends React.Component {
  createList () {
    const liste = []
    for (let i = 0; i < 8; i++) {
      liste.push(<PluginsItem key={i} plugin='' />)
    }
    return liste
  }

  render () {
    return (
      <section className='content'>
        <FilAriane parent='Services' page='' onlyTwo />

        <div className='row'>{this.createList()}</div>
      </section>
    )
  }
}

export default Plugins
