import ws from './websocket'

export const roomIsActive = roomId => {
  const chat = ws.getSubscription(`room:${roomId}`)
  return !!chat
}

function createRoom (roomId = '1_7', events) {
  try {
    let chat = ws.getSubscription(`room:${roomId}`)

    if (!chat) {
      chat = ws.subscribe(`room:${roomId}`)
    }

    chat.on('open', () => {})

    chat.on('ready', () => {
      chat.emit('ready')
      if (typeof events.onReady == 'function') events.onReady()
    })

    chat.on('error', error => {
      if (typeof events.onError == 'function') events.onError()
    })

    chat.on('close', () => {
      if (typeof events.onClose == 'function') events.onClose()
    })

    chat.on('message', async msg => {
      if (typeof events.onMessage == 'function') events.onMessage(msg.data)
    })

    chat.on('reaction', async msg => {
      if (typeof events.onReaction == 'function') events.onReaction(msg.data)
    })

    chat.on('block', async msg => {
      if (typeof events.onBlock == 'function') events.onBlock(msg.data)
    })

    chat.on('deletedaccount', async msg => {
      if (typeof events.onDeletedAccount == 'function') {
        events.onDeletedAccount(msg.data)
      }
    })
    chat.on('feed', async msg => {
      if (typeof events.onFeedChange == 'function') {
        events.onFeedChange(msg.data)
      }
    })
    chat.on('changestatusaccount', async msg => {
      if (typeof events.onChangeStatusAccount == 'function') {
        events.onChangeStatusAccount(msg.data)
      }
    })

    chat.on('users', async users => {
      if (typeof events.onUsers == 'function') events.onUsers(users.data)
    })

    chat.on('write', user => {
      if (typeof events.onWrite == 'function')
        events.onWrite({ [user.data.fk_send_id]: user.data })
    })

    chat.on('quit', user => {
      if (typeof events.onQuit == 'function') events.onQuit({})
    })

    chat.on('room', async room => {
      if (typeof events.onRoom == 'function') events.onRoom()
    })
    return chat
  } catch (e) {
    return null
  }
}

export default createRoom
