import React from 'react'

class TextareaCkEditor extends React.Component {
  constructor () {
    super()
  }

  render () {
    return <textarea name={this.props.formName} className='ckeditor' />
  }
}

export default TextareaCkEditor
