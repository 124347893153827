import axios from 'axios'
import constants from '../constants'

axios.defaults.baseURL = constants.host
axios.defaults.timeout = 180000

axios.interceptors.request.use(
  config => {
    console.log(
      config.method.toUpperCase(),
      config.baseURL + config.url,
      config.data
    )
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default axios
