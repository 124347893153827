/** @format */

import React from "react"

import useFooter from "../sm/useFooter"

export default function CheckListFooter({ _WINDOW: { width: sw }, ...props }) {
  const { handleClose, handleSave } = useFooter(props);

  return (
    <React.Fragment>
      <button
        className={"btn btn-danger"}
        style={{ marginRight: "10px" }}
        disabled={props.checkedItems.isEmpty}
        onClick={props.checkedItems.clear}
      >
        <span className={"fa fa-eraser"}></span>
        {sw >= 700 && (
          <span className={"text-capitalize"}>&nbsp;Réinitialiser</span>
        )}
      </button>
      <button
        className="btn bagheram-btn-grey"
        style={{ marginRight: "10px" }}
        onClick={handleClose}
      >
        <span className={"fa fa-undo"}></span>
        {sw >= 700 && <span className={"text-capitalize"}>&nbsp;Annuler</span>}
      </button>
      <button
        className="btn btn-success"
        style={{ backgroundColor: "#783fbd !important" }}
        onClick={handleSave}
      >
        <span className={"fa fa-check"}></span>
        {sw >= 700 && <span className={"text-capitalize"}>&nbsp;Valider</span>}
      </button>
    </React.Fragment>
  );
}
