/** @format */

import React, { useEffect, useState } from 'react'
import { mapActions } from '../../store'

import { Popover } from 'react-tiny-popover'

export default function BagheramSelect (props) {
  const [isPopoverOpenBegin, setIsPopoverOpenBegin] = useState(false)
  const { updateItem } = mapActions()
  const [res, setRes] = React.useState(props.value)

  function onChangeSelect ({ value }) {
    setIsPopoverOpenBegin(false)
    if (props.model == 'users') {
      if (value == props.value) return

      window.BootstrapDialog.show({
        title:
          value == 0
            ? "Désactivation d'un utilisateur"
            : "Activation d'un utilisateur",
        data: {
          ...(props.item || {})
        },
        message: function (dialog) {
          const $message = window.jQuery(
            `<textarea id="textarea_user_blockingdesc" class="form-control" rows="4" placeholder="Veuillez ajouter un motif de votre action" style="border-radius: 10px; margin-top: 15px" defaultValue="${props.item['user_blockingdesc']}"></textarea>`
          )

          const _value = dialog.getData('user_blockingdesc')

          $message.val(_value)

          if (value == 1) {
            return window.jQuery(
              `<div>Voulez-vous vraiment activer le compte de l'utilisateur n° ${props.id} ?</div>`
            )
          }

          return window
            .jQuery(
              `<div>Voulez-vous vraiment désactiver le compte de l'utilisateur n° ${props.id} ?</div>`
            )
            .add($message)
        },
        buttons: [
          {
            label: 'Annuler',
            cssClass: 'bagheram-btn-grey',
            action: function (dialogRef) {
              dialogRef.close()
              window
                .jQuery(`#${props.field}_${props.id}`)
                .on('change', onChangeSelect)
            }
          },
          {
            label: value == 0 ? 'Désactiver' : 'Activer',
            cssClass: 'btn-primary',
            action: function (dialogRef) {
              onChange(
                { value },
                {
                  user_blockingdesc:
                    window.jQuery(`#textarea_user_blockingdesc`).val() ||
                    (props.item && props.item.user_blockingdesc) ||
                    ''
                },
                props.refresh
              )
              dialogRef.close()
            }
          }
        ]
      })
    } else {
      onChange({ value })
    }
  }

  function onChange (selectObject, mOj = {}, cb = () => null) {
    if (props.model && props.id) {
      const value = selectObject.value
      const formData = new FormData()

      const data = {
        [props.field]: value,
        ...mOj
      }

      for (const field in data) {
        formData.append(field, data[field])
      }

      setRes(value)

      updateItem({
        model: props.model,
        data: formData,
        id: props.id
      }).then(cb)
    }
    if (props.onChange && typeof props.onChange === 'function') {
      props.onChange(selectObject.value)
    }
  }

  const getLabel = React.useCallback(
    _value => {
      return props.options.find(v => v.value == _value) || { label: '' }
    },
    [props.options]
  )

  const Select = React.forwardRef(
    ({ id, field, placeholder, value = null, onClick = () => null }, ref) => {
      return (
        <span style={{ position: 'relative' }}>
          <input
            ref={ref}
            id={`${field}_${id}`}
            defaultValue={getLabel(value).label}
            readOnly
            type='text'
            autoCorrect='off'
            autoComplete='off'
            placeholder={placeholder}
            className='form-control'
            style={{
              backgroundColor: '#d13c8c',
              color: 'white',
              borderRadius: 5,
              cursor: 'pointer',
              textAlign: 'center'
            }}
            onClick={onClick}
          />
          <i
            className='fa fa-caret-down'
            style={{
              color: 'white',
              marginLeft: '0px !important',
              position: 'absolute',
              right: 13,
              top: 15
            }}
          />
        </span>
      )
    }
  )

  return (
    <div
      id={`select_${props.field}_${props.id}`}
      className='styled-select'
      style={{
        width: props.width || 160
      }}
    >
      <Popover
        isOpen={isPopoverOpenBegin}
        positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
        onClickOutside={() => setIsPopoverOpenBegin(false)}
        content={
          <div
            className='shadow-pink'
            style={{
              width: props.width || 160,
              minHeight: 44,
              backgroundColor: 'white',
              borderRadius: 5
            }}
          >
            {props.options.map((option, index) => (
              <div
                key={index}
                value={String(option.value)}
                className={
                  'flex-row hover-pink ' +
                  (index == 0
                    ? ' radius-top '
                    : props.options.length - 1 == index
                    ? ' radius-bottom '
                    : '')
                }
                style={{
                  height: 40,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => onChangeSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        }
      >
        <Select
          {...props}
          value={res}
          onClick={() => setIsPopoverOpenBegin(!isPopoverOpenBegin)}
        />
      </Popover>
    </div>
  )
}
