import React from 'react'
import { ListePagesRow } from '../../components/tables/ListePagesRow'
import FilAriane from '../../components/shared/FilAriane'

class ListePages extends React.Component {
  createList () {
    const table = []
    for (let i = 0; i < 7; i++) {
      table.push(
        <ListePagesRow
          key={i}
          id={i + 1}
          name='home'
          metaTitle='Gentlemen 1919'
          metaBody="Un barbier-coiffeur au chic intemporel, ayant pignon sur rue... Mais au fond du salon se cache l'inattendu"
        />
      )
    }
    return table
  }

  componentDidMount () {
    const navarray = document.getElementsByClassName('nava')
    for (let i = 0; i < navarray.length; i++) {
      navarray[i].className = 'nava'
    }
    document.getElementById('nav-contenu').className += ' active'
  }

  render () {
    return (
      <section className='content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='block-white flex-row'>
              <h1 className='title-page'>
                <i className='fa fa-info' aria-hidden='true' />
                Liste des pages
              </h1>
            </div>
          </div>
        </div>

        <FilAriane parent='Contenu' page='Pages' />

        <div className='alert alert-info alert-dismissible'>
          <button
            type='button'
            className='close'
            data-dismiss='alert'
            aria-hidden='true'
          >
            &times;
          </button>
          <h4>
            <i className='icon fa fa-info' /> Information !
          </h4>
          Si vous souhaitez ajouter une nouvelle page merci de{' '}
          <a href='#'>contacter uxia</a>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <table>
              <tbody>
                <tr>
                  <th>ID</th>
                  <th>Nom</th>
                  <th>Meta Titre</th>
                  <th>Meta Description</th>
                  <th>Auteur</th>
                  <th>Créée le</th>
                  <th>Éditer</th>
                </tr>

                {this.createList()}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    )
  }
}

export default ListePages
