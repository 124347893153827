import React, { useEffect, useMemo } from 'react'

import Reex, { mapActions, mapGetters } from '../../store'

import { Link, NavLink } from 'react-router-dom'

import BImage from '../../components/admin/Bagheram-image'
import csc from '../../logics/csc'
import constants from '../../constants'

export default props => {
  const {
    match: { params },
    history
  } = props
  const { model, id } = params
  const { getShowItem, getMenuData } = mapActions()
  var { title } = getMenuData(model)

  if (model === 'pubs' && +props.location.query.pub_type === 1) {
    title = 'Feeds'
  }

  const hideAlias = mapGetters('hideAlias')
  const statusLabels = useMemo(() => Reex.getState('statusLabels'), [])
  const languages = useMemo(() => Reex.getState('languages'), [])

  const item = mapGetters('showItem')
  const alias = mapGetters('alias')[model]

  const dataItem = Object.entries(item || {})

  useEffect(() => {
    getShowItem({ model, id })
  }, [model, id])

  function ContentTD (item, key) {
    if (key == 'scats') {
      return item[key].map((scat, i) => (
        <>
          <NavLink
            exact
            strict
            to={`/admin/scats/show/${scat.scat_id}`}
            key={i}
            style={{
              padding: 10,
              margin: 5,
              borderRadius: 20,
              border: 'none solid grey'
            }}
          >
            <BImage
              model={model}
              src={scat.scat_img}
              title={scat.scat_title}
              alt='...'
              className='img-fluid img-thumbnail'
              style={{ borderRadius: 40, height: 40, width: 40 }}
            />
            <span style={{ padding: 5 }}>{scat.scat_title}</span>
            {scat.scat_visa == 0 && <span>(inactive)</span>}
          </NavLink>
        </>
      ))
    } else if (key == 'feelings') {
      return item[key]
        .filter(feel => feel.fk_scat_id > 0)
        .map((feel, i) => (
          <NavLink
            exact
            strict
            to={`/admin/scats/show/${feel.fk_scat_id}`}
            key={i}
            style={{
              padding: 10,
              margin: 5,
              borderRadius: 20,
              border: 'none solid grey'
            }}
          >
            <BImage
              model='scats'
              src={feel.img}
              alt='...'
              className='img-fluid img-thumbnail'
              style={{ borderRadius: 40, height: 40, width: 40 }}
            />
            <span style={{ padding: 5 }}>{feel.title}</span>
          </NavLink>
        ))
    } else if (key.indexOf('_img') > -1) {
      if (model === 'pubs' && +item['pub_type'] === 1) {
        return (
          <BImage
            model={'users'}
            src={item[key]}
            title={item[`${model.slice(0, -1)}_pseudo`]}
          />
        )
      }
      return (
        <BImage
          model={model}
          src={item[key]}
          title={item[`${model.slice(0, -1)}_title`]}
        />
      )
    } else if (key.indexOf('_accept') > -1) {
      return <span>{item[key] == 1 ? 'Accepté' : 'En cours'}</span>
    } else if (key.indexOf('pub_type') > -1) {
      return <span>{item[key] == 1 ? 'Vidéo publique' : 'Publicité'}</span>
    } else if (key.indexOf('_visa') > -1) {
      if (model === 'pubs') {
        return <span>{item[key] == 1 ? 'Actif' : 'En pause'}</span>
      } else {
        return <span>{statusLabels[item[key]]}</span>
      }
    } else if (key.indexOf('_gender') > -1) {
      return <span>{item[key] == 1 ? 'Masculin' : 'Féminin'}</span>
    } else if (key.indexOf('_country') > -1) {
      return (
        <span>{!item[key] ? 'Aucun' : csc.getCountry(item[key]).name}</span>
      )
    } else if (key.indexOf('_video') > -1) {
      return (
        <video
          width='250'
          controls='controls'
          preload='metadata'
          style={{ borderRadius: 10 }}
          src={constants.host + '/uploads/pubs/' + item[key] + '#t=0.1'}
          type='video/mp4'
        />
      )
    } else if (!key.indexOf('vid_file')) {
      return (
        <video
          width='250'
          controls='controls'
          preload='metadata'
          style={{ borderRadius: 10 }}
          src={constants.host + '/uploads/vids/' + item[key] + '#t=0.1'}
          type='video/mp4'
        />
      )
    } else if (key.indexOf('vid_file') > -1) {
      return (
        <video
          width='250'
          controls='controls'
          preload='metadata'
          style={{ borderRadius: 10 }}
        >
          <source
            src={constants.host + '/uploads/vids/' + item[key] + '#t=0.1'}
            type='video/mp4'
          />
        </video>
      )
    } else if (key === 'wordings') {
      return (
        <>
          {item[key].map((wording, i) => (
            <div key={i}>
              - Titre en{' '}
              {String(languages[wording.wording_locale]).toLowerCase()}:{' '}
              {wording.wording_value}
            </div>
          ))}
        </>
      )
    } else {
      return <span>{item[key] || 'Aucun'}</span>
    }
  }

  return (
    <section className='content'>
      <section className='content-header'>
        <h1 className='row'>
          <span onClick={() => history.goBack()} className='cursor-pointer'>
            <i className='fa fa-chevron-left' aria-hidden='true' />
          </span>
          <span style={{ marginLeft: 10 }}>
            Fiche {String(title).toLowerCase().slice(0, -1)} nº{id}
          </span>
        </h1>
        <div className='form-group row'>
          <div
            className='col-sm-12'
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <button
              onClick={() =>
                history.push(
                  `/admin/${model}/edit/${item[model.slice(0, -1) + '_id']}${
                    model === 'pubs' ? `?pub_type=${item.pub_type || 0}` : ''
                  }`
                )
              }
              className='btn btn-primary'
              style={{ backgroundColor: '#783fbd !important' }}
            >
              Editer
            </button>
          </div>
        </div>
      </section>

      <section className='content'>
        <div
          className='box box-info'
          style={{
            paddingTop: '50px',
            border: 'thin solid grey',
            borderRadius: 50,
            backgroundColor: 'white'
          }}
        >
          <table>
            <tbody>
              {dataItem
                .filter(
                  ([key, value]) =>
                    !(
                      hideAlias &&
                      (hideAlias[key] ||
                        (hideAlias[model] && hideAlias[model][key]))
                    )
                )
                .map(([key, value]) => {
                  return (
                    <tr
                      key={key}
                      className='justify-content-center align-items-center'
                      style={{ minHeight: 40, borderx: 'thin solid red' }}
                    >
                      <td
                        htmlFor='inputEmail'
                        xclassname='col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <strong>
                          {' '}
                          {key.indexOf('fk_user_id') === 0
                            ? 'Utilisateur'
                            : key.indexOf('fk_scat_id') === 0
                            ? 'Passion Fille'
                            : key.indexOf('fk_cat_id') === 0
                            ? 'Passion Mère'
                            : alias[key] || key}
                        </strong>
                      </td>

                      <td className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        {ContentTD(item, key)}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </section>
    </section>
  )
}
