/** @format */

import React from 'react'

export default function BModal ({
  open = false,
  _trigger,
  _title,
  _body,
  _footer,
  ...props
}) {
  const {
    handleClick,
    _children: { BModalTrigger, BModalTitle, BModalFooter, BModalBody }
  } = useBModalLayoutManager(props)

  return (
    <React.Fragment>
      <React.Fragment>{_trigger || BModalTrigger}</React.Fragment>
      <div
        id={'BModalRoot'}
        className={'modal2'}
        onClick={handleClick}
        style={{
          display: open ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          className={'modal2-content d-flex flex-column'}
          style={{
            borderRadius: '10px !important',
            height: '95vh',
            zIndex: 10000
          }}
        >
          <div
            className={'d-flex flex-row'}
            style={{
              flexGrow: 0,
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '10%'
            }}
          >
            {_title || BModalTitle}
          </div>
          <div
            style={{
              flexGrow: 1,
              height: '100%',
              maxHeight: '72%',
              overflowY: 'scroll'
            }}
          >
            {_body || BModalBody}
          </div>
          {!!(_footer || BModalFooter) && (
            <div
              className={'d-flex flex-row'}
              style={{
                flexGrow: 0,
                alignItems: 'center',
                height: '100%',
                maxHeight: '18%'
              }}
            >
              {_footer || BModalFooter}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

BModal.Title = BModalTitle
BModal.Body = BModalBody
BModal.Footer = BModalFooter
BModal.Trigger = BModalTrigger

function BModalTrigger (props) {
  return <React.Fragment>{props.children}</React.Fragment>
}

function BModalBody (props) {
  return <React.Fragment>{props.children}</React.Fragment>
}

function BModalFooter (props) {
  return <React.Fragment>{props.children}</React.Fragment>
}

function BModalTitle (props) {
  return <React.Fragment>{props.children}</React.Fragment>
}

function useBModalLayoutManager ({ children = null, handleClose } = {}) {
  const modalFields = React.useMemo(() => {
    const fields = {}
    React.Children.forEach(children, child => {
      fields[child.type.name] = child
    })
    return { ...fields }
  }, [children])

  const handleClick = React.useCallback(
    e => {
      if (e.target.id === 'BModalRoot') {
        if (typeof handleClose === 'function') {
          handleClose()
        }
      }
    },
    [handleClose]
  )

  return {
    handleClick,
    _children: modalFields
  }
}
