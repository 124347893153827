/** @format */

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Link, NavLink } from 'react-router-dom'
import FilAriane from '../../components/shared/FilAriane'
import BImage from '../../components/admin/Bagheram-image'
import BSearch from '../../components/admin/Bagheram-search'
import BFilter from '../../components/admin/Bagheram-filter2/index'
import BGroupItems from '../../components/admin/Bagheram-groupitems'

import BNotifPush from '../../components/admin/Bagheram-Notification'
import BMemo from '../../components/admin/Bagheram-Memo'
import csc from '../../logics/csc'
import LUtils from '../../logics/utils'
import createRoom from '../../plugins/room'

import Reex, { mapActions, mapGetters } from '../../store'
import BagheramSelect from '../../components/admin/Bagheram-select'
import constants from '../../constants'

const moment = window.moment

let modelTmp = ''

function downloadFileCsv2 ({
  query: { all, model = '', filter = null },
  actions: { _download, _parseQ }
} = {}) {
  _download({
    model,
    query: _parseQ({
      all,
      csv: 1,
      filter: JSON.stringify(filter || {})
    })
  }).then(url => {
    if (url) {
      window.open(url, '_blank')
    }
  })
}

function remove2 (
  e = null,
  {
    query: { data = [], model = '', search = '' },
    actions: { _delete, _fetch, _next }
  } = {}
) {
  e && e.preventDefault()

  window.BootstrapDialog.show({
    title: 'Confirmation de suppression',
    message: 'Voulez-vous supprimer ces entrées ?',
    closable: false,
    buttons: [
      {
        id: 'btn-ok',
        label: 'Annuler',
        cssClass: 'bagheram-btn-grey',
        data: {
          js: 'btn-confirm',
          'user-id': '3'
        },
        autospin: false,
        action: function (dialogRef) {
          dialogRef.close()
        }
      },
      {
        id: 'btn-ok',
        label: 'OK',
        cssClass: 'btn-danger',
        data: {
          js: 'btn-confirm',
          'user-id': '3'
        },
        autospin: false,
        action: function (dialogRef) {
          dialogRef.close()
          _delete({ data, model })
            .then(r => {
              if (_next && typeof _next === 'function') {
                _next(r)
              }
              const dialogInstance = new window.BootstrapDialog()
                .setTitle('Operation réussie')
                .setClosable(false)
                .setMessage(
                  `Entrée(s) supprimée(s): ${r
                    .map(key => `n°${key}`)
                    .join(', ')}`
                )
                .setType(window.BootstrapDialog.TYPE_SUCCESS)
                .open()

              setTimeout(() => {
                dialogInstance.close()
                setTimeout(() => {
                  _fetch({ model, query: search }).then(() => {
                    setTimeout(() => {
                      window.loadSelect()
                    }, 100)
                  })
                }, 500)
              }, 1500)
            })
            .catch(e => {
              new window.BootstrapDialog()
                .setTitle('Operation échouée')
                .setClosable(false)
                .setMessage("Une erreur s'est produite")
                .setButtons([
                  {
                    label: 'ok',
                    action: function (dialog) {
                      dialog.close()
                    }
                  }
                ])
                .setType(window.BootstrapDialog.TYPE_DANGER)
                .open()
            })
        }
      }
    ]
  })
}

function undoQuestion ({
  query: { model, data, search },
  actions: { _update, _fetch, _next }
} = {}) {
  window.BootstrapDialog.show({
    title: 'Confirmation de suppression',
    message: 'Voulez-vous réhabiliter ce(s) compte(s) utilisateur ?',
    closable: false,
    buttons: [
      {
        id: 'btn-ok',
        label: 'Annuler',
        cssClass: 'bagheram-btn-grey',
        data: {
          js: 'btn-confirm',
          'user-id': '3'
        },
        autospin: false,
        action: function (dialogRef) {
          dialogRef.close()
        }
      },
      {
        id: 'btn-ok',
        label: 'OK',
        cssClass: 'btn-danger',
        data: {
          js: 'btn-confirm',
          'user-id': '3'
        },
        autospin: false,
        action: function (dialogRef) {
          dialogRef.close()
          _update({ model, data })
            .then(({ payload: { data: rData } } = {}) => {
              if (rData.length) {
                if (typeof _next === 'function') {
                  _next(rData)
                }
                const dialogInstance = new window.BootstrapDialog()
                  .setTitle('Operation réussie')
                  .setClosable(false)
                  .setMessage(
                    `Entrée(s) resotrée(s): ${rData
                      .map(key => `n°${key}`)
                      .join(', ')}`
                  )
                  .setType(window.BootstrapDialog.TYPE_SUCCESS)
                  .open()

                setTimeout(() => {
                  dialogInstance.close()
                  setTimeout(() => {
                    _fetch({ model, query: search })
                  }, 500)
                }, 1500)
              }
            })
            .catch(e => {
              new window.BootstrapDialog()
                .setTitle('Operation échouée')
                .setClosable(false)
                .setMessage("Une erreur s'est produite")
                .setButtons([
                  {
                    label: 'ok',
                    action: function (dialog) {
                      dialog.close()
                    }
                  }
                ])
                .setType(window.BootstrapDialog.TYPE_DANGER)
                .open()
            })
        }
      }
    ]
  })
}

function disableQuestion ({
  query: { model, data, search },
  actions: { _disable, _fetch, _next }
} = {}) {
  window.BootstrapDialog.show({
    title: 'Confirmation de désactivation',
    message: function (dialog) {
      const $message = window.jQuery(
        `<textarea id="textarea_user_blockingdesc" class="form-control" rows="4" placeholder="Veuillez indiquer le motif de votre action" style="border-radius: 10px; margin-top: 15px" defaultValue=""></textarea>`
      )
      return window
        .jQuery(
          `<div>Voulez-vous vraiment désactiver ce(s) compte(s) utilisateur: ${data[
            'user_id'
          ]
            .map(v => `n°${v}`)
            .join(', ')} ?</div>`
        )
        .add($message)
    },
    closable: true,
    buttons: [
      {
        id: 'btn-ok',
        label: 'Annuler',
        cssClass: 'bagheram-btn-grey',
        data: {
          js: 'btn-confirm',
          'user-id': '3'
        },
        autospin: false,
        action: function (dialogRef) {
          dialogRef.close()
        }
      },
      {
        id: 'btn-ok',
        label: 'OK',
        cssClass: 'btn-danger',
        data: {
          js: 'btn-confirm',
          'user-id': '3'
        },
        autospin: false,
        action: function (dialogRef) {
          const user_blockingdesc =
            window.jQuery(`#textarea_user_blockingdesc`).val() || ''
          if (user_blockingdesc) {
            dialogRef.close()
            _disable({
              model,
              data: {
                ...data,
                update: {
                  ...data['update'],
                  user_blockingdesc
                }
              }
            })
              .then(({ payload: { data: rData } } = {}) => {
                if (rData.length) {
                  if (typeof _next === 'function') {
                    _next(rData)
                  }
                  const dialogInstance = new window.BootstrapDialog()
                    .setTitle('Operation réussie')
                    .setClosable(false)
                    .setMessage(
                      `Entrée(s) déscativée(s): ${rData
                        .map(key => `n°${key}`)
                        .join(', ')}`
                    )
                    .setType(window.BootstrapDialog.TYPE_SUCCESS)
                    .open()

                  setTimeout(() => {
                    dialogInstance.close()
                    setTimeout(() => {
                      _fetch({ model, query: search }).then(() => {
                        setTimeout(() => {
                          window.loadSelect()
                        }, 100)
                      })
                    }, 500)
                  }, 1500)
                }
              })
              .catch(e => {
                new window.BootstrapDialog()
                  .setTitle('Operation échouée')
                  .setClosable(false)
                  .setMessage("Une erreur s'est produite")
                  .setButtons([
                    {
                      label: 'ok',
                      action: function (dialog) {
                        dialog.close()
                      }
                    }
                  ])
                  .setType(window.BootstrapDialog.TYPE_DANGER)
                  .open()
              })
          }
        }
      }
    ]
  })
}

function Lists (props) {
  const {
    match: { params },
    location
  } = props

  let { model } = params
  const query = props.location.query
  const search = props.location.search

  const {
    getAll,
    getMessage,
    sendMessage,
    deleteItem,
    updateItems,
    updateItem,
    deleteItems,
    disableItems,
    getMenuData,
    download,
    setSelected,
    GETMessage,
    initMessage,
    setCountFeed,
    setData
  } = mapActions()
  const wsOpened = mapGetters('wsOpened')

  let room = {}

  const filter = useMemo(() => {
    let query = props.location.query || {}
    try {
      query = JSON.parse(query.filter || '{}')
    } catch (e) {
      query = {}
    }
    return query
  }, [props.location.query])

  const pubTypeQueryValue = useMemo(() => {
    return model === 'pubs' && typeof filter.pub_type === 'object'
      ? filter.pub_type.value
      : 0
  }, [model, filter])

  const pubTypeQuery = useMemo(() => {
    return model === 'pubs' && typeof filter.pub_type === 'object'
      ? `?pub_type=${filter.pub_type.value}`
      : ''
  }, [props, model, filter])

  const DATA = mapGetters('data')
  const alias = mapGetters('alias')[model]
  const hideAlias = mapGetters('hideAlias')
  const hideFilter = mapGetters('hideFilter')
  const selected = mapGetters('selected')
  const messages = mapGetters('message')

  const [showNotif, setShowNotif] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [activeFilters, setActiveFilters] = useState(0)
  const [minimizeFilter, setMinimizeFilter] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [contWidth, setContWidth] = useState(false)
  const [sticky2, setSticky2] = useState(false)
  const [sticky3, setSticky3] = useState(false)
  const isAdminSuppression = React.useMemo(() => {
    if (model && model == 'users') {
      const { user_visa: { op = '>=', value = 0 } = {} } = query.filter
        ? JSON.parse(query.filter)
        : {}
      if (op === '=' && value === -1) {
        return true
      }
    }
    return false
  }, [model, query])

  const isEmptySelected = React.useMemo(() => {
    return Object.keys(selected || {})
      .map(v => (selected[v] ? v : null))
      .filter(v => v).length
  }, [selected])

  React.useEffect(() => {
    setSelected({})
  }, [isAdminSuppression])

  React.useEffect(() => {
    if (!showFilter) {
      const filter = LUtils.parseQueryFilter(query) || {}
      delete filter.user_visa
      if (Object.keys(filter).length > 0) {
        setShowFilter(true)
        setMinimizeFilter(true)
      }
    }
  }, [showFilter, query])

  const undoAdminSuppression = React.useCallback(
    ({ data, next }) => {
      undoQuestion({
        query: { data, model, search },
        actions: { _update: updateItems, _fetch: getAll, _next: next }
      })
    },
    [isAdminSuppression, model]
  )

  var { icon, title, bgc } = getMenuData(model)
  if (
    model === 'pubs' &&
    +JSON.parse(props.location.query.filter).pub_type.value === 1
  ) {
    title = 'Feeds'
  }

  let refSearch = {}

  function getModel (fld) {
    const field = fld.split('_')[1]
    return `${field == 'send' || field == 'dest' ? 'user' : field}s`
  }

  function parseQuery (params, sauf) {
    return Object.keys(params)
      .filter(k => (sauf || []).indexOf(k) === -1)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&')
  }

  function remove (e, { model, id }) {
    e.preventDefault()

    window.BootstrapDialog.show({
      title: 'Confirmation de suppression',
      message: 'Voulez-vous supprimer cette entrée?',
      closable: false,
      buttons: [
        {
          id: 'btn-ok',
          label: 'Annuler',
          cssClass: 'bagheram-btn-grey',
          data: {
            js: 'btn-confirm',
            'user-id': '3'
          },
          autospin: false,
          action: function (dialogRef) {
            dialogRef.close()
          }
        },
        {
          id: 'btn-ok',
          label: 'OK',
          cssClass: 'btn-danger',
          data: {
            js: 'btn-confirm',
            'user-id': '3'
          },
          autospin: false,
          action: function (dialogRef) {
            dialogRef.close()
            deleteItem({ model, id })
              .then(() => {
                const dialogInstance = new window.BootstrapDialog()
                  .setTitle('Operation réussi')
                  .setClosable(false)
                  .setMessage(`L'entrée nº ${id} a été supprimée`)
                  .setType(window.BootstrapDialog.TYPE_SUCCESS)
                  .open()

                setTimeout(() => {
                  console.log(dialogInstance)
                  dialogInstance.close()
                  setTimeout(() => {
                    getAll({ model, query: search })
                  }, 500)
                }, 1500)
              })
              .catch(e => {
                new window.BootstrapDialog()
                  .setTitle('Operation échouée')
                  .setClosable(false)
                  .setMessage("Une erreur s'est produite")
                  .setButtons([
                    {
                      label: 'ok',
                      action: function (dialog) {
                        dialog.close()
                      }
                    }
                  ])
                  .setType(window.BootstrapDialog.TYPE_DANGER)
                  .open()
              })
          }
        }
      ]
    })
  }
  function confirmVideo (e, { model, id }) {
    e.preventDefault()

    window.BootstrapDialog.show({
      title: 'Confirmation',
      message: 'Voulez-vous valider cette entrée?',
      closable: false,
      icon: 'fa fa-home',
      buttons: [
        {
          id: 'btn-ok',
          label: 'Annuler',
          cssClass: 'bagheram-btn-grey',
          data: {
            js: 'btn-confirm',
            'user-id': '3'
          },
          autospin: false,
          action: function (dialogRef) {
            dialogRef.close()
          }
        },
        {
          id: 'btn-ok',
          label: 'OK',
          cssClass: 'btn-primary',
          data: {
            js: 'btn-confirm',
            'user-id': '3'
          },
          autospin: false,
          action: function (dialogRef) {
            dialogRef.close()
            updateItem({ model, data: { pub_visa: 1 }, id })
              .then(() => {
                const dialogInstance = new window.BootstrapDialog()
                  .setTitle('Operation réussi')
                  .setClosable(false)
                  .setMessage(`L'entrée nº ${id} a été validée`)
                  .setType(window.BootstrapDialog.TYPE_SUCCESS)
                  .open()

                setTimeout(() => {
                  console.log(dialogInstance)
                  dialogInstance.close()
                  setCountFeed(0)
                  setTimeout(() => {
                    getAll({ model, query: search })
                  }, 500)
                }, 1500)
              })
              .catch(() => {
                new window.BootstrapDialog()
                  .setTitle('Operation échouée')
                  .setClosable(false)
                  .setMessage("Une erreur s'est produite")
                  .setButtons([
                    {
                      label: 'ok',
                      action: function (dialog) {
                        dialog.close()
                      }
                    }
                  ])
                  .setType(window.BootstrapDialog.TYPE_DANGER)
                  .open()
              })
          }
        }
      ]
    })
  }
  function deleteVideo (e, { model, id }) {
    e.preventDefault()
    window.BootstrapDialog.show({
      title: 'Confirmation',
      message: 'Voulez-vous supprimer cette entrée?',
      closable: false,
      buttons: [
        {
          id: 'btn-ok',
          label: 'Annuler',
          cssClass: 'bagheram-btn-grey',
          data: {
            js: 'btn-confirm',
            'user-id': '3'
          },
          autospin: false,
          action: function (dialogRef) {
            dialogRef.close()
          }
        },
        {
          id: 'btn-ok',
          label: 'Ok',
          cssClass: 'btn-danger',
          data: {
            js: 'btn-confirm',
            'user-id': '3'
          },
          autospin: false,
          action: function (dialogRef) {
            dialogRef.close()
            updateItem({ model, data: { pub_visa: 2 }, id })
              .then(() => {
                const dialogInstance = new window.BootstrapDialog()
                  .setTitle('Operation réussi')
                  .setClosable(false)
                  .setMessage(`L'entrée nº ${id} a été suprimée`)
                  .setType(window.BootstrapDialog.TYPE_SUCCESS)
                  .open()

                setTimeout(() => {
                  console.log(dialogInstance)
                  setCountFeed(0)
                  dialogInstance.close()
                  setTimeout(() => {
                    getAll({ model, query: search })
                  }, 500)
                }, 1500)
              })
              .catch(() => {
                new window.BootstrapDialog()
                  .setTitle('Operation échouée')
                  .setClosable(false)
                  .setMessage("Une erreur s'est produite")
                  .setButtons([
                    {
                      label: 'ok',
                      action: function (dialog) {
                        dialog.close()
                      }
                    }
                  ])
                  .setType(window.BootstrapDialog.TYPE_DANGER)
                  .open()
              })
          }
        }
      ]
    })
  }
  const disableSelected = React.useCallback(
    ({ data, next }) => {
      disableQuestion({
        query: { data, model, search },
        actions: { _disable: disableItems, _fetch: getAll, _next: next }
      })
    },
    [model, search]
  )

  const removeSelected = React.useCallback(
    ({ data = [], next: _next } = {}) => {
      remove2(null, {
        query: { data, model, search },
        actions: { _delete: deleteItems, _fetch: getAll, _next }
      })
    },
    [model, search]
  )

  const downloadSelected = React.useCallback(
    ({ data = null, currentPage = false } = {}) => {
      var model_pk = `${model.slice(0, -1)}_id`
      var value = []
      if (!currentPage) {
        if (data === null) {
          value = Object.keys(selected || {})
            .map(v => (selected[v] ? v : null))
            .filter(v => v)
        }
      } else {
        value = DATA.data.map(v => `${v[model_pk]}`)
      }
      if (Object.keys(data || {}).length > 0 || value.length > 0) {
        downloadFileCsv2({
          query: {
            all: -1,
            model,
            filter: data || { [model_pk]: { op: 'IN', value } }
          },
          actions: { _download: download, _parseQ: parseQuery }
        })
      }
    },
    [model, selected, DATA]
  )

  function downloadFileCsv (model, all = 0) {
    download({
      model,
      query: parseQuery({ ...props.location.query, csv: 1, all })
    }).then(url => {
      if (url) {
        window.open(url, '_blank')
      }
    })
  }

  function downloadQuestion (e, { model, one }) {
    e.preventDefault()
    let data
    if (one) {
      data = {
        title: 'Exporter',
        message: 'Voulez-vous extraire toutes les données en format .csv ?',
        closable: true,
        buttons: [
          {
            id: 'btn-ok',
            label: 'Ok',
            cssClass: `bg-${bgc}`,
            data: {
              js: 'btn-confirm',
              'user-id': '3'
            },
            autospin: false,
            action: function (dialogRef) {
              dialogRef.close()
              downloadFileCsv(model, 1)
            }
          }
        ]
      }
    } else {
      data = {
        title: 'Exporter',
        message: 'Voulez-vous extraire toutes les données en format .csv ?',
        closable: true,
        buttons: [
          {
            id: 'btn-ok',
            label: 'Tous',
            cssClass: `bg-${bgc}`,
            data: {
              js: 'btn-confirm',
              'user-id': '3'
            },
            autospin: false,
            action: function (dialogRef) {
              dialogRef.close()
              downloadFileCsv(model, 1)
            }
          },
          {
            id: 'btn-ok',
            label: 'Seulement cette page',
            cssClass: `bg-${bgc}`,
            data: {
              js: 'btn-confirm',
              'user-id': '3'
            },
            autospin: false,
            action: function (dialogRef) {
              dialogRef.close()
              downloadSelected({ currentPage: true })
            }
          },
          isEmptySelected
            ? {
                id: 'btn-ok',
                label: 'sélections uniquement',
                cssClass: `bg-${bgc}`,
                data: {
                  js: 'btn-confirm',
                  'user-id': '3'
                },
                autospin: false,
                action: function (dialogRef) {
                  dialogRef.close()
                  downloadSelected()
                }
              }
            : undefined
        ]
      }
    }
    if (data) {
      window.BootstrapDialog.show(data)
    }
  }

  function findWord () {
    const q = new URLSearchParams(search.slice(1))

    if (refSearch && refSearch.value) {
      if (search) {
        refSearch.value = String(refSearch.value).replace('  ', ' ')
        if (q.has('page')) {
          q.set('page', 1)
        }
        if (q.has('word')) {
          q.set('word', refSearch.value)
        } else {
          q.append('word', refSearch.value)
        }
      } else {
        q.append('word', refSearch.value)
      }
      props.history.push(`/admin/${model}?${q.toString()}`)
    } else {
      if (query.word) {
        q.delete('word')
      }
      console.log(refSearch)
      if (refSearch) {
        const bclr = refSearch.style.borderColor
        refSearch.style.borderColor = 'red'
        setTimeout(() => {
          if (refSearch) refSearch.style.borderColor = bclr
        }, 1000)
      }
      props.history.push(`/admin/${model}?${q.toString()}`)
    }
  }
  function filterField (field) {
    const q = new URLSearchParams(search.slice(1))

    if (field) {
      if (search) {
        if (q.has('orderby')) {
          q.set('orderby', field)
          if (q.has('asc')) {
            if (query.orderby == field) {
              q.delete('asc')
            }
          } else {
            q.append('asc', true)
          }
        } else {
          q.append('orderby', field)
          q.append('asc', true)
        }
      } else {
        q.append('orderby', field)
        q.append('asc', true)
      }
    } else {
      if (query.orderby) {
        q.delete('orderby')
        q.delete('asc')
      }
    }

    props.history.push(`/admin/${model}?${q.toString()}`)
  }

  function genUrl (key, v) {
    const q = new URLSearchParams(search.slice(1))

    if (v) {
      if (search) {
        if (q.has(key)) {
          q.set(key, v)
        } else {
          q.append(key, v)
        }
      } else {
        q.append(key, v)
      }
    } else {
      if (q.has(key)) {
        q.delete(key)
      }
    }

    return `/admin/${model}?${q.toString()}`
  }

  function doFilter (_data) {
    let data = { ...(_data || {}) }
    if (query.filter) {
      let filter = {}
      try {
        filter = JSON.parse(query.filter)
      } catch (e) {
        filter = {}
      }
      data = {
        ...(model === 'users' ? { user_visa: filter['user_visa'] } : {}),
        ...data
      }
    }
    const url = genUrl('filter', data ? JSON.stringify(data) : null)
    props.history.push(url)
  }

  function Checkbox ({ all, id, item }) {
    useEffect(() => {
      if (all && mounted) {
        let _checked = DATA.data.every(
          row => !!selected[row[model.slice(0, -1) + '_id']]
        )
        if (document.getElementById(`checkbox_all_${DATA.page}`)) {
          document.getElementById(`checkbox_all_${DATA.page}`).checked =
            _checked
        }
      }
    }, [all, selected, mounted])

    function onChangeCheckbox (e) {
      e.persist()
      const _selected = { ...selected }
      if (all) {
        for (let k in DATA.data) {
          const row = DATA.data[k]
          _selected[row[model.slice(0, -1) + '_id']] = e.target.checked
            ? row
            : false
        }
        setSelected({ ..._selected })
      } else {
        _selected[id] = e.target.checked ? item : false
        setSelected(_selected)
      }
    }

    return (
      <input
        type='checkbox'
        id={`checkbox_${id}`}
        name='subscribe'
        value={all ? 'all' : id}
        style={{ height: '20px' }}
        checked={!!selected[id]}
        onChange={onChangeCheckbox}
      ></input>
    )
  }

  function generateAlias (dt = {}, addLabel = false) {
    const _item = { ...dt }
    const item = {}

    for (let k in alias) {
      if (addLabel && _item[`${k}_label`]) {
        item[`${k}_label`] = _item[`${k}_label`]
      }
      if (k in _item) {
        item[k] = _item[k]
      }
    }

    return item
  }

  const RenderHead = useMemo(() => {
    const items = DATA.data || []
    const item = generateAlias(items[0])

    const arr = []

    for (const key in item) {
      if ((hideAlias && hideAlias[key]) || (hideAlias[model] || {})[key]) {
        continue
      }

      arr.push(
        <th
          key={key}
          className='text-center bagheram-th-hover'
          onClick={() => filterField(key)}
          style={{ cursor: 'pointer' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              ...(['created_at', 'user_birthday', 'user_blockingdesc'].indexOf(
                key
              ) > -1
                ? { minWidth: '150px' }
                : {})
            }}
          >
            {LUtils.capitalize(alias[key] || key)}
            {query.orderby == key && (
              <span
                className={`glyphicon glyphicon-chevron-${
                  query.asc && query.orderby == key ? 'up' : 'down'
                }`}
                style={{ marginLeft: 10 }}
              />
            )}
          </div>
        </th>
      )
    }

    if (['signals'].indexOf(model) === -1) {
      arr.push(
        <th
          key={Math.random()}
          className='text-capitalize text-center xbagheram-th-hover'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Actions
          </div>
        </th>
      )
    }

    return arr
  }, [DATA.data, model])

  function range (start = 1, end) {
    if (!end) return []

    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx)
  }

  function refresh () {
    getAll({ model, query: search }).then(() => {
      setTimeout(() => {
        window.loadSelect()
      }, 100)
    })
  }
  const [checkSrcollTop, setScrollTop] = useState(false)
  const [dataMessage, setDataMessage] = useState({})
  const [stateMessage, setStateMessage] = useState([])
  const [openMessageDialog, setOpenDialogMessage] = useState(false)
  const [page, setPage] = useState(2)
  window.onScrollMessage = function onScrollMessage () {
    window.jQuery('#conversation').on('scroll', function (e) {
      var scrollTop = window.jQuery(this).scrollTop()
      console.log(scrollTop)
      if (scrollTop <= 0) {
        setTimeout(() => {
          setScrollTop(true)
        }, 1000)
      } else {
        setScrollTop(false)
      }
    })
  }
  function convertAgo (secondFromNow) {
    const time = secondFromNow
    let rest = time
    if (time < 60) {
      return `à l’instant`
    }
    const nbDays = Math.floor(rest / (3600 * 24))
    const nbHours = Math.floor(rest / 3600)
    const nbMinutes = Math.floor(rest / 60)
    if (nbDays > 0) {
      return `Il ya ${nbDays} jours`
    }
    if (nbHours > 0) {
      return `Il ya ${nbHours} heures`
    }
    if (nbMinutes > 0) {
      return `Il ya ${nbMinutes} minutes`
    }
  }
  const generateMessages = useCallback(
    function (dest, data) {
      var messageContenu = ''
      var $messages = window.jQuery(
        `<div class="row message" id="conversation" onScroll="onScrollMessage()">`
      )
      window.jQuery.each(data, function (index, value) {
        const extension = String(value.message_cont).split('.').reverse()
        var sender = value.fk_send_id !== dest ? 'sender' : 'receiver'
        value.message_type === 1 || value.message_type === 3
          ? (messageContenu = `
        <div class="message-text">
        <video width="320" height="240" autoplay controls>
        <source src="${constants.host}${constants.videoDir}/${
              value.message_cont
            }" type="video/${(extension[0] || 'mp4').trim()}">
        </video>
                </div>
        `)
          : (messageContenu =
              `
        <div class="message-text">
        ` +
              value.message_cont +
              `
                </div>`)
        $messages.append(
          `<div class="row message-body">
            <div class="col-sm-12 message-main-` +
            sender +
            `">
              <div class="` +
            sender +
            `"> ` +
            messageContenu +
            `
                <span class="message-time pull-right">
                  ` +
            convertAgo(value.message_date) +
            `
                </span>
              </div>
            </div>
          </div>
          </div>
          `
        )
      })
      $messages.append(
        '<textarea id="user_msg" class="form-control txtarea" rows="4" cols="3" placeholder="Votre message" style="border-radius: 10px; margin-top: 15px ;margin-bottom: 15px ; margin-left: 13px; width:95%" defaultValue=""></textarea>'
      )
      return $messages
    },
    [messages.data]
  )
  const dialogInstance = useMemo(
    () =>
      model == 'pubs'
        ? new window.BootstrapDialog({
            title: 'Messagerie',
            message: '',
            onshown: function () {
              const conversation = window.jQuery('#conversation')
              conversation.animate(
                { scrollTop: conversation.innerHeight() },
                'fast'
              )
            },
            buttons: [
              {
                id: 'btn-ok',
                label: ' Envoyer',
                icon: 'glyphicon glyphicon-send',
                cssClass: 'btn-primary',
                data: {
                  js: 'btn-confirm'
                },
                autospin: false,
                action: function (dialogRef) {
                  const user_msg = window.jQuery(`#user_msg`).val() || ''
                  if (user_msg) {
                    sendMessage({ ...dataMessage, message_cont: user_msg })
                      .then(result => {
                        window.jQuery('.txtarea').before(
                          `
                            <div class="row message-body">
                              <div class="col-sm-12 message-main-sender">
                                <div class="sender">
                                  <div class="message-text">
                                    ${user_msg || ''}
                                  </div>
                                  <span class="message-time pull-right">
                                    ${convertAgo(0)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          `
                        )
                        window.jQuery('.txtarea').val('')
                      })
                      .catch(() => {
                        new window.BootstrapDialog()
                          .setTitle('Message non envoyée')
                          .setClosable(false)
                          .setMessage("Une erreur s'est produite")
                          .setButtons([
                            {
                              label: 'ok',
                              action: function (dialog) {
                                dialog.close()
                              }
                            }
                          ])
                          .setType(window.BootstrapDialog.TYPE_DANGER)
                          .open()
                        window.jQuery('.txtarea').val('')
                      })
                  }
                }
              }
            ]
          })
        : null,
    [model, dataMessage]
  )
  const close = useCallback(
    function () {
      if (room && room.close) {
        room.close()
        room = {}
      }
    },
    [room]
  )

  useEffect(() => {
    return () => {
      close()
    }
  }, [])

  const loadMessage = useCallback(async (data, dialogRef) => {
    dialogRef = dialogRef || { close: () => null, setMessage: () => null }
    getMessage({ dest: data.dest_id })
      .then(payload => {
        const message = generateMessages(data.dest_id, payload.data)
        if (dialogRef && message) {
          dialogRef.setMessage(message)
        }
      })
      .catch(e => {
        dialogRef.close()
      })
  }, [])
  useEffect(() => {
    if (wsOpened) {
      room = createRoom('m_admin', {
        async onMessage (data) {
          if (
            !!dialogInstance &&
            !!dataMessage &&
            dataMessage.pub_id > 0 &&
            !!openMessageDialog &&
            +data.fk_send_id === +dataMessage.dest_id
          ) {
            loadMessage(dataMessage, dialogInstance)
          }
          await getAll({ model, query: search })
        }
      })
    } else {
      close()
    }
  }, [wsOpened, dataMessage])

  useEffect(() => {
    if (model !== 'pubs') return
    if (checkSrcollTop) {
      loadMessage(dataMessage, dialogInstance)
    } else if (openMessageDialog) {
      dialogInstance.open()
      loadMessage(dataMessage, dialogInstance)
    } else {
      dialogInstance.close()
    }
  }, [openMessageDialog, checkSrcollTop, dataMessage])

  const RenderRow = useMemo(() => {
    const items = DATA.data || []

    function RenderTD ({ item: _item }) {
      const arr = []
      const id = _item[model.slice(0, -1) + '_id']

      const languages = useMemo(() => Reex.getState('languages'), [])

      const value = useCallback(
        function (item, key) {
          if (key == 'wording_locale') {
            // return <span>{languages[item[key]] || 'NaN'}</span>
            return <span>{item[key] == 0 ? 'Français' : 'Anglais'}</span>
          } else if (
            key.indexOf('fk_') < 0 &&
            (key.indexOf('_img') > -1 || String(item[key]).indexOf('svg') > -1)
          ) {
            if (
              model === 'pubs' &&
              filter &&
              filter['pub_type'] &&
              +filter['pub_type'].value === 1
            ) {
              return (
                <BImage
                  model={'users'}
                  src={item[key]}
                  title={item[`${model.slice(0, -1)}_pseudo`]}
                />
              )
            }
            return (
              <BImage
                model={model}
                src={item[key]}
                title={item[`${model.slice(0, -1)}_title`]}
              />
            )
          } else if (
            !!item[key] &&
            String(item[key]).split &&
            key.indexOf('fk_') > -1
          ) {
            return (
              <NavLink
                to={`/admin/${getModel(key)}/show/${item[key]}`}
                style={{
                  padding: 10,
                  margin: 5,
                  borderRadius: 20,
                  border: 'none solid grey'
                }}
              >
                {item[key + '_label'] || item[key]}
              </NavLink>
            )
          } else if (key.indexOf('_visa') > -1 && !isAdminSuppression) {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <BMemo data={item[key]}>
                  <BagheramSelect
                    model={model}
                    field={key}
                    value={item[key]}
                    item={item}
                    id={id}
                    placeholder='None'
                    options={
                      model === 'pubs'
                        ? [
                            {
                              label: 'En pause',
                              value: 0
                            },
                            {
                              label: 'Actif',
                              value: 1
                            },
                            {
                              label: 'Refusée',
                              value: 2
                            }
                          ]
                        : [
                            {
                              label: 'Inactif',
                              value: 0
                            },
                            {
                              label: 'Actif',
                              value: 1
                            }
                          ]
                    }
                    refresh={refresh}
                  />
                </BMemo>
              </div>
            )
          } else if (key == 'vid_type') {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <BagheramSelect
                  model={model}
                  field={key}
                  value={item[key]}
                  item={item}
                  id={id}
                  placeholder='None'
                  options={[
                    {
                      label: 'Video surprise',
                      value: 0
                    },
                    {
                      label: 'Anniversaire',
                      value: 1
                    }
                  ]}
                  refresh={refresh}
                />
              </div>
            )
          } else if (key.indexOf('_status') > -1) {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <BagheramSelect
                  model={model}
                  field={key}
                  value={item[key]}
                  item={item}
                  id={id}
                  width={100}
                  placeholder='Non'
                  options={[
                    {
                      label: 'Non lu',
                      value: 0
                    },
                    {
                      label: 'Lu',
                      value: 1
                    }
                  ]}
                  refresh={refresh}
                />
              </div>
            )
          } else if (key.indexOf('_gender') > -1) {
            return (
              <span>
                {item[key] == 0
                  ? 'Féminin'
                  : item[key] == 1
                  ? 'Masculin'
                  : 'Secret'}
              </span>
            )
          } else if (key.indexOf('_country') > -1) {
            return (
              <span>
                {!item[key] ? 'Aucun' : csc.getCountry(item[key]).name}
              </span>
            )
          } else if (key.indexOf('_town') > -1) {
            return (
              <span>
                {item.user_country && item[key] ? item[key] : 'Aucune'}
              </span>
            )
          } else if (key.indexOf('_accept') > -1) {
            return <span>{item[key] == 1 ? 'Accepté' : 'En cours'}</span>
          } else if (key.indexOf('pub_type') > -1) {
            return (
              <span>{item[key] == 1 ? 'Vidéo publique' : 'Publicité'}</span>
            )
          } else if (key === 'badge_level') {
            return (
              <span>
                {item[key] == 1
                  ? 'Bronze'
                  : item[key] == 2
                  ? 'Argent'
                  : item[key] == 3
                  ? 'Or'
                  : '???'}
              </span>
            )
          } else if (key === 'badge_cat') {
            return <span className='text-capitalize'>{item[key]}</span>
          } else if (['created_at', 'updated_at'].indexOf(key) > -1) {
            return <span>{moment(item[key]).format('DD/MM/YYYY')}</span>
          } else if (key.indexOf('_nationality') > -1) {
            return (
              <span>
                {item[key]
                  ? csc.getCountryFromIso(item[key].toUpperCase()).name
                  : '-'}
              </span>
            )
          } else if (key.indexOf('_blocking_desc') > -1) {
            if (isAdminSuppression) {
              return <span>{item[key] || '-'}</span>
            }
          } else if (key.indexOf('_blocking_desc') > -1) {
            if (isAdminSuppression) {
              return <span>{item[key] || '-'}</span>
            }
          } else if (key === 'wordings') {
            return (
              <>
                {item[key].map(wording => (
                  <div key={wording.wording_title}>
                    - {wording.wording_value}
                  </div>
                ))}
              </>
            )
          } else {
            return <span>{item[key] || '-'}</span>
          }
        },
        [languages]
      )

      const item = generateAlias(_item, true)

      for (const key in item) {
        if (
          (hideAlias && hideAlias[key]) ||
          (hideAlias[model] || {})[key] ||
          String(key).indexOf('id_label') > -1
        ) {
          continue
        }

        arr.push(
          <td key={key} className='text-center'>
            {value(item, key)}
          </td>
        )
      }

      if (['signals'].indexOf(model) === -1) {
        if (!isAdminSuppression) {
          const isPublicVideo = model === 'pubs' && _item.pub_type === 1

          arr.push(
            <td
              key='op'
              className='text-truncate'
              style={{ minWidth: '100px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Link to={`/admin/${model}/show/${id}`} className='btn'>
                  <i className='fa fa-eye' aria-hidden='true'></i>
                </Link>
                {(!isPublicVideo ||
                  (isPublicVideo && _item.pub_visa !== 0)) && (
                  <Link
                    to={`/admin/${model}/edit/${id}${pubTypeQuery}`}
                    style={{ margin: '10px 10px' }}
                    className='btn bg-blue'
                  >
                    <i
                      className='fa fa-pencil'
                      aria-hidden='true'
                      style={{ color: 'white', marginLeft: '0px !important' }}
                    />
                  </Link>
                )}
                {(!isPublicVideo ||
                  (isPublicVideo && _item.pub_visa !== 0)) && (
                  <Link
                    onClick={e => remove(e, { model, id })}
                    to={location.pathname + search}
                    className='btn bg-orange'
                  >
                    <i
                      className='fa fa-trash'
                      aria-hidden='true'
                      style={{ color: 'white' }}
                    />
                  </Link>
                )}
                {isPublicVideo && _item.pub_visa === 0 && (
                  <Link
                    className='btn bg-green'
                    onClick={e => confirmVideo(e, { model: model, id })}
                    style={{ margin: '10px' }}
                  >
                    <i
                      className='fa fa-check'
                      aria-hidden='true'
                      style={{ color: 'white' }}
                    />
                  </Link>
                )}
                {isPublicVideo && _item.pub_visa === 0 && (
                  <Link
                    className='btn bg-red'
                    onClick={e => deleteVideo(e, { model: model, id })}
                  >
                    <i
                      className='fa fa-times'
                      aria-hidden='true'
                      style={{ color: 'white' }}
                    />
                  </Link>
                )}
                {isPublicVideo && (
                  <Link
                    style={{
                      margin: '10px 10px',
                      backgroundColor: '#95C8D8',
                      position: 'relative'
                    }}
                    onClick={async e => {
                      e.preventDefault()
                      const idx = DATA.data.findIndex(
                        pub => pub.pub_id === _item.pub_id
                      )
                      if (DATA.data[idx]) {
                        DATA.data[idx] = { ...DATA.data[idx], last_message: 0 }
                        DATA.data = [...DATA.data]
                        setData({ ...DATA })
                      }
                      const dest = _item.fk_user_id || 1
                      setDataMessage(dataMessage => ({
                        pub_id: _item.pub_id,
                        dest_id: dest
                      }))
                      setOpenDialogMessage(true)
                    }}
                    className='btn'
                  >
                    <i
                      className='fa fa-comment-o'
                      aria-hidden='true'
                      style={{ color: 'white', marginLeft: '0px !important' }}
                    />
                    {_item.last_message > 0 && (
                      <div class='notif'> {_item.last_message} </div>
                    )}
                  </Link>
                )}
              </div>
            </td>
          )
        } else {
          arr.push(
            <td
              key='op'
              className='text-truncate'
              style={{ minWidth: '100px' }}
            >
              <button
                className={'btn btn-primary text-sm'}
                onClick={() => {
                  undoAdminSuppression({
                    data: { ['user_id']: [id], update: { user_visa: 1 } }
                  })
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  <i className='fa fa-undo' aria-hidden='true' />
                </span>
                <span>Restaurer</span>
              </button>
            </td>
          )
        }
      }

      return arr
    }

    return items.map((item, i) => {
      return <RenderTD key={i} item={item} index={i} />
    })
  }, [DATA.data, model, filter])

  useEffect(() => {
    setMounted(false)

    getAll({ model, query: search }).then(() => {
      setTimeout(() => {
        setMounted(true)
        window.loadSelect()
      }, 450)
    })
  }, [model, search, params])

  useEffect(() => {
    if (DATA.data && DATA.data.length) {
      let _selected = { ...selected }
      if (modelTmp && modelTmp != model) {
        if (document.getElementById(`checkbox_all`)) {
          document.getElementById(`checkbox_all`).checked = false
        }
        for (let k in selected) {
          if (document.getElementById(`checkbox_${k}`)) {
            document.getElementById(`checkbox_${k}`).checked = false
          }
        }
        _selected = {}
      }
      DATA.data.map(item => {
        const id = item[model.slice(0, -1) + '_id']
        if (id) {
          _selected[id] = selected[id] || false
        }
      })
      setSelected(_selected)

      modelTmp = model
    }
  }, [DATA.data, model])

  useEffect(() => {
    window.onscroll = function () {
      myFunction2()
      myFunction3()
    }

    var header = window.document.getElementById('myHeader')
    var cont_option = window.document.getElementById('cont_option')
    var cont = window.document.getElementById('cont')
    var _sticky2 = header.offsetTop
    var _sticky3 = cont_option.offsetTop

    function myFunction2 () {
      setContWidth(cont.offsetWidth)
      if (window.pageYOffset + 50 > _sticky2) {
        header.classList.add('sticky2')
        setSticky2(true)
      } else {
        header.classList.remove('sticky2')
        setSticky2(false)
      }
    }
    function myFunction3 () {
      if (window.pageYOffset > _sticky3 - 120) {
        cont_option.classList.add('sticky3')
        setSticky3(true)
      } else {
        cont_option.classList.remove('sticky3')
        setSticky3(false)
      }
    }

    function handleResize () {
      setContWidth(cont.offsetWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  React.useEffect(() => {
    setShowFilter(false)
  }, [model])

  const pagination = range(1, DATA.lastPage)
  const hasData = DATA.data && DATA.data.length > 0

  return (
    <section className='content'>
      <div
        className='header2'
        id='myHeader'
        style={
          contWidth > 1
            ? {
                width: `${contWidth}px`,
                ...(sticky3
                  ? {
                      height: 150
                    }
                  : {})
              }
            : {}
        }
      >
        <div
          className='row'
          style={
            sticky3
              ? {
                  height: 80
                }
              : {}
          }
        >
          <div
            className='col-md-12'
            style={
              sticky3
                ? {
                    height: 80
                  }
                : {}
            }
          >
            <div className='block-white flex-row'>
              <h1 className='title-page'>
                <i
                  className={`${icon} btn-pa-none bg-${bgc}`}
                  aria-hidden='true'
                />
                Liste des {String(title).toLowerCase()}
              </h1>
              <div className={'flex-row'}>
                <div style={{ marginRight: '10px' }}>
                  <BGroupItems
                    {...{
                      title: '',
                      items: selected,
                      updateItems: setSelected,
                      doRemoveItems: removeSelected,
                      doDisableItems: disableSelected,
                      doDownloadItemsToCsv: downloadSelected,
                      doUndoAdminSuppression: undoAdminSuppression,
                      hiddenActions: {
                        _delete: isAdminSuppression
                      },
                      model,
                      trigger: ({
                        data = [],
                        _WINDOW: { width: sw },
                        _limits
                      } = {}) => {
                        return (
                          <div className={`btn mgt-15 bg-${bgc}`}>
                            <span>
                              <i className='fa fa-eye' />
                            </span>
                            {sw >= _limits._uw && (
                              <span>&nbsp;Voir la sélection</span>
                            )}
                            {sw >= _limits._lw && sw < _limits._uw && (
                              <span>&nbsp;Voir</span>
                            )}
                            {sw >= _limits._lw && (
                              <span>&nbsp;( {data.length} )</span>
                            )}
                          </div>
                        )
                      }
                    }}
                  ></BGroupItems>
                </div>
                {((model !== 'vids' && model !== 'pubs') ||
                  (model === 'pubs' && pubTypeQueryValue !== 1)) && (
                  <NavLink
                    exact
                    strict
                    to={`/admin/${model}/add${pubTypeQuery}`}
                  >
                    <div className={`btn mgt-15 bg-${bgc}`}>
                      <i className='fa fa-plus' />
                      &nbsp;Ajouter
                    </div>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='content2'>
        <FilAriane parent={title} page='' onlyTwo />
      </div>
      <div
        className='row'
        style={{ paddingLeft: '15px', paddingRight: '15px' }}
      >
        <div
          id='cont'
          className='col-12 bagheram-box-shadow bagheram-rounded d-flex flex-column'
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          <div
            id='cont_option'
            style={
              sticky3
                ? {
                    width: `${contWidth}px`,
                    marginLeft: '-20px'
                  }
                : {}
            }
          >
            <div
              className='d-flex flex-row'
              style={{
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                width: '100%'
              }}
            >
              <div
                className='d-flex flex-row col-lg-6 col-md-6 col-sm-12 col-xs-12'
                style={{
                  padding: 0,
                  justifyContent: 'flex-start',
                  marginTop: 20,
                  paddingBottom: 20
                }}
              >
                {!showNotif && model == 'users' && !isAdminSuppression && (
                  <button
                    href='#!'
                    className={`btn btn-default btn-outline-secondary bg-${bgc}`}
                    type='button'
                    onClick={() => setShowNotif(true)}
                    style={{
                      height: 38,
                      borderRadius: 20,
                      fontSize: 12,
                      fontWeight: 'normal',
                      marginRight: 5
                    }}
                  >
                    <span className='fa fa-bell' />
                    <span style={{ marginLeft: 5 }}>Notifier</span>
                  </button>
                )}
                {[
                  'cats',
                  'scats',
                  'users',
                  'signals',
                  'badges',
                  'vids',
                  'messages'
                ].indexOf(model) > -1 && (
                  <button
                    href='#!'
                    className={`btn btn-default btn-outline-secondary bg-${bgc}`}
                    type='button'
                    onClick={e => {
                      downloadQuestion(e, {
                        model
                      })
                    }}
                    style={{
                      height: 38,
                      borderRadius: 20,
                      fontSize: 12,
                      fontWeight: 'normal',
                      marginRight: 5
                    }}
                  >
                    <span className='fa fa-download' />
                    <span style={{ marginLeft: 5 }}>Exporter</span>
                  </button>
                )}
                {!hideFilter[model] ? (
                  !showFilter ? (
                    <button
                      href='#!'
                      className={`btn btn-default btn-outline-secondary bg-${bgc}`}
                      type='button'
                      onClick={() => {
                        setShowFilter(true)
                      }}
                      style={{
                        height: 38,
                        borderRadius: 20,
                        fontSize: 12,
                        fontWeight: 'normal'
                      }}
                    >
                      <span className='fa fa-filter' />
                      <span style={{ marginLeft: 5 }}>Filtrer</span>
                    </button>
                  ) : (
                    <button
                      href='#!'
                      className={`btn btn-default btn-outline-secondary bg-${
                        activeFilters > 0 ? 'green' : 'blue'
                      }`}
                      type='button'
                      onClick={() => {
                        setMinimizeFilter(minimize => !minimize)
                      }}
                      style={{
                        height: 38,
                        borderRadius: 20,
                        fontSize: 12,
                        fontWeight: 'normal'
                      }}
                    >
                      <span
                        className={`fa ${
                          minimizeFilter ? 'fa-eye' : 'fa-eye-slash'
                        }`}
                      />
                      <span style={{ marginLeft: 5 }}>Filtrer</span>
                    </button>
                  )
                ) : (
                  <></>
                )}
              </div>
              <div
                className='d-flex flex-row col-lg-6 col-md-6 col-sm-12 col-xs-12'
                style={{
                  justifyContent: 'flex-end',
                  borderx: 'thin solid red',
                  padding: 0,
                  marginTop: 20,
                  paddingBottom: 20
                }}
              >
                <div
                  className='md-form col-xs-12'
                  style={{
                    marginRight: 10,
                    padding: 0,
                    borderx: 'thin solid red'
                  }}
                >
                  <BSearch
                    forwardRef={node => (refSearch = node)}
                    onKeyUp={e => {
                      if (e.keyCode === 13 && refSearch && refSearch.value) {
                        findWord()
                      }
                    }}
                    model={model}
                    defaultValue={query.word}
                    onClear={findWord}
                  />
                </div>
                <button
                  href='#!'
                  className={`btn btn-default btn-outline-secondary bg-${bgc}`}
                  type='button'
                  onClick={findWord}
                  style={{
                    height: 38,
                    borderRadius: 20,
                    fontSize: 12,
                    fontWeight: 'normal'
                  }}
                >
                  <span className='fa fa-search' />
                </button>
              </div>
            </div>

            {showNotif && model == 'users' && (
              <BNotifPush model={model} onClose={() => setShowNotif(false)} />
            )}

            <BFilter
              visible={showFilter}
              model={model}
              isAdminSuppression={isAdminSuppression}
              onClick={doFilter}
              minimizeWindowFilter={minimizeFilter}
              onMinimize={({ minimize = true, activeFilters = 0 } = {}) => {
                setActiveFilters(activeFilters)
                if (minimize) {
                  setMinimizeFilter(true)
                }
              }}
              fields={(DATA.data || [{}])[0]}
              active={query.filter}
              onClear={d => {
                if (mounted) {
                  setShowFilter(false)
                  doFilter(d)
                }
              }}
              alias={alias}
              type={
                DATA.types || {
                  user_country: 'text',
                  user_town: 'text',
                  created_at: 'date'
                }
              }
            />
          </div>

          <div
            className='col-12 table-responsive content3'
            style={hasData ? { marginTop: 10 } : {}}
          >
            {hasData && mounted && (
              <table>
                <thead id='cont_option2'>
                  <tr>
                    <th
                      key={'chck_all'}
                      className='text-capitalize text-center xbagheram-th-hover'
                    >
                      <Checkbox key={'all'} all id={`all_${DATA.page}`} />
                    </th>
                    {RenderHead}
                  </tr>
                </thead>
                <tbody>
                  {DATA.data.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td
                          key={`chckbx_${item[model.slice(0, -1) + '_id']}`}
                          className='text-truncate'
                          style={{ minWidth: '70px' }}
                        >
                          <Checkbox
                            key={item[model.slice(0, -1) + '_id']}
                            id={item[model.slice(0, -1) + '_id']}
                            item={item}
                          />
                        </td>
                        {RenderRow[ind]}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
            {!mounted && (
              <div
                className='col-12 d-flex flex-row'
                style={{ height: '80px', alignItems: 'center' }}
              >
                <div className='spinner'>
                  <div
                    className='double-bounce1'
                    style={{ backgroundColor: bgc }}
                  />
                  <div
                    className='double-bounce2'
                    style={{ backgroundColor: bgc }}
                  />
                </div>
              </div>
            )}
            {DATA.data && DATA.data.length == 0 && mounted && (
              <div className='text-center'>
                <h4>Aucune donnée à afficher pour le moment</h4>
              </div>
            )}
          </div>
        </div>
        {pagination.length > 1 && mounted && (
          <nav
            aria-label='Page navigation example'
            className='d-flex justify-content-end'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <ul className='pagination'>
              <li
                className={
                  pagination[0] == DATA.page
                    ? 'page-item disabled'
                    : 'page-item'
                }
              >
                <Link
                  className='page-link'
                  to={genUrl('page', DATA.page - 1)}
                  tabIndex='-1'
                  aria-label='Previous'
                  onClick={e =>
                    pagination[0] == DATA.page && e.preventDefault()
                  }
                >
                  <span aria-hidden='true'>&laquo;</span>
                </Link>
              </li>
              {pagination.map((pg, i) => {
                return (
                  <li
                    key={i}
                    className={
                      DATA.page == pg ? 'page-item active' : 'page-item'
                    }
                  >
                    <Link className='page-link' to={genUrl('page', pg)}>
                      {pg}
                    </Link>
                  </li>
                )
              })}
              <li
                className={
                  pagination[pagination.length - 1] == DATA.page
                    ? 'page-item disabled'
                    : 'page-item'
                }
              >
                <Link
                  className='page-link'
                  to={genUrl('page', DATA.page + 1)}
                  aria-label='Next'
                  onClick={e =>
                    pagination[pagination.length - 1] == DATA.page &&
                    e.preventDefault()
                  }
                >
                  <span aria-hidden='true'>&raquo;</span>
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  )
}

export default Lists
