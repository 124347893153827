import React, { useState } from 'react'

import { mapActions } from '../../store'

export default props => {
  const [user_email, setUserName] = useState(null)
  const [user_password, setUserPassword] = useState(null)
  const [err, setErr] = useState(null)

  const { login } = mapActions()

  function onChangeName (v) {
    setUserName(v.target.value)
  }

  function onChangePass (v) {
    setUserPassword(v.target.value)
  }

  function connect () {
    if (user_email && user_password) {
      login({ user_email, user_password })
        .then(r => {
          props.history.push('/admin')
          props.history.go()
        })
        .catch(e => {
          setErr("Oops, ce compte n'existe pas")
        })
    }
  }

  function enter (e) {
    if (e.which == 13) {
      connect()
    }
  }

  return (
    <section
      className='content'
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        className='card col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 bagheram-rounded'
        style={{ backgroundColor: '#FFFFFF', maxWidth: 500 }}
      >
        <div
          className='card-body login-card-body'
          style={{ padding: '30px 50px 30px 50px' }}
        >
          <center className='mt-3' style={{ marginTop: '10px' }}>
            <img
              src={require('../../assets/img/logo-small.png')}
              width='80'
              height='50'
            />
          </center>
          <h3
            className='login-box-msg font-weight-light'
            style={{ color: '#ff0132', marginTop: '15px' }}
          >
            Bagheram Admin
          </h3>

          <div className='form-group mt-2'>
            <label
              htmlFor='exampleFormControlInput1'
              style={{ width: '100%', marginLeft: 0 }}
            >
              Login
            </label>
            <input
              type='email'
              onChange={onChangeName}
              className='form-control font-weight-light h4 bagheram-input'
              id='login'
              placeholder='Login'
            />
          </div>
          <div className='form-group'>
            <label
              htmlFor='exampleFormControlInput1'
              style={{ width: '100%', marginLeft: 0 }}
            >
              Password
            </label>
            <input
              type='password'
              onChange={onChangePass}
              onKeyDown={enter}
              className='form-control font-weight-light h4 bagheram-input'
              id='password'
              placeholder='Password'
            />
          </div>

          {err && (
            <div className='text-warning text-center pt-1 pb-3' role='alert'>
              Oops, ce compte n'existe pas
            </div>
          )}

          <div
            className='d-flex flex-row justify-content-end'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <button
              type='button'
              className='btn'
              onClick={connect}
              style={{
                background: 'linear-gradient(to right, #643fc8, #d13c8c)',
                border: '2px solid blue !important'
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
