import React, { useCallback, useEffect, useState } from 'react'

import {
  HashRouter,
  Route,
  withRouter,
  Redirect,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'

import Store from './store/main.store'

import { mapGetters, mapActions } from './store'

import router from './router'

import './plugins/axios'

import { NavigationMenu } from './components/NavigationMenu'
import HeaderBar from './components/header-bar/HeaderBar'

import Login from './pages/Authentification/Login'
import Splash from './pages/Authentification/Splash'
import createRoom from './plugins/room'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const Routes = router.routes.map((r, i) => {
  return (
    <Route
      key={i}
      name={r.name}
      exact
      strict
      path={r.path}
      // component={withRouter(r.component)}
      component={withRouter(r.component)}
      render={routeProps => console.log({ routeProps })}
    />
  )
})

const Header = props => {
  const history = useHistory()
  const hookQuery = useQuery()
  const query = {}
  const token = mapGetters('token')
  const countFeed = mapGetters('countFeed')
  const setCountFeed = mapActions('setCountFeed')

  const getCountPublicVideo = mapActions('getCountPublicVideo')

  const wsOpened = mapGetters('wsOpened')

  hookQuery.forEach((q, k) => {
    query[k] = q
  })

  history.location.query = query
  history.location.useQuery = hookQuery

  let room = {}

  const roomAll = 'all'

  const loadCountVideo = useCallback(async () => {
    return getCountPublicVideo().then(count => setCountFeed(count))
  }, [])

  const close = useCallback(
    function () {
      if (room && room.close) {
        room.close()
        room = {}
      }
    },
    [room]
  )

  useEffect(() => {
    loadCountVideo()
  }, [countFeed, token])

  useEffect(() => {
    return () => {
      close()
    }
  }, [])

  useEffect(() => {
    if (wsOpened) {
      room = createRoom(roomAll, {
        onFeedChange (msg) {
          loadCountVideo()
        }
      })
    } else {
      close()
    }
  }, [wsOpened])

  if (!token) {
    return <></>
  }

  return (
    <>
      <HeaderBar history={history} />
      <NavigationMenu history={history} count={countFeed} />
    </>
  )
}

const Content = props => {
  const history = useHistory()
  const token = mapGetters('token')
  const pathname = history.location.pathname

  console.log('okk', pathname)

  return (
    <div className={(token && 'content-wrapper') || ''}>
      <Switch>
        {!token && (
          <Redirect
            exact
            from={
              pathname != router.initialRoute &&
              pathname != router.loginRoute &&
              pathname != router.homeRoute
                ? pathname
                : router.homeRoute
            }
            to={{
              pathname: router.initialRoute,
              search: `${
                history.location.search ? history.location.search + '&' : ''
              }pathname=${pathname}`
            }}
          />
        )}
        <Route
          key={Math.random()}
          name='check'
          path='/check'
          component={withRouter(Splash)}
        />
        <Route
          key={Math.random()}
          name='login'
          path='/login'
          component={withRouter(Login)}
        />
        {Routes}
      </Switch>
    </div>
  )
}

const ContentRouter = props => {
  return (
    <>
      <Header />
      <Content />
    </>
  )
}

export const App = () => {
  // const history = useHistory();
  return (
    <Store>
      <HashRouter>
        <ContentRouter />
      </HashRouter>
    </Store>
  )
}
