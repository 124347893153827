import React from 'react'
import { Link } from 'react-router-dom'

class FilAriane extends React.Component {
  render () {
    const li3 = <li className='active'>{this.props.page}</li>
    const a2 = <a href='#'>{this.props.parent} </a>
    return (
      <div className='row'>
        <div className='col-md-12'>
          <ol className='breadcrumb'>
            <li>
              <Link exact='true' to='/'>
                <i className='fa fa-dashboard' /> Accueil{' '}
              </Link>
            </li>
            <li className={this.props.onlyTwo ? 'active' : ''}>
              {this.props.onlyTwo ? this.props.parent : a2}
            </li>
            {this.props.onlyTwo ? '' : li3}
          </ol>
        </div>
      </div>
    )
  }
}

export default FilAriane
