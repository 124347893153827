import React, { useEffect, useState } from 'react'

export default props => {
  const [id, setId] = useState(props.field || 'bagheram_ckeditor')

  useEffect(() => {
    setTimeout(() => {
      window.CKEDITOR.replace(`${id}`, {
        placeholder: 'Type the content here!',
        fullPage: true,
        allowedContent: true,
        autoGrow_onStartup: true,
        enterMode: window.CKEDITOR.ENTER_BR,
        toolbar: [
          {
            name: 'document',
            groups: ['mode', 'document', 'doctools'],
            items: [
              'Source',
              '-',
              'Save',
              'NewPage',
              'ExportPdf',
              'Preview',
              'Print',
              '-',
              'Templates'
            ]
          },
          {
            name: 'clipboard',
            groups: ['clipboard', 'undo'],
            items: [
              'Cut',
              'Copy',
              'Paste',
              'PasteText',
              'PasteFromWord',
              '-',
              'Undo',
              'Redo'
            ]
          },
          {
            name: 'editing',
            groups: ['find', 'selection', 'spellchecker'],
            items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt']
          },
          {
            name: 'forms',
            items: [
              'Form',
              'Checkbox',
              'Radio',
              'TextField',
              'Textarea',
              'Select',
              'Button',
              'ImageButton',
              'HiddenField'
            ]
          },
          {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: [
              'Bold',
              'Italic',
              'Underline',
              'Strike',
              'Subscript',
              'Superscript',
              '-',
              'CopyFormatting',
              'RemoveFormat'
            ]
          },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
            items: [
              'NumberedList',
              'BulletedList',
              '-',
              'Outdent',
              'Indent',
              '-',
              'Blockquote',
              'CreateDiv',
              '-',
              'JustifyLeft',
              'JustifyCenter',
              'JustifyRight',
              'JustifyBlock',
              '-',
              'BidiLtr',
              'BidiRtl',
              'Language'
            ]
          },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
          {
            name: 'insert',
            items: [
              'Image',
              'Flash',
              'Table',
              'HorizontalRule',
              'Smiley',
              'SpecialChar',
              'PageBreak',
              'Iframe'
            ]
          },
          { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
          { name: 'colors', items: ['TextColor', 'BGColor'] },
          { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
        ]
      })
      window.CKEDITOR.instances[`${id}`].on('change', function (e) {
        if (typeof props.onChange == 'function') {
          props.onChange(e.editor.getData())
        }
      })
    }, 0)
  }, [])

  useEffect(() => {
    setId(id)
  }, [props.field])

  return (
    <textarea
      cols='100'
      ref={props.forwardRef}
      id={id}
      name={id}
      defaultValue={props.defaultValue}
      rows='5'
      className='form-control'
      data-sample-short
    />
  )
}
