/** @format */

import React from 'react';

import useCheckListChips from '../sm/useCheckListChips'

export default function CheckListChips(props) {
  const { data, handleClick } = useCheckListChips(props)
  return (
    <React.Fragment>
      <div
        style={{
          display: props.checkedItems.keys.length ? 'block' : 'none',
          fontSize: '16px',
          padding: '5px 0px',
        }}
      >
        Eléments sélectionnés: {props.checkedItems.keys.length}
      </div>
      <div
        style={{
          ...(data.length
            ? {
                borderRadius: '4px',
              }
            : {}),
          width: 'auto',
          whiteSpace: 'nowrap',
          overflowX: 'scroll',
        }}
      >
        {data.map((item) => {
          return (
            <CheckedItemChip
              key={`item#${item[item.item_key]}`}
              {...{
                handleClick,
                item: { ...item, item_title: props.item_title },
                fetchNextById: props.fetchNextById,
              }}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

function CheckedItemChip({
  fetchNextById = () => {},
  item: itemData = {},
  ...props
} = {}) {
  const [item, setItem] = React.useState({ ...itemData });

  const handleClick = React.useCallback(() => {
    props.handleClick({ ...item });
  }, [item]);

  React.useEffect(() => {
    if (!item[item.item_title]) {
      (async () => {
        if (typeof fetchNextById === 'function') {
          await fetchNextById({ id: item[item.item_key] })
            .then((nItem) => {
              setItem((oItem) => {
                return {
                  ...oItem,
                  ...nItem,
                };
              });
            })
            .catch((e) => null);
        }
      })();
    }
  }, [item, fetchNextById]);

  return (
    <div style={styles.checkedItemChips} onClick={handleClick}>
      <span style={{ marginRight: '10px' }}>{item[item.item_title]}</span>
      <span className={'glyphicon glyphicon-remove text-danger'}></span>
    </div>
  );
}

const styles = {
  checkedItemChips: {
    border: 'thin solid pink',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'inline-block',
    marginRight: '10px',
    marginBottom: '10px',
    padding: '10px',
  },
};
