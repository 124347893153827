/** @format */

import React from 'react'

import LUtils from '../../../../logics/utils'

export default function useCheckListPagination ({
  word,
  page: currentPage,
  lastPage,
  fetchNext
}) {
  const { width } = useWindowDimensions()
  const pageRanges = React.useMemo(() => {
    const { min, max } = genRanges({
      currentPage,
      lastPage,
      step: width <= 550 ? 1 : 2
    })
    return {
      data: [...LUtils.range(min, max)],
      current: currentPage,
      last: lastPage,
      max,
      min
    }
  }, [currentPage, lastPage, width])

  const fetchPage = React.useCallback(
    ({ page }) => {
      if (page <= lastPage && page !== currentPage) {
        fetchNext({ word, page })
      }
    },
    [word, fetchNext, lastPage, currentPage]
  )

  const fetchFirstPage = React.useCallback(() => {
    fetchPage({ page: 1 })
  }, [fetchPage])

  const fetchLastPage = React.useCallback(() => {
    fetchPage({ page: lastPage })
  }, [fetchPage, lastPage])

  const fetchNextPage = React.useCallback(() => {
    const nextPage = currentPage + 1
    fetchPage({ page: nextPage <= lastPage ? nextPage : lastPage })
  }, [fetchPage, lastPage])

  const fetchPreviousPage = React.useCallback(() => {
    const prevPage = currentPage - 1
    fetchPage({ page: prevPage >= 1 ? prevPage : currentPage })
  }, [fetchPage, currentPage])

  return {
    pager: {
      range: { ...pageRanges },
      first: fetchFirstPage,
      last: fetchLastPage,
      go: fetchPage,
      next: fetchNextPage,
      previous: fetchPreviousPage
    }
  }
}

function genRanges ({ currentPage = 1, lastPage = 1, step = 2 } = {}) {
  if (lastPage > step * 2 + 1) {
    let maxUpperRange = currentPage + step
    const upperRangeOverflow = Math.abs(Math.min(lastPage - maxUpperRange, 0))
    maxUpperRange = maxUpperRange - upperRangeOverflow
    const maxLowerRange = currentPage - (step + upperRangeOverflow)
    return {
      min: Math.max(maxLowerRange, 1),
      max:
        maxLowerRange > 0
          ? maxUpperRange
          : maxUpperRange + Math.abs(maxLowerRange) + 1
    }
  } else {
    return {
      min: 1,
      max: lastPage
    }
  }
}

function useWindowDimensions () {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  )

  React.useEffect(() => {
    function handleResize () {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { ...windowDimensions }
}

function getWindowDimensions () {
  const { innerWidth, innerHeight } = window
  return {
    width: innerWidth,
    height: innerHeight
  }
}
