/** @format */

import React from 'react'

export default function useFooter (props) {
  const handleClose = React.useCallback(() => {
    props.handleClose({ update: false })
  }, [props])

  const handleSave = React.useCallback(() => {
    props.handleClose({ update: true })
  }, [props])

  return {
    handleClose,
    handleSave
  }
}
