import React from 'react'
import FilAriane from '../../components/shared/FilAriane'

class Medias extends React.Component {
  componentDidMount () {
    const navarray = document.getElementsByClassName('nava')
    for (let i = 0; i < navarray.length; i++) {
      navarray[i].className = 'nava'
    }
    document.getElementById('nav-contenu').className += ' active'
  }

  render () {
    return (
      <section className='content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='block-white flex-row'>
              <h1 className='title-page'>
                <i className='fa fa-info' aria-hidden='true' />
                Liste des médias
              </h1>
              <a href='#'>
                <div className='btn btn-add mgt-15'>Ajouter</div>
              </a>
            </div>
          </div>
        </div>

        <FilAriane parent='Contenu' page='Médias' />

        <div className='row'>
          <div className='col-md-2'>
            <img
              className='img-responsive'
              src='dist/img/_U3A5638.jpg'
              alt=''
            />
          </div>
          <div className='col-md-2'>
            <img
              className='img-responsive'
              src='dist/img/_U3A5647.jpg'
              alt=''
            />
          </div>
          <div className='col-md-2'>
            <img
              className='img-responsive'
              src='dist/img/_U3A5658.jpg'
              alt=''
            />
          </div>
          <div className='col-md-2'>
            <img
              className='img-responsive'
              src='dist/img/_U3A5660.jpg'
              alt=''
            />
          </div>
          <div className='col-md-2'>
            <img
              className='img-responsive'
              src='dist/img/IMGP0331.jpg'
              alt=''
            />
          </div>
          <div className='col-md-2'>
            <img
              className='img-responsive'
              src='dist/img/IMGP0334.jpg'
              alt=''
            />
          </div>
          <div className='col-md-2'>
            <img
              className='img-responsive'
              src='dist/img/IMGP0353.jpg'
              alt=''
            />
          </div>
        </div>
      </section>
    )
  }
}

export default Medias
