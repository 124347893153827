import React, { useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import constants from '../../constants'

import axios from 'axios'
export default withRouter(props => {
  const id = useMemo(() => props.field, [props.field])

  const [items, setItems] = useState([])

  const search = props.location.search

  function infiniteScrool () {
    const baseUrl = constants.host
    window.jQuery(`#infinitSelect`).select2({
      ajax: {
        url: `${baseUrl}/admin/v1/passions`,
        dataType: 'json',
        type: 'GET',
        data: function (params) {
          var query = {
            word: params.term,
            p: params.page || 1
          }
          return query
        },
        processResults: function (data, params) {
          params.page = params.page || 1
          var results = []
          window.jQuery.map(data.data, function (values, index) {
            results.push({
              id: values.value,
              text: values.title
            })
          })
          return {
            results: results,
            pagination: {
              more: params.page * 50 < data.total
            }
          }
        },
        cache: true
      }
    })
  }

  function load () {
    if (!props.field) return
    const models = props.field.split('_')

    let mdl = models[1]
    if (mdl == 'send' || mdl == 'dest') {
      mdl = 'user'
    }

    axios
      .get(`/admin/v1/${mdl}s${search}`, {
        furtif: true,
        params: { autocomplete: true }
      })
      .then(r => {
        const DATA = r.data

        if (DATA.data && DATA.data.length > 0) {
          setItems(
            DATA.data.map(item => {
              return {
                text:
                  mdl == 'user'
                    ? item.user_login
                    : mdl == 'vid'
                    ? item.vid_file
                    : item[`${mdl}_title`],
                value: item[`${mdl}_id`]
              }
            })
          )
        }
      })
  }

  function onChange ({ value, text }) {
    if (props.onChange && typeof props.onChange == 'function') {
      props.onChange({ value, text })
    }
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    if (props.defaultDatas) {
      const { isCat, ...rest } = props.defaultDatas
      var newOption = new Option(rest.text, rest.id, false, true)
      window.jQuery('#infinitSelect').append(newOption).trigger('change')
      infiniteScrool()
    }
  }, [props.defaultDatas])

  useEffect(() => {
    window.selectValue = items.length
    window
      .jQuery(`#${id}`)
      .select2({
        placeholder: props.placeholder,
        allowClear: true,
        tags: false,
        tokenSeparators: [',', ' ']
      })
      .val(props.defaultValue)
      .trigger('change')

    window.jQuery(`#${id}`).on('change', function (e) {
      onChange({
        value: e.target.value,
        text:
          e.target.options[e.target.selectedIndex] &&
          e.target.options[e.target.selectedIndex].text
      })

      e.target.values = window.jQuery(`#${id}`).select2('data')

      e.target.clear = function () {
        window.jQuery(`#${id}`).val(null).trigger('change')
      }
    })
  }, [items])

  return !props.defaultDatas ? (
    <>
      <select
        id={id}
        defaultValue={props.defaultValue}
        onChange={onChange}
        ref={props.forwardRef}
        style={{ borderRadius: '5px !important', height: '40px !important' }}
        multiple={props.multiple}
        disabled={props.disabled}
      >
        {items.map((item, i) => (
          <option key={i} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </>
  ) : (
    <>
      <select
        id='infinitSelect'
        onChange={onChange}
        style={{ borderRadius: '5px !important', height: '40px !important' }}
      ></select>
    </>
  )
})
