import React, { useEffect, useState } from 'react'

import { mapActions } from '../../store'

export default props => {
  const [showClear, setShowClear] = useState(!!props.defaultValue)
  const [id, setId] = useState(`searchinput_${props.model}`)
  const [idClear, setIdClear] = useState(`searchclear_${props.model}`)
  const { getMenuData } = mapActions()
  var { title } = getMenuData(props.model)

  useEffect(() => {
    window.jQuery(`#${idClear}`).click(function () {
      window.jQuery(`#${id}`).val('')
      if (props.onClear && typeof props.onClear == 'function') {
        props.onClear()
        setShowClear(false)
      }
    })
  }, [props.model])

  useEffect(() => {
    window.jQuery(`#${id}`).val(props.defaultValue)
  }, [props.defaultValue])

  const onChange = () => {
    setShowClear(!!window.jQuery(`#${id}`).val())
    if (
      props.onClear &&
      typeof props.onClear == 'function' &&
      window.jQuery(`#${id}`).val() == ''
    ) {
      props.onClear()
      setShowClear(false)
    }
  }

  function onKeyUp (v) {
    window
      .jQuery(`#${id}`)
      .val(String(window.jQuery(`#${id}`).val()).replace('. ', '  '))
    if (typeof props.onKeyUp == 'function') {
      props.onKeyUp(v)
    }
  }

  return (
    <div
      className='btn-group col-xs-12 d-flex flex-row'
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: 0,
        borderX: 'thin solid blue'
      }}
    >
      <input
        id={id}
        type='search'
        className='form-control searchinput'
        ref={props.forwardRef}
        defaultValue={props.defaultValue}
        autoCorrect='off'
        autoComplete='off'
        onChange={onChange}
        onKeyUp={onKeyUp}
        placeholder={`Rechercher dans les ${String(title).toLowerCase()}`}
        aria-label={`Rechercher dans les ${String(title).toLowerCase()}`}
      />
      <span
        id={idClear}
        className='fa fa-times searchclear'
        style={{ display: showClear ? 'block' : 'none' }}
      />
    </div>
  )
}
