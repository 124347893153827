/** @format */
import React from 'react'

import countries from '../../../../logics/countries'

export default function useCountryData ({ include = false, meta = {} } = {}) {
  const [countryDataConfig, setCountryDataConfig] = React.useState({
    word: '',
    page: 1
  })

  const dataMapped = React.useMemo(() => {
    return include
      ? [
          ...(countries || []).map(({ country, iso }) => {
            return {
              country_id: iso.toUpperCase(),
              country_title: country
            }
          })
        ]
      : null
  }, [include])

  const dataFiltered = React.useMemo(() => {
    const wordLowerCased = countryDataConfig.word.toLowerCase()
    return (dataMapped || []).filter(
      item => item.country_title.toLowerCase().indexOf(wordLowerCased) > -1
    )
  }, [dataMapped, countryDataConfig])

  const dataPaged = React.useMemo(() => {
    const { currentPage, pages } = (dataFiltered || []).reduce(
      ({ count, currentPage, pages }, item) => {
        if (count < meta.perPage) {
          count += 1
        } else {
          count = 1
          currentPage += 1
        }
        return {
          count,
          pages: {
            ...pages,
            [currentPage]: [...(pages[currentPage] || []), { ...item }]
          },
          currentPage
        }
      },
      { count: 0, currentPage: 1, pages: {} }
    )
    return {
      total: dataFiltered.length,
      currentPage,
      pages
    }
  }, [dataFiltered])

  const result = React.useMemo(() => {
    return {
      ...countryDataConfig,
      total: dataPaged.total,
      lastPage: dataPaged.currentPage,
      data: [...(dataPaged.pages[countryDataConfig.page] || [])]
    }
  }, [countryDataConfig, dataPaged, meta])

  const fetchNext = React.useCallback(({ word = '', page = 1 } = {}) => {
    setCountryDataConfig({
      word,
      page
    })
  }, [])

  const fetchNextById = React.useCallback(({ id = '' } = {}) => {
    if (id) {
      const [{ country: country_title, iso } = {}] =
        countries.filter(
          ({ iso } = {}) => iso.toLowerCase() === id.toLowerCase()
        ) || []
      if (iso) {
        return Promise.resolve({
          country_id: iso.toUpperCase(),
          country_title
        })
      }
    }
    return Promise.resolve(null)
  }, [])

  return include
    ? {
        props: {
          ...result,
          ...meta,
          fetchNext,
          fetchNextById
        }
      }
    : {}
}
