import React from 'react'

class OGForm extends React.Component {
  constructor (props) {
    super(props)

    var today = new Date()
    var month
    switch (today.getMonth()) {
      case 0:
        month = 'Janv'
        break
      case 1:
        month = 'Févr'
        break
      case 2:
        month = 'Mars'
        break
      case 3:
        month = 'Avr'
        break
      case 4:
        month = 'Mai'
        break
      case 5:
        month = 'Juin'
        break
      case 6:
        month = 'Juill'
        break
      case 7:
        month = 'Août'
        break
      case 8:
        month = 'Sept'
        break
      case 9:
        month = 'Oct'
        break
      case 10:
        month = 'Nov'
        break
      case 11:
        month = 'Déc'
        break
      default:
        break
    }

    this.state = {
      title: '',
      slug: '',
      desc: '',
      date: month + ' ' + today.getDate() + ', ' + today.getFullYear()
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    if (event.target.name === 'form[og_titre]') {
      this.setState({ title: event.target.value })
    } else if (event.target.name === 'form[og_slug]') {
      this.setState({ slug: event.target.value })
    } else if (event.target.name === 'form[og_desc]') {
      this.setState({ desc: event.target.value })
    }
  }

  render () {
    return (
      <div>
        <div className='row'>
          <div className='col-xs-6'>
            <div className='og-apercu block-white'>
              <div className='og-title'>
                {this.state.title == '' ? 'Exemple de titre' : this.state.title}
              </div>
              <div className='og-url'>
                https://www.uxia-agency.fr/{this.state.slug}
              </div>
              <div className='og-desc'>
                <span className='og-date'>{this.state.date} - </span>
                {this.state.desc == ''
                  ? 'Exemple de description'
                  : this.state.desc}
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-6'>
            <div className='form-group'>
              <input
                type='text'
                name='form[og_titre]'
                className='form-control'
                placeholder='Titre'
                maxLength={70}
                value={this.state.title}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-6'>
            <div className='form-group'>
              <input
                type='text'
                name='form[og_slug]'
                className='form-control'
                placeholder='slug-url-exemple'
                maxLength={200}
                value={this.state.slug}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-9'>
            <div className='form-group'>
              <input
                type='text'
                name='form[og_desc]'
                className='form-control'
                placeholder='Description'
                maxLength={230}
                value={this.state.desc}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OGForm
