/** @format */

import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import { mapGetters, mapActions, genState } from '../../store'
import LUtils from '../../logics/utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function App () {
  const [charts, models] = mapGetters(['charts', 'models'])
  const dashboard = mapGetters('dashboard')
  const getCharts = mapActions('getCharts')

  const config = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true
        //   text: 'Chart.js Bar Chart'
      }
    }
  }

  const [selected, setSelected] = React.useState(new Date().getFullYear())

  const [options, setOptions] = React.useState([
    {
      label: '2021',
      value: 2021
    },
    {
      label: '2022',
      value: 2022
    },
    {
      label: '2023',
      value: 2023
    }
  ])

  const [data, setDate] = React.useState({
    labels: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Décembre'
    ],
    datasets: []
  })

  function generateData (_data) {
    for (let i = 0; i < data.labels.length; i++) {
      const label = `${selected}-${LUtils.pad(i + 1)}`
      const item = _data.find(d => d.label == label)
      _data[i] = {
        label,
        value: item ? item.value : 0
      }
    }
    return _data.map(d => d.value)
  }

  React.useEffect(() => {
    getCharts()
  }, [])

  React.useEffect(() => {
    if (!charts[selected]) {
      dashboard.menu.map(m => {
        if (!charts[selected]) {
          charts[selected] = {}
        }
        if (models.indexOf((m.model2 || m.model).slice(0, -1)) > -1) {
          charts[selected][m.model2 || m.model] = {
            data: [],
            ...m
          }
        }
      })
    }
    if (charts[selected]) {
      setDate(v => ({
        ...v,
        datasets: Object.keys(charts[selected]).map(model => ({
          label: charts[selected][model].title,
          data: generateData(charts[selected][model].data),
          borderColor: charts[selected][model].bgc,
          backgroundColor: charts[selected][model].bgc
        }))
      }))
    }

    setOptions(Object.keys(charts).map(o => ({ label: o, value: +o })))
  }, [charts, selected, dashboard])

  function onChange (e) {
    setSelected(+e.target.value)
  }

  return (
    <>
      <div className='flex-row' style={{ justifyContent: 'center' }}>
        <h4>
          <center>
            <span style={{ fontSize: 25 }}>
              <span className={'text-capitalize'}>Statistique</span> des données
              de l'année{' '}
              <span className='text-primary text-bold'>{selected}</span>
            </span>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <select
                className='form-control'
                onChange={onChange}
                style={{ borderRadius: 5 }}
                value={selected}
              >
                {options.map(o => (
                  <option key={o.value}>{o.label}</option>
                ))}
              </select>
            </div>
          </center>
        </h4>
      </div>
      <div className='col-12 flex-row' style={{ justifyContent: 'center' }}>
        <div className='col-xs-12 col-sm-12 col-md-11 col-lg-10'>
          <Line options={config} data={data} />
        </div>
      </div>
    </>
  )
}
