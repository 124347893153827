import React, { useEffect, useState } from 'react'

import BAutocomplete from './Bagheram-autocomplete'
import { mapActions } from '../../store'

export default props => {
  const refNotif = {}
  let data = {}
  const [message, setMessage] = useState('Veuillez remplir tous les champs')
  const [valid, setValid] = useState(true)
  const [dest_type, setDestType] = useState(0)
  const [ios, setIos] = useState(true)
  const [android, setAndroid] = useState(true)
  const [majeur, setMajeur] = useState(false)
  const [mineur, setMineur] = useState(false)
  const [masculin, setMasculin] = useState(false)
  const [feminin, setFeminin] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const send = mapActions('send_notif')

  function normalize (content) {
    const pattern = /\{\s*\{(.*?)\}\s*\}/g

    if (!content) {
      return content
    }
    content = content.replace(/(?<!\$)(\s*\}){2,}/g, '}}')
    content = content.replace(/(?<!\$)(\s*\{){2,}/g, '{{')
    content = content.replace(/\{\s*\{(\s*)\}\s*\}/g, '')

    const misy = pattern.test(content)

    if (misy) {
      content = content.replace(/(?<!\$)\s*\}\s*\}/g, '|-|')
      content = content.replace(/(?<!\$)\{\s*\{\s*/g, '|+|')

      content = content.replace(/\$\s*\}{1}/g, '£]') // }
      content = content.replace(/\$\s*\{{1}/g, '£[') // {
      content = content.replace(/(\$\s*\$){1}/g, '§') // $
      content = content.replace(/(\$\s*){1,}|(\}\s*){1,}|(\{\s*){1,}/g, '')
    } else {
      content = content.replace(/(\$\s*\$){1}/g, '§') // $
      content = content.replace(/\$\s*\}{1}/g, '}') // }
      content = content.replace(/\$\s*\{{1}/g, '{') // {
    }

    if (misy) {
      content = content.split('|-|').join('}}')
      content = content.split('|+|').join('{{')
      content = content.split('£]').join('|-|') // .join('}')
      content = content.split('£[').join('|+|') // .join('{')
    }

    content = content.split('§').join('$')

    return content
  }

  function sendNotif () {
    const tabs = []
    if (dest_type == 0) {
      data = {
        ios,
        android,
        majeur,
        mineur,
        masculin,
        feminin,
        users: [],
        title,
        content: normalize(content)
      }
    } else {
      if (refNotif.users && refNotif.users.values.length > 0) {
        for (var i = refNotif.users.values.length - 1; i >= 0; i--) {
          tabs.push(parseInt(refNotif.users.values[i].id))
        }
      }
      data = {
        ios: false,
        android: false,
        majeur: false,
        mineur: false,
        masculin: false,
        feminin: false,
        users: tabs,
        title,
        content: normalize(content)
      }
    }

    if (
      data.title == '' ||
      data.content == '' ||
      (dest_type == 1 && data.users.length == 0)
    ) {
      setValid(false)
      if (dest_type == 1 && data.users.length == 0) {
        setMessage('Veuillez entrer un destinataire')
      }
    } else {
      window.BootstrapDialog.show({
        message: "Etes-vous sur d'envoyer les notifications push ?",
        buttons: [
          {
            label: 'Annuler',
            cssClass: 'bagheram-btn-grey',
            action: function (dialogItself) {
              dialogItself.close()
            }
          },
          {
            label: 'Envoyer',
            // no title as it is optional
            cssClass: 'btn-primary',
            data: {
              js: 'btn-confirm',
              'user-id': '3'
            },
            action: function (dialogItself) {
              dialogItself.close()
              close()
              return send(data)
                .then(() => {
                  window.BootstrapDialog.show({
                    message: 'Opération succès',
                    buttons: [
                      {
                        label: 'ok',
                        action: function (dialogItself) {
                          dialogItself.close()
                        }
                      }
                    ]
                  })
                })
                .catch(() => {
                  window.BootstrapDialog.show({
                    message: "Oups une erreur s'est produite",
                    buttons: [
                      {
                        label: 'ok',
                        action: function (dialogItself) {
                          dialogItself.close()
                        }
                      }
                    ]
                  })
                })
            }
          }
        ]
      })
    }
  }

  function close () {
    if (props.onClose && typeof props.onClose == 'function') {
      props.onClose()
    }
  }

  function checkOK () {
    if (!!title && !!content) {
      if (
        dest_type == 1 &&
        refNotif.users &&
        refNotif.users.values &&
        refNotif.users.values.length == 0
      ) {
        setValid(false)
      } else {
        setValid(true)
      }
    } else {
      setValid(false)
    }
  }

  useEffect(() => {
    if (!!title && !!content) {
      setValid(true)
    }
  }, [title, content])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          border: '1.5px solid #605ca8',
          borderRadius: 20,
          marginBottom: 20,
          padding: 10
        }}
      >
        <div
          className='form-group col-md-12 col-sm-12 col-xl-12 col-lg-12'
          style={{ marginTop: 10 }}
        >
          <h2>Notifications push</h2>
        </div>
        {!valid && (
          <div
            className='col-12 col-md-12 col-sm-12 col-xl-12 col-lg-12'
            style={{ width: '100%' }}
          >
            <div
              className='alert'
              style={{
                backgroundColor: '#F2DEDE',
                color: '#A84442',
                border: 'thin solid #A84442'
              }}
              role='alert'
            >
              {message}
            </div>
          </div>
        )}
        <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6'>
          <div className='form-group' style={{ marginTop: 10 }}>
            <label style={{ marginLeft: 0 }}>Titre</label>
            <input
              type='text'
              className='form-control'
              onChange={e => setTitle(e.target.value)}
              ref={node => (refNotif.title = node)}
              id='exampleFormControlInput1'
              placeholder='Titre'
              style={{ borderRadius: 5 }}
            />
          </div>
          <div className='form-group'>
            <label style={{ marginLeft: 0 }}>Contenu</label>
            <textarea
              className='form-control'
              id='exampleFormControlTextarea1'
              onChange={e => setContent(e.target.value)}
              ref={node => (refNotif.content = node)}
              placeholder='Contenu'
              rows='3'
              style={{ borderRadius: 5 }}
            />
          </div>
        </div>
        <div
          className='col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6'
          style={{ minHeight: '100% !important' }}
        >
          <div className='form-group' style={{ marginTop: 10 }}>
            <label style={{ marginLeft: 0 }}>Destinataires</label>
            <div className='form-group'>
              <label
                className='radio-inline'
                style={{ marginLeft: 0, borderX: 'thin solid red', height: 30 }}
              >
                <input
                  type='radio'
                  name='dest_type'
                  onChange={e => setDestType(0)}
                  checked={dest_type == 0}
                  style={{ width: 20, height: 18 }}
                />
                <span style={{ marginLeft: 10 }}>Destinataires groupés</span>
              </label>
              <label className='radio-inline' style={{ height: 30 }}>
                <input
                  type='radio'
                  name='dest_type'
                  onChange={e => setDestType(1)}
                  checked={dest_type == 1}
                  style={{ width: 20, height: 18 }}
                />
                <span style={{ marginLeft: 10 }}>Destinataires précis</span>
              </label>
            </div>
            {dest_type == 1 && (
              <div className='form-group' style={{ marginTop: 10 }}>
                <BAutocomplete
                  forwardRef={node => (refNotif.users = node)}
                  className='form-control'
                  model={props.model}
                  onChange={checkOK}
                  field='login_user'
                  placeholder='Utilisateurs'
                  multiple
                  {...props}
                />
              </div>
            )}
            {dest_type == 0 && (
              <div
                className='d-flex flex-row'
                style={{ marginTop: 10, flexWrap: 'wrap' }}
              >
                <div
                  className='form-check d-flex flex-row'
                  style={{
                    minWidth: 70,
                    paddingRight: 20,
                    justifyContent: 'center'
                  }}
                >
                  <input
                    className='form-check-input'
                    style={{ width: 15, height: 15 }}
                    type='checkbox'
                    onChange={e => setIos(e.target.checked)}
                    checked={ios}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='defaultCheck1'
                    style={{ marginLeft: 15 }}
                  >
                    Ios
                  </label>
                </div>
                <div
                  className='form-check d-flex flex-row'
                  style={{
                    minWidth: 70,
                    paddingRight: 20,
                    justifyContent: 'center',
                    borderX: 'thin solid red'
                  }}
                >
                  <input
                    className='form-check-input'
                    style={{ width: 15, height: 15 }}
                    type='checkbox'
                    onChange={e => setAndroid(e.target.checked)}
                    checked={android}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='defaultCheck1'
                    style={{ marginLeft: 15 }}
                  >
                    Android
                  </label>
                </div>
                <div
                  className='form-check d-flex flex-row'
                  style={{
                    minWidth: 70,
                    paddingRight: 20,
                    justifyContent: 'center'
                  }}
                >
                  <input
                    className='form-check-input'
                    style={{ width: 15, height: 15 }}
                    type='checkbox'
                    onChange={e => setMajeur(e.target.checked)}
                    checked={majeur}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='defaultCheck1'
                    style={{ marginLeft: 15 }}
                  >
                    Majeur
                  </label>
                </div>
                <div
                  className='form-check d-flex flex-row'
                  style={{
                    minWidth: 70,
                    paddingRight: 20,
                    justifyContent: 'center'
                  }}
                >
                  <input
                    className='form-check-input'
                    style={{ width: 15, height: 15 }}
                    type='checkbox'
                    onChange={e => setMineur(e.target.checked)}
                    checked={mineur}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='defaultCheck1'
                    style={{ marginLeft: 15 }}
                  >
                    Mineur
                  </label>
                </div>
                <div
                  className='form-check d-flex flex-row'
                  style={{
                    minWidth: 70,
                    paddingRight: 20,
                    justifyContent: 'center'
                  }}
                >
                  <input
                    className='form-check-input'
                    style={{ width: 15, height: 15 }}
                    type='checkbox'
                    onChange={e => setMasculin(e.target.checked)}
                    checked={masculin}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='defaultCheck1'
                    style={{ marginLeft: 15 }}
                  >
                    Masculin
                  </label>
                </div>
                <div
                  className='form-check d-flex flex-row'
                  style={{
                    minWidth: 70,
                    paddingRight: 20,
                    justifyContent: 'center'
                  }}
                >
                  <input
                    className='form-check-input'
                    style={{ width: 15, height: 15 }}
                    type='checkbox'
                    onChange={e => setFeminin(e.target.checked)}
                    checked={feminin}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='defaultCheck1'
                    style={{ marginLeft: 15 }}
                  >
                    Feminin
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className='col-md-12 d-flex flex-row'
          style={{ justifyContent: 'flex-end', marginBottom: 20 }}
        >
          <button
            href='#!'
            className='btn'
            type='button'
            onClick={close}
            style={{
              borderRadius: 20,
              fontWeight: 'normal',
              background: 'grey',
              color: 'white',
              height: 35,
              fontSize: 11
            }}
          >
            Fermer
          </button>
          <button
            href='#!'
            className='btn btn-default btn-outline-secondary bg-purple'
            type='button'
            onClick={sendNotif}
            style={{
              borderRadius: 20,
              fontWeight: 'normal',
              height: 35,
              fontSize: 11,
              marginLeft: 10
            }}
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
  )
}
