/** @format */

import countries from './countries'
import LG from './index'

function getAllCountries () {
  return countries
}

function getCountryFromIso (iso = null) {
  if (iso) return countries.find(c => c.iso == iso) || {}
  return countries
}

function getCountry (id) {
  return countries.find(r => r.id == id) || {}
}

async function getCity (iso, id) {
  var data = await LG.getCity({ iso })
  return data.find(c => c.id == id) || {}
}

export default {
  countries,
  getAllCountries,
  getCountryFromIso,
  getCountry,
  getCity
}
