/** @format */

import React from 'react'

export default function useCheckListChips ({ checkedItems }) {
  const handleClick = React.useCallback(
    (item = null) => {
      checkedItems.delete(item)
    },
    [checkedItems]
  )

  return {
    data: checkedItems.data,
    handleClick
  }
}
