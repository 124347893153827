import React from 'react'

export default props => {
  return (
    <>
      {React.useMemo(() => {
        return props.children
      }, [props.data])}
    </>
  )
}
