import React, { useCallback, useEffect, useState } from 'react'
export const OBJ = {}
export const GOBJ = {}
export let INIT = () => null
export let STORES_TMP = {}
export let STORES = {}
export const GETTERS = {}
export const STATES = {}
export const ACTIONS = {}
export const MUTATIONS = {}
export let DEBUG_MODE = false

export function isClassComponent (component) {
  return (
    component &&
    typeof component === 'function' &&
    !!component.prototype.isReactComponent
  )
}

export function isFunctionComponent (component) {
  return (
    component &&
    typeof component === 'function' &&
    String(component).includes('return React.createElement')
  )
}

export function isReactComponent (component) {
  return (
    (component && isClassComponent(component)) || isFunctionComponent(component)
  )
}

export function isElement (element) {
  return element && React.isValidElement(element)
}

export function isDOMTypeElement (element) {
  return element && isElement(element) && typeof element.type === 'string'
}

export function isCompositeTypeElement (element) {
  return element && isElement(element) && typeof element.type === 'function'
}

function objectDiff (obj1, obj2) {
  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1
  }

  if (
    isClassComponent(obj2) ||
    isFunctionComponent(obj2) ||
    isReactComponent(obj2) ||
    isElement(obj2) ||
    isDOMTypeElement(obj2) ||
    isCompositeTypeElement(obj2)
  ) {
    return obj2
  }

  //
  // Variables
  //

  var diffs = {}
  var key

  var arraysMatch = function (arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) {
      return false
    }

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
      let t1 = Object.prototype.toString.call(arr1[i])
      let t2 = Object.prototype.toString.call(arr2[i])
      if (arr1[i] !== arr2[i]) {
        if (t1 === t2) {
          if (t1 === '[object Object]' && t2 === '[object Object]') {
            if (!objectEquals(arr1[i], arr2[i])) {
              return false
            }
          } else if (t1 === '[object Array]' && t2 === '[object Array]') {
            return arraysMatch(arr1[i], arr2[i])
          }
        }

        return false
      }
    }

    return true
  }

  //
  // Compare our objects
  //
  var compare = function (item1, item2, key) {
    // Get the object type
    var type1 = Object.prototype.toString.call(item1)
    var type2 = Object.prototype.toString.call(item2)

    // If type2 is undefined it has been removed
    if (type2 === '[object Undefined]') {
      diffs[key] = null
      return
    }

    // If items are different types
    if (type1 !== type2) {
      diffs[key] = item2
      return
    }

    // If an object, compare recursively
    if (type1 === '[object Object]') {
      var objDiff = objectDiff(item1, item2)
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff
      }
      return
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2
      }
      return
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2
      }
    }
  }

  // Loop through the first object
  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key)
    }
  }

  // Loop through the second object and find missing items
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key]
      }
    }
  }

  // Return the object of differences
  return diffs
}

function clone (item) {
  if (!item) {
    return item
  } // null, undefined values check

  if (
    isClassComponent(item) ||
    isFunctionComponent(item) ||
    isReactComponent(item) ||
    isElement(item) ||
    isDOMTypeElement(item) ||
    isCompositeTypeElement(item)
  ) {
    return item
  }

  var types = [Number, String, Boolean],
    result

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(function (type) {
    if (item instanceof type) {
      result = type(item)
    }
  })

  if (typeof result === 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = []
      item.forEach(function (child, index, array) {
        result[index] = clone(child)
      })
    } else if (typeof item === 'object') {
      // testing that this is DOM
      if (item.nodeType && typeof item.cloneNode === 'function') {
        result = item.cloneNode(true)
      } else if (!item.prototype) {
        // check that this is a literal
        if (item instanceof Date) {
          result = new Date(item)
        } else {
          // it is an object literal
          result = {}
          for (var i in item) {
            result[i] = clone(item[i])
          }
        }
      } else {
        // depending what you would like here,
        // just keep the reference, or create new object
        if (false && item.constructor) {
          // would not advice to do that, reason? Read below
          result = new item.constructor()
        } else {
          result = item
        }
      }
    } else {
      result = item
    }
  }

  return result
}

function objectEquals (x, y) {
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y
  }

  if (x.constructor !== y.constructor) {
    return false
  }

  if (x instanceof Function) {
    return x === y
  }

  if (x instanceof RegExp) {
    return x === y
  }

  if (x === y || x.valueOf() === y.valueOf()) {
    return true
  }

  if (Array.isArray(x) && x.length !== y.length) {
    return false
  }

  if (x instanceof Date) {
    return false
  }

  if (!(x instanceof Object)) {
    return false
  }

  if (!(y instanceof Object)) {
    return false
  }

  var p = Object.keys(x)

  return (
    Object.keys(y).every(function (i) {
      return p.indexOf(i) !== -1
    }) &&
    p.every(function (i) {
      return objectEquals(x[i], y[i])
    })
  )
}

export function genState (statename, full) {
  let res = null
  if (full) {
    res = STATES[statename]
  } else {
    let statenameArr = statename.split('/')
    if (statenameArr.length === 2) {
      let storename = statenameArr[0]
      let statename = statenameArr[1]
      if (STATES[storename]) {
        if (!(statename in STATES[storename])) {
          console.warn(
            'Warning: ',
            `State ${statename} not exist in ${storename} store`
          )
          res = null
        } else {
          res = STATES[storename][statename]
        }
      } else {
        console.warn('Warning: ', `State ${storename} not found`)
      }
    } else {
      if (!(statename in STATES.main)) {
        console.warn('Warning: ', `State ${statename} not exist in main store`)
        res = null
      } else {
        // console.log(state.main)
        res = STATES.main[statename]
      }
    }
  }

  return res
}

function genGetter (gettername) {
  let getternameArr = gettername.split('/')
  let res = null
  if (getternameArr.length === 2) {
    let storename = getternameArr[0]
    let gettername = getternameArr[1]
    if (GETTERS[storename]) {
      if (
        !(gettername in GETTERS[storename]) ||
        typeof GETTERS[storename][gettername] !== 'function'
      ) {
        console.error(
          'Warning: ',
          `Getter ${gettername} not exist in ${storename} store`
        )
        res = null
      } else {
        res = GETTERS[storename][gettername](STATES[storename])
      }
    } else {
      console.error('Warning: ', `Store ${storename} not found`)
    }
  } else {
    if (
      !(gettername in GETTERS.main) ||
      typeof GETTERS.main[gettername] !== 'function'
    ) {
      console.error('Warning: ', `Getter ${gettername} not exist in main store`)
      res = null
    } else {
      // console.log(GETTERS.main, gettername, GETTERS.main[gettername](STATES.main))
      res = GETTERS.main[gettername](STATES.main)
    }
  }

  return res
}

function genMutation (mutationName) {
  let mutationNameArr = mutationName.split('/')
  let res = () => null
  if (mutationNameArr.length === 2) {
    let storename = mutationNameArr[0]
    let mutationName = mutationNameArr[1]
    if (MUTATIONS[storename]) {
      if (
        !(mutationName in MUTATIONS[storename]) ||
        typeof MUTATIONS[storename][mutationName] !== 'function'
      ) {
        console.error(
          'Warning: ',
          `Mutation ${mutationName} not exist in ${storename} store`
        )
        res = () => null
      } else {
        res = v => Promise.resolve(mutate(storename, mutationName, v))
      }
    } else {
      console.error('Warning: ', `Store ${storename} not found`)
    }
  } else {
    // console.log(MUTATIONS.main, mutationName);
    if (
      !(mutationName in MUTATIONS.main) ||
      typeof MUTATIONS.main[mutationName] !== 'function'
    ) {
      console.error(
        'Warning: ',
        `Mutation ${mutationName} not exist in main store`
      )
      res = () => null
    } else {
      res = v => Promise.resolve(mutate('main', mutationName, v))
    }
  }

  return res
}

function genSetState (statename) {
  let statenameArr = statename.split('/')
  let res = null
  if (statenameArr.length === 2) {
    let storename = statenameArr[0]
    let statename = statenameArr[1]
    if (STATES[storename]) {
      if (!(statename in STATES[storename])) {
        console.error(
          'Warning: ',
          `State ${statename} not exist in ${storename} store`
        )
        res = null
      } else {
        res = v => (STATES[storename][statename] = v)
      }
    } else {
      console.error('Warning: ', `State ${storename} not found`)
    }
  } else {
    if (!(statename in STATES.main)) {
      console.error('Warning: ', `State ${statename} not exist in main store`)
      res = null
    } else {
      // console.log(state.main)
      res = v => (STATES.main[statename] = v)
    }
  }

  return res
}

async function mutate (storename, _type, payload) {
  if (
    !(
      MUTATIONS[storename][_type] ||
      typeof MUTATIONS[storename][_type] === 'function'
    )
  ) {
    return console.error(
      'Warning: ',
      `Mutation ${_type} not found in ${storename} Store`
    )
  }

  const state = STATES[storename]
  const saveState = clone(state)
  const { commit, _getState, dispatch, state: _state } = generateCDGS(storename)

  await MUTATIONS[storename][_type](state, payload, {
    commit: commit,
    getState: _getState,
    state: _state,
    dispatch: dispatch
  })

  let diff = objectDiff(saveState, state)
  for (let name in diff) {
    let key = storename + '/' + name
    if (
      typeof state[name] === 'function' &&
      typeof saveState[name] !== 'function'
    ) {
      genSetState(key)(state[name](saveState[name]))
    } else {
      genSetState(key)(state[name])
    }

    if (DEBUG_MODE && name == 'currentRouteName') {
      console.log(3, key, saveState[name], '===>', state[name], GOBJ)
    }
    for (const getterName in GETTERS[storename]) {
      if (
        GETTERS[storename][getterName](state) !==
        GETTERS[storename][getterName](saveState)
      ) {
        let _key = `${storename}/${getterName}`
        if (storename == 'main') {
          _key = getterName
        }
        if (GOBJ[_key]) {
          for (const callerName in GOBJ[_key].data) {
            try {
              GOBJ[_key].data[callerName][1](
                GETTERS[storename][getterName](state)
              )
            } catch (e) {}
          }
        }
      }
    }
    if (OBJ[key]) {
      for (const callerName in OBJ[key].data) {
        OBJ[key].data[callerName][1](state[name])
      }
    }
  }
}

function generateCDGS (storename) {
  let commit = (_type, payload, option) => {
    if (option && option.root) {
      let dispatchSplit = _type.split('/')

      if (dispatchSplit.length === 2) {
        let storename = dispatchSplit[0]
        let mutationName = dispatchSplit[1]

        if (!MUTATIONS[storename] || !(mutationName in MUTATIONS[storename])) {
          console.error(
            'Warning: ',
            `Mutation ${mutationName} not found in ${storename} Store`
          )
        } else {
          return Promise.resolve(mutate(storename, mutationName, payload))
        }
      } else {
        if (!MUTATIONS.main || !(_type in MUTATIONS.main[_type])) {
          console.error(
            'Warning: ',
            `Mutation ${_type} not found in main Store`
          )
        }

        return Promise.resolve(mutate('main', _type, payload))
      }
    } else {
      return Promise.resolve(mutate(storename, _type, payload))
    }
  }

  let _getState = (_state, option) => {
    if (option && option.root) {
      return genState(_state, option.full)
    } else {
      return genState(storename + '/' + _state)
    }
  }

  let dispatch = (_setter, payload, option) => {
    if (option && option.root) {
      return Promise.resolve(genAction(_setter)(payload))
    } else {
      return Promise.resolve(genAction(storename + '/' + _setter)(payload))
    }
  }

  let state = STATES[storename]

  return {
    commit,
    _getState,
    dispatch,
    state
  }
}

function genAction (settername) {
  let setternameArr = settername.split('/')
  let res = null
  if (setternameArr.length === 2) {
    let storename = setternameArr[0]
    let settername = setternameArr[1]
    if (ACTIONS[storename]) {
      if (
        !(settername in ACTIONS[storename]) ||
        typeof ACTIONS[storename][settername] !== 'function'
      ) {
        console.error(
          'Warning: ',
          `Setter ${settername} not exist in ${storename} store`
        )
        res = () => null
      } else {
        let { commit, _getState, dispatch, state } = generateCDGS(storename)
        res = v => {
          return ACTIONS[storename][settername](
            {
              commit: commit,
              getState: _getState,
              state: state,
              dispatch: dispatch
            },
            v
          )
        }
      }
    } else {
      console.error('Warning: ', `Store ${storename} not found`)
    }
  } else {
    if (
      !(settername in ACTIONS.main) ||
      typeof ACTIONS.main[settername] !== 'function'
    ) {
      console.error('Warning: ', `Setter ${settername} not exist in main store`)
      res = () => null
    } else {
      let { commit, _getState, dispatch, state } = generateCDGS('main')

      res = v => {
        return ACTIONS.main[settername](
          {
            commit: commit,
            getState: _getState,
            state: state,
            dispatch: dispatch
          },
          v
        )
      }
    }
  }
  return res
}

export const dispatch = (_setter, payload) => {
  if (_setter.split('/').length === 1) {
    _setter = 'main/' + _setter
  }

  return Promise.resolve(genAction(_setter)(payload))
}

export const commit = (_type, payload) => {
  let dispatchSplit = _type.split('/')

  if (dispatchSplit.length === 2) {
    let storename = dispatchSplit[0]
    let mutationName = dispatchSplit[1]

    if (!MUTATIONS[storename] || !(mutationName in MUTATIONS[storename])) {
      console.error(
        'Warning: ',
        `Mutation ${mutationName} not found in ${storename} Store`
      )
    } else {
      return Promise.resolve(mutate(storename, mutationName, payload))
    }
  } else {
    if (!MUTATIONS.main || !(_type in MUTATIONS.main[_type])) {
      console.error('Warning: ', `Mutation ${_type} not found in main Store`)
    }

    return Promise.resolve(mutate('main', _type, payload))
  }
}

function rxLoadStore (_stores_) {
  STORES_TMP = { ..._stores_ }
  DEBUG_MODE = _stores_.debug || false
  STORES = {
    main: _stores_,
    ...(_stores_.modules || {})
  }

  for (const name in STORES) {
    if (STORES[name].getters) {
      GETTERS[name] = {}

      for (const key in STORES[name].getters) {
        GETTERS[name][key] = state => {
          return STORES[name].getters[key](state)
        }
      }
    }
    if (typeof STORES[name].state === 'function') {
      STATES[name] = STORES[name].state()
    } else {
      STATES[name] = STORES[name].state
    }

    ACTIONS[name] = STORES[name].actions
    MUTATIONS[name] = STORES[name].mutations
  }
}

export const store = _stores_ => {
  rxLoadStore(_stores_)
  return props => {
    useEffect(() => {
      INIT = OBJ => {
        rxLoadStore(STORES_TMP)
        useForceUpdate()
      }
    }, [])
    return <>{props.children}</>
  }
}

export const mapStates = stateDemand => {
  stateDemand = stateDemand || 'main'
  let res = {}
  let type = Object.prototype.toString.call(stateDemand)
  let callerName
  try {
    throw new Error()
  } catch (e) {
    var re = /(.+?)@|at (.+?) \(/g,
      st = e.stack,
      m
    // var re = /(\w+)@|at (\w+) \(/g, st = e.stack, m;
    re.exec(st)
    m = re.exec(st)
    callerName = m[1] || m[2]
  }

  if (type === '[object Object]') {
    res = Object.entries(stateDemand).reduce(
      (acc, [key, state]) => ({
        ...acc,
        [key]: useRxState(state, callerName)
      }),
      {}
    )
  } else if (type === '[object Array]') {
    res = stateDemand.reduce((acc, state) => {
      acc.push(useRxState(state, callerName))
      return acc
    }, [])
  } else if (type === '[object String]') {
    if (stateDemand in STATES) {
      res = Object.entries(STATES[stateDemand]).reduce(
        (acc, [key, state]) => ({
          ...acc,
          [key]: useRxState(stateDemand + '/' + key, callerName)
        }),
        []
      )
    } else {
      res = [stateDemand].reduce((acc, state) => {
        acc.push(useRxState(state, callerName))
        return acc
      }, [])[0]
    }
  }

  return res
}

export const mapActions = actionDemand => {
  actionDemand = actionDemand || 'main'
  let res = {}
  let type = Object.prototype.toString.call(actionDemand)

  if (type === '[object Object]') {
    res = Object.entries(actionDemand).reduce(
      (acc, [key, action]) => ({
        ...acc,
        [key]: genAction(action)
      }),
      {}
    )
  } else if (type === '[object Array]') {
    res = actionDemand.reduce((acc, action) => {
      acc.push(genAction(action))
      return acc
    }, [])
  } else if (type === '[object String]') {
    if (actionDemand in ACTIONS) {
      res = Object.entries(ACTIONS[actionDemand]).reduce(
        (acc, [key, action]) => ({
          ...acc,
          [key]: genAction(actionDemand + '/' + key)
        }),
        []
      )
    } else {
      res = genAction(actionDemand)
    }
  }

  return res
}

export const mapMutations = mutationDemand => {
  mutationDemand = mutationDemand || 'main'
  let res = {}
  let type = Object.prototype.toString.call(mutationDemand)

  if (type === '[object Object]') {
    res = Object.entries(mutationDemand).reduce(
      (acc, [key, mutation]) => ({
        ...acc,
        [key]: genMutation(mutation)
      }),
      {}
    )
  } else if (type === '[object Array]') {
    res = mutationDemand.reduce((acc, mutation) => {
      acc.push(genMutation(mutation))
      return acc
    }, [])
  } else if (type === '[object String]') {
    if (mutationDemand in MUTATIONS) {
      res = Object.entries(MUTATIONS[mutationDemand]).reduce(
        (acc, [key, mutation]) => ({
          ...acc,
          [key]: genMutation(mutationDemand + '/' + key)
        }),
        {}
      )
    } else {
      res = [mutationDemand].reduce((acc, mutation) => {
        acc.push(genMutation(mutation))
        return acc
      }, [])[0]
    }
  }

  return res
}

export const mapGetters = getterDemand => {
  getterDemand = getterDemand || 'main'
  let res = {}
  let type = Object.prototype.toString.call(getterDemand)
  let callerName
  try {
    throw new Error()
  } catch (e) {
    var re = /(.+?)@|at (.+?) \(/g,
      st = e.stack,
      m
    // var re = /(\w+)@|at (\w+) \(/g, st = e.stack, m;
    re.exec(st)
    m = re.exec(st)
    callerName = m[1] || m[2]
  }

  if (type === '[object Object]') {
    res = Object.entries(getterDemand).reduce(
      (acc, [key, gettername]) => ({
        ...acc,
        [key]: useRxState(gettername, callerName, true)
      }),
      {}
    )
  } else if (type === '[object Array]') {
    res = getterDemand.reduce((acc, gettername) => {
      acc.push(useRxState(gettername, callerName, true))
      return acc
    }, [])
  } else if (type === '[object String]') {
    if (getterDemand in GETTERS) {
      res = Object.entries(GETTERS[getterDemand]).reduce(
        (acc, [key, gettername]) => ({
          ...acc,
          [key]: useRxState(getterDemand + '/' + key, callerName, true)
        }),
        {}
      )
    } else {
      res = [getterDemand].reduce((acc, gettername) => {
        acc.push(useRxState(gettername, callerName, true))
        return acc
      }, [])[0]
    }
  }

  return res
}

function useRxState (name, callerName, isGetter) {
  const sf = useState(isGetter ? genGetter(name) : genState(name))
  if (isGetter) {
    if (!GOBJ[name]) {
      GOBJ[name] = {
        name,
        data: {}
      }
    }
    GOBJ[name].data[callerName] = sf
  } else {
    if (!OBJ[name]) {
      OBJ[name] = {
        name,
        data: {}
      }
    }
    OBJ[name].data[callerName] = sf
  }
  return sf[0]
}

export function useForceUpdate () {
  const [, setTick] = useState(0)
  const update = useCallback(() => {
    setTick(tick => tick + 1)
  }, [])
  return update
}

const data = {
  store,
  useForceUpdate,
  mapStates,
  mapActions,
  mapMutations,
  mapGetters,
  dispatch,
  commit,
  getState: genState,
  INIT
}

export default data
