/** @format */

import React from 'react'

import useCheckListPagination from '../sm/useCheckListPagination'

export default function CheckListPagination (props) {
  const { pager } = useCheckListPagination(props)

  if (props.lastPage === 1 || props.total === 0) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '0px'
      }}
    >
      <PaginationUtils {...{ pager }}>
        {pager.range.data.map((page, index) => {
          return (
            <div
              key={`page#${index}`}
              style={{
                marginRight: '5px'
              }}
            >
              <Page {...{ pager, page }} />
            </div>
          )
        })}
      </PaginationUtils>
    </div>
  )
}

function PaginationUtils ({ children = null, pager = {} } = {}) {
  return (
    <React.Fragment>
      <PaginationUtils.FirstPage {...{ pager }} />
      <PaginationUtils.PreviousPage {...{ pager }} />
      <React.Fragment>{children}</React.Fragment>
      <PaginationUtils.NextPage {...{ pager }} />
      <PaginationUtils.LastPage {...{ pager }} />
    </React.Fragment>
  )
}
PaginationUtils.FirstPage = FirstPage
PaginationUtils.PreviousPage = PreviousPage
PaginationUtils.NextPage = NextPage
PaginationUtils.LastPage = LastPage

function Page ({ pager, page }) {
  const classNameType = React.useMemo(() => {
    return pager.range.current === page ? 'info' : 'primary'
  }, [pager, page])

  const handleClick = React.useCallback(() => {
    pager.go({ page: page })
  }, [pager, page])

  return (
    <button className={`btn btn-${classNameType}`} onClick={handleClick}>
      {page}
    </button>
  )
}

function FirstPage ({ pager }) {
  if (pager.range.last === 2) {
    return null
  }
  return (
    <button
      type={'button'}
      style={{
        marginRight: '5px'
      }}
      className={'btn btn-primary'}
      disabled={pager.range.data.indexOf(1) > -1}
      onClick={pager.first}
    >
      <span className={'glyphicon glyphicon-step-backward'}></span>
    </button>
  )
}

function LastPage ({ pager }) {
  if (pager.range.last === 2) {
    return null
  }
  return (
    <button
      type={'button'}
      className={'btn btn-primary'}
      disabled={pager.range.data.indexOf(pager.range.last) > -1}
      onClick={pager.last}
    >
      <span className={'glyphicon glyphicon-step-forward'}></span>
    </button>
  )
}

function NextPage ({ pager }) {
  return (
    <button
      type={'button'}
      style={{
        marginRight: '5px'
      }}
      className={'btn btn-primary'}
      disabled={pager.range.current === pager.range.last}
      onClick={pager.next}
    >
      <span className={'glyphicon glyphicon-chevron-right'}></span>
    </button>
  )
}

function PreviousPage ({ pager }) {
  return (
    <button
      type={'button'}
      className={'btn btn-primary'}
      style={{
        marginRight: '5px'
      }}
      disabled={pager.range.current === 1}
      onClick={pager.previous}
    >
      <span className={'glyphicon glyphicon-chevron-left'}></span>
    </button>
  )
}
