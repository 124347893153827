/** @format */

import React from 'react';

export default function CheckListUtils({ currentItems, total = 0 } = {}) {
  const _TEXTCONTENT = React.useMemo(() => {
    return !currentItems.isAllChecked ? (
      <span className='text-lowercase'>
        <span className={'text-capitalize'}>Sélectionner</span> la page
      </span>
    ) : (
      <span className='text-lowercase'>
        <span className={'text-capitalize'}>Annuler</span> la sélection
      </span>
    );
  }, [currentItems]);

  const className = React.useMemo(() => {
    return !currentItems.isAllChecked ? 'btn-info' : 'btn-danger';
  }, [currentItems]);

  const handleClick = React.useCallback(() => {
    if (!currentItems.isAllChecked) {
      currentItems.checkAll();
    } else {
      currentItems.unCheckAll();
    }
  }, [currentItems]);

  if (total === 0) {
    return null;
  }
  return (
    <div>
      <button className={`btn ${className}`} onClick={handleClick}>
        {_TEXTCONTENT}
      </button>
    </div>
  );
}
