/** @format */

import React, { useEffect, useMemo, useState } from 'react'

import Reex, { mapActions, mapGetters } from '../../store'
import Autocomplete from '../../components/admin/Bagheram-autocomplete'
import DateTimePicker from '../../components/admin/Bagheram-datetimepicker'
import BImage from '../../components/admin/Bagheram-image'
import BSwitch from '../../components/admin/Bagheram-switch'
import PlaceCity from '../../components/admin/Bagheram-city'
import PlaceCountry from '../../components/admin/Bagheram-country'
import BagheramWording from '../../components/admin/Bagheram-wording'

export default props => {
  const {
    match: { params },
    history
  } = props
  const { model } = params
  const { getForm, add, getMenuData } = mapActions()
  var { title } = getMenuData(model)
  if (model === 'pubs' && +props.location.query.pub_type === 1) {
    title = 'Feeds'
  }
  const form = mapGetters('form')
  const alias = mapGetters('alias')[model]
  const hideAddAlias = mapGetters('hideAddAlias')

  const dataForm = useMemo(() => {
    return Object.entries(form)
  }, [form])

  console.log(2, dataForm)

  const languages = useMemo(() => Reex.getState('languages'), [])
  const statusLabels = useMemo(() => Reex.getState('statusLabels'), [])

  const [imagePreview, setImagePreview] = useState({})
  const [scats, setScats] = useState([])

  const [newScatsArr, addFile] = useState([])
  const [newScatsTitle, addTitle] = useState([])
  const [file, setFile] = useState(null)
  const [desable, setDesable] = useState(true)
  const [iso, setIso] = useState('')
  const [country, setCountry] = useState(null)
  const [city, setCity] = useState({})

  const wordings = useMemo(() => {
    return languages.map((l, i) => ({
      wording_value: '',
      wording_locale: i
    }))
  }, [languages])

  const refs = {}

  useEffect(() => {
    getForm({ model })
  }, [])

  function create () {
    const formData = new FormData()
    const query = props.location.query || {}
    if (model === 'pubs') {
      refs.pub_type = query.pub_type
    }
    for (const field in refs) {
      if (field == 'new_scats') continue
      let value
      if (refs[field].type == 'file') {
        value = refs[field].files[0]
      } else if (refs[field].type == 'select-multiple') {
        value = refs[field].data
      } else {
        value = refs[field].value
      }

      formData.append(field, value)
    }

    if (model == 'cats') {
      for (let i = 0; i < newScatsArr.length; i++) {
        formData.append('new_scats', newScatsArr[i])
        formData.append('new_titles', newScatsTitle[i].scat_title)
      }
    }

    if (model == 'users') {
      const user_birthday = String(formData.get('user_birthday'))
        .split('/')
        .reverse()
      if (user_birthday.length) {
        formData.set('user_birthday', user_birthday.join('-'))
      }
      if (city.id > 0) {
        formData.set('user_town', city.id)
        formData.set('user_lat', city.lat)
        formData.set('user_lon', city.lng)
      }
      formData.set('user_country', country)
    }
    if (model === 'pubs') {
      if (formData.has('pub_type')) {
        formData.set('pub_type', query.pub_type)
      } else {
        formData.append('pub_type', query.pub_type)
      }
    }

    add({ model, data: formData })
      .then(() => {
        const dialogInstance = new window.BootstrapDialog()
          .setTitle('Operation réussi')
          .setClosable(false)
          .setMessage("L'entrée a été bien sauvegardée")
          .setType(window.BootstrapDialog.TYPE_SUCCESS)
          .open()

        setTimeout(() => {
          dialogInstance.close()
          setTimeout(() => {
            const queriesValue =
              model === 'pubs'
                ? `?filter=${JSON.stringify({
                    pub_type: { op: '=', value: query.pub_type || 0 }
                  })}`
                : ''
            history.push(`/admin/${model}${queriesValue}`)
          }, 500)
        }, 2000)
      })
      .catch(() => {
        const dialogInstance = new window.BootstrapDialog()
          .setTitle('Operation échouée')
          .setClosable(false)
          .setMessage("Une erreur s'est produite")
          .setButtons([
            {
              label: 'ok',
              action: function (dialog) {
                dialog.close()
              }
            }
          ])
          .setType(window.BootstrapDialog.TYPE_DANGER)
          .open()
      })
  }

  function handleSubmit (e) {
    e.preventDefault()

    create()
  }

  function onChangeVideo (e, key) {
    if (e.target && e.target.files) {
      let file = e.target.files[0]
      let blobURL = URL.createObjectURL(file)
      setImagePreview(p => ({ ...p, [key]: blobURL }))
    }
  }

  function onChangeImage (e, key) {
    var reader = new FileReader()

    reader.onload = e => {
      setImagePreview(p => ({ ...p, [key]: e.target.result }))
    }
    if (e && e.target && e.target.files) {
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function deleteScat (scat) {
    const mdl = model.slice(0, -1)
    const index = scats.indexOf(scat)
    if (scats[index]) {
      scats[index].scat_visa = 0
    }

    if (refs.scats) {
      refs.scats.data = JSON.stringify(scats)
    }

    setScats(scats)
  }

  async function addScat () {
    const title = refs.new_scats.value
    if (!title || !file) {
      const dialogInstance = new window.BootstrapDialog()
        .setTitle('Incorrect')
        .setClosable(true)
        .setMessage('Veuillez spécifier un titre et une image')
        .setType(window.BootstrapDialog.TYPE_SUCCESS)
        .open()

      return Promise.resolve('tsisy')
    }

    const misy = newScatsTitle.filter(
      scat => scat.scat_title.toLowerCase() == title.toLowerCase()
    )
    const misy2 = []

    if (misy.length || misy2.length) {
      const msg = misy.length
        ? ' existe déjà dans votre nouvelle liste'
        : misy2.length
        ? "est déjà attribuée à l'émostion mère actuelle"
        : 'dans la base de donnée'

      const dialogInstance = new window.BootstrapDialog()
        .setTitle('Existe déjà')
        .setClosable(true)
        .setMessage(
          `Une passion portant le nom <strong>${title}</strong> ${msg}`
        )
        .setType(window.BootstrapDialog.TYPE_SUCCESS)
        .open()

      return Promise.resolve('efa')
    }

    addTitle([
      ...newScatsTitle,
      {
        scat_visa: 1,
        scat_title: title,
        scat_img: imagePreview.new_scats_img
      }
    ])
    addFile([...newScatsArr, file])

    setImagePreview(p => ({ ...p, new_scats_img: null }))
    setFile(null)
    return Promise.resolve(null)
  }

  return (
    <section className='content'>
      <section className='content-header'>
        <h1 className='row'>
          <span onClick={() => history.goBack()} className='cursor-pointer'>
            <i className='fa fa-chevron-left' aria-hidden='true' />
          </span>
          <span style={{ marginLeft: 10 }}>
            Ajout {String(title).toLowerCase().slice(0, -1)}
          </span>
        </h1>
      </section>

      <section className='content'>
        <div className='box box-info' style={{ paddingTop: '50px' }}>
          <form
            method='POST'
            action={`/admin/v1/${model}`}
            onSubmit={handleSubmit}
          >
            <div className='bagheram-row-center'>
              {dataForm.map(([key, value]) => {
                if (
                  (key.indexOf('fk_') == -1 && key.indexOf('_id') > -1) ||
                  hideAddAlias[key]
                ) {
                  return
                }

                if (key.indexOf('_visa') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        <BSwitch
                          field={key}
                          alias={statusLabels}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('_locale') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BSwitch
                          field={key}
                          alias={languages}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (
                  key.indexOf('_accept') > -1 ||
                  key.indexOf('user_confirmbirth') > -1
                ) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        <BSwitch
                          field={key}
                          value={value[key]}
                          alias={['Accepté', 'En cours']}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }
                if (key.indexOf('_gender') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        <BSwitch
                          field={key}
                          alias={['Homme', 'Femme']}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('_img') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        <BImage src={imagePreview[key]} />
                        <span className='btn btn-primary btn-file bagheram-btn-file'>
                          Choisir{' '}
                          <input
                            type='file'
                            accept='image/png, image/jpeg, image/jpg, image/svg'
                            ref={node => (refs[key] = node)}
                            onChange={e => onChangeImage(e, key)}
                          />
                        </span>
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('_video') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        {imagePreview[key] && (
                          <video
                            width='200'
                            controls='controls'
                            preload='metadata'
                            style={{ borderRadius: 10 }}
                          >
                            <source
                              src={imagePreview[key] + '#t=0.1'}
                              type='video/mp4'
                            />
                          </video>
                        )}
                        <span className='btn btn-primary btn-file bagheram-btn-file'>
                          Choisir une video{' '}
                          <input
                            type='file'
                            accept='video/*'
                            ref={node => (refs[key] = node)}
                            onChange={e => onChangeVideo(e, key)}
                          />
                        </span>
                      </div>
                    </div>
                  )
                }

                if (['datetime', 'date'].indexOf(value.type) > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          borderx: 'thin solid red'
                        }}
                      >
                        <DateTimePicker
                          forwardRef={node => (refs[key] = node)}
                          field={key}
                          model={model}
                          placeholder={alias[key]}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('fk_scat_id') == 0 && model == 'pubs') {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        Thème :
                      </label>
                      <div className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'>
                        <Autocomplete
                          forwardRef={node => (refs[key] = node)}
                          className='form-control'
                          model={model}
                          field={key}
                          placeholder={alias[key]}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('fk_cat_id') == 0 && model == 'pubs') {
                  return <></>
                }

                if (key.indexOf('fk_') == 0) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {key.indexOf('fk_user_id') === 0
                          ? 'Utilisateur'
                          : key.indexOf('fk_scat_id') === 0
                          ? 'Passion Fille'
                          : key.indexOf('fk_cat_id') === 0
                          ? 'Passion Mère'
                          : alias[key] || key}
                      </label>
                      <div className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'>
                        <Autocomplete
                          forwardRef={node => (refs[key] = node)}
                          className='form-control'
                          model={model}
                          field={key}
                          placeholder={alias[key]}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'user_country') {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'>
                        <PlaceCountry
                          model={model}
                          field={key}
                          country
                          defaultValue={{ id: value }}
                          placeholder='Pays'
                          onChange={data => {
                            if (data && data.id > 0) {
                              setDesable(false)
                              setIso(data.iso)
                              setCountry(data.id)
                            } else {
                              setDesable(true)
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'user_town') {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'>
                        <PlaceCity
                          model={model}
                          field={key}
                          disabled={desable}
                          iso={iso}
                          defaultValue={{ name: value }}
                          placeholder='Ville'
                          onChange={data => {
                            if (data && data.value > 0) {
                              setCity(data.data)
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'wording_value') {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'stretch'
                        }}
                      >
                        <textarea
                          type={key}
                          className='ckeditor form-control'
                          id={key}
                          cols='100'
                          rows='4'
                          placeholder={alias[key] || key}
                          maxLength={value.maxLength}
                          name={key}
                          ref={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'user_blockingdesc') {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        <textarea
                          type={key}
                          className='form-control'
                          id={key}
                          cols='100'
                          rows='4'
                          placeholder={alias[key] || key}
                          maxLength={value.maxLength}
                          name={key}
                          ref={node => (refs[key] = node)}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'pub_description') {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        <textarea
                          type={key}
                          className='form-control'
                          id={key}
                          cols='100'
                          rows='4'
                          placeholder={alias[key] || key}
                          maxLength={value.maxLength}
                          name={key}
                          ref={node => (refs[key] = node)}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>
                    </div>
                  )
                }

                return (
                  <>
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        <input
                          type={key}
                          className='form-control'
                          id={key}
                          placeholder={alias[key]}
                          maxLength={value.maxLength}
                          name={key}
                          ref={node => (refs[key] = node)}
                          style={{ borderRadius: 5, height: 40 }}
                        />
                      </div>
                    </div>
                    {['cats', 'scats'].indexOf(model) > -1 &&
                      ['cat_title', 'scat_title'].indexOf(key) > -1 && (
                        <>
                          {wordings.map(wording => (
                            <div
                              key={'wording_' + wording.wording_locale}
                              className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                              style={{ borderx: 'thin solid red' }}
                            >
                              <label
                                className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                  borderx: 'thin solid red'
                                }}
                              >
                                Titre en{' '}
                                {String(
                                  languages[wording.wording_locale]
                                ).toLowerCase()}
                              </label>
                              <div
                                className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }}
                              >
                                <BagheramWording
                                  type={'text'}
                                  className='form-control'
                                  placeholder={
                                    languages[wording.wording_locale]
                                  }
                                  name={
                                    'wording_value_' + wording.wording_locale
                                  }
                                  defaultValue={wording.wording_value}
                                  forwardRef={node =>
                                    (refs[
                                      'wording_value_' + wording.wording_locale
                                    ] = node)
                                  }
                                  style={{ borderRadius: 5, height: 40 }}
                                />
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                  </>
                )
              })}
            </div>

            {model == 'cats' && (
              <div
                key='cats'
                className='form-group d-flex flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                style={{ xborder: 'thin solid red' }}
              >
                <br />
                <br />
                <label
                  className='col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-form-label'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    xborder: 'thin solid red'
                  }}
                >
                  Passion filles
                </label>
                <br />
                <br />

                {newScatsTitle &&
                  newScatsTitle.map((scat, i) => (
                    <div
                      key={i}
                      style={{
                        maxWidth: 200,
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 10,
                        margin: 5,
                        borderRadius: 20,
                        border: `thin solid ${
                          scat.scat_visa != 0 ? 'grey' : 'red'
                        }`
                      }}
                    >
                      <BImage
                        model={model}
                        src={scat.scat_img}
                        alt='...'
                        className='img-fluid img-thumbnail'
                        style={{ borderRadius: 40, height: 40, width: 40 }}
                      />
                      {scat.scat_visa == 0 && (
                        <span style={{ padding: 5 }}>
                          <s>{scat.scat_title}</s>
                        </span>
                      )}
                      {scat.scat_visa != 0 && (
                        <span style={{ padding: 5 }}>{scat.scat_title}</span>
                      )}
                      {scat.scat_visa != 0 && (
                        <button
                          style={{ borderRadius: 20, width: 40 }}
                          type='button'
                          onClick={() => deleteScat(i, false, true)}
                        >
                          X
                        </button>
                      )}
                    </div>
                  ))}

                <hr />
                <div
                  style={{
                    display: 'flex',
                    maxWidth: 300,
                    justifyContent: 'space-between',
                    padding: 10,
                    margin: 5,
                    borderRadius: 20,
                    border: 'thin solid grey'
                  }}
                >
                  <div className='col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                    <div
                      className='btn-file'
                      style={{
                        padding: 0,
                        margin: 0,
                        width: 50,
                        height: 50,
                        cursor: 'pointer'
                      }}
                    >
                      {!imagePreview.new_scats_img && (
                        <button
                          style={{ fontSize: 8, height: 50, borderRadius: 10 }}
                        >
                          Ajouter une image
                        </button>
                      )}

                      {!!imagePreview.new_scats_img && (
                        <img
                          alt='Choisir Image'
                          style={{
                            padding: 0,
                            margin: 0,
                            width: 50,
                            height: 50
                          }}
                          src={imagePreview.new_scats_img}
                        />
                      )}

                      <input
                        type='file'
                        ref={node => (refs.new_scats_img = node)}
                        accept='image/png, image/jpeg, image/jpg, image/bmp, image/gif, image/tif'
                        onChange={e => {
                          setFile(e.target.files[0])
                          onChangeImage(e, 'new_scats_img')
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <input
                      type='text'
                      className='form-control'
                      id='new_scats'
                      placeholder='Nouvelle fille'
                      name='new_scats'
                      ref={node => (refs.new_scats = node)}
                      style={{ borderRadius: 5, height: 40 }}
                    />
                  </div>

                  <button
                    type='button'
                    onClick={async x => {
                      const error = await addScat()
                      if (!error) {
                        window.document.getElementById('new_scats').value = ''
                      }
                    }}
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            )}

            <div className='form-group row'>
              <div
                className='col-sm-12'
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <button
                  type='submit'
                  className='btn btn-primary'
                  style={{ backgroundColor: '#783fbd !important' }}
                >
                  {model !== 'pubs'
                    ? 'Create'
                    : +props.location.query.pub_type === 1
                    ? 'Créer le Feed'
                    : 'Créer la publicité'}
                  {/* { model === 'pubs' ? 'Créer la publicité':'Create'} */}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </section>
  )
}
