import React, { useCallback, useEffect, useMemo, useState } from 'react'

export default function BagheramWording ({
  name,
  forwardRef,
  placeholder,
  defaultValue
}) {
  const field = useMemo(() => name, [name])

  const [defo, setDefo] = useState(defaultValue)

  useEffect(() => {
    setDefo(defaultValue)
    document.getElementById(field).value = defaultValue
  }, [defaultValue, field])

  return (
    <input
      type={'text'}
      className='form-control'
      id={field}
      ref={forwardRef}
      placeholder={placeholder}
      name={field}
      defaultValue={defo}
      style={{ borderRadius: 5, height: 40 }}
    />
  )
}
