/** @format */
import React from 'react'

const moment = window.moment

export default {
  ['MODELS']: {
    cats: {
      scats: true,
      users: true,
      date_range: {
        cat_created_at: true
      }
    },
    scats: {
      cats: true,
      users: true,
      date_range: {
        scat_created_at: true
      }
    },
    messages: {
      cats: true,
      scats: true,
      dest: true,
      sender: true,
      message_status: true,
      date_range: {
        message_date: true
      }
    },
    signals: {
      dest: true,
      sender: true,
      signals: true,
      date_range: {
        signal_date: true
      }
    },
    vids: {
      cats: true,
      scats: true,
      dest: true,
      sender: true,
      video_type: true,
      video_status: true,
      date_range: {
        video_date: true
      }
    },
    users: {
      cats: true,
      scats: true,
      games: true,
      genders: true,
      countries: true,
      date_range: {
        created_at: true,
        user_birthday: true
      }
    },
    badges: {
      cats: true,
      users: true,
      badges: true,
      badgeLevels: true,
      date_range: {
        badge_created_at: true
      }
    },
    wordings: {
      date_range: {
        wording_created_at: true
      }
    }
  },
  ['META']: {
    badges: {
      label: 'Types de badge',
      icon: 'fa fa-certificate',
      item_key: 'badgelist_id',
      item_title: 'badgelist_label',
      title: 'Filtre par type de badge',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            badgelist_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          badgelist_id: 'ID',
          badgelist_label: 'Titre'
        },
        fieldValuesFormat: {
          badgelist_label: v => formatClassNames(v, 'text-capitalize')
        }
      },
      query: {
        id: 'fk_badgelist_id',
        op: 'IN'
      }
    },
    badgeLevels: {
      label: 'Niveaux de badge',
      icon: 'fa fa-star-o',
      item_key: 'badge_level_id',
      item_title: 'badge_level_title',
      title: 'Filtre par niveau de badge',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            badge_level_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          badge_level_id: 'ID',
          badge_level_title: 'Titre'
        },
        fieldValuesFormat: {}
      },
      query: {
        id: 'badge_level',
        op: 'IN'
      },
      hiddenComponents: {
        search: true
      }
    },
    cats: {
      label: ({ _WINDOW: { width: sw } } = {}) =>
        sw > 1300 ? 'Passions mères' : 'P. mères',
      icon: 'fa fa-heart',
      item_key: 'cat_id',
      item_title: 'cat_title',
      title: 'passion mère',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            cat_id: { width: '10%' },
            created_at: { width: sw >= 800 ? '15%' : '25%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          cat_id: 'ID',
          cat_title: 'Titre',
          created_at: 'Date de création'
        },
        fieldValuesFormat: {
          created_at: momentFormatDateTime
        }
      },
      query: {
        id: 'fk_cat_id',
        from: 'feelings',
        op: 'IN'
      }
    },
    scats: {
      label: ({ _WINDOW: { width: sw } } = {}) =>
        sw > 1300 ? 'Passions filles' : 'P. filles',
      icon: 'fa fa-child',
      item_key: 'scat_id',
      item_title: 'scat_title',
      title: 'passion fille',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            scat_id: { width: '10%' },
            created_at: { width: sw >= 800 ? '15%' : '25%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          scat_id: 'ID',
          scat_title: 'Titre',
          created_at: 'Date de création'
        },
        fieldValuesFormat: {
          created_at: momentFormatDateTime
        }
      },
      query: {
        id: 'fk_scat_id',
        from: 'feelings',
        op: 'IN'
      }
    },
    games: {
      label: ({ _WINDOW: { width: sw } } = {}) =>
        sw > 1300 ? 'Titre Bagheramer' : 'Titre',
      icon: 'fa fa-star',
      item_key: 'game_id',
      item_title: 'game_title',
      title: "niveau de l'utilisateur",
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            game_id: { width: '10%' },
            game_value: { width: sw >= 800 ? '15%' : '25%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          game_id: 'ID',
          game_title: 'Titre',
          game_value: 'Valeur'
        },
        fieldValuesFormat: {
          created_at: momentFormatDateTime
        }
      },
      query: {
        id: 'fk_game_id',
        op: 'IN'
      },
      hiddenComponents: {
        search: true
      }
    },
    genders: {
      label: 'Sexe',
      icon: 'fa fa-venus-mars',
      item_key: 'gender_id',
      item_title: 'gender_title',
      title: 'sexe',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            gender_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          gender_id: 'Code',
          gender_title: 'Valeur'
        },
        fieldValuesFormat: {
          gender_title: v => formatClassNames(v, 'text-capitalize')
        }
      },
      query: {
        id: 'user_gender',
        op: 'IN'
      },
      hiddenComponents: {
        search: true
      }
    },
    countries: {
      label: ({ _WINDOW: { width: sw } } = {}) =>
        sw > 1300 ? 'Nationalités' : 'Nation...',
      icon: 'fa fa-globe',
      item_key: 'country_id',
      item_title: 'country_title',
      title: 'nationalité',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            country_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          country_id: 'Code',
          country_title: 'Nom'
        },
        fieldValuesFormat: {
          country_title: v => formatClassNames(v, 'text-capitalize')
        }
      },
      query: {
        id: 'user_nationality',
        op: 'IN'
      },
      perPage: 15
    },
    visa_status: {
      label: 'Etat',
      icon: 'fa fa-edit',
      item_key: 'visa_status_id',
      item_title: 'visa_status_title',
      title: 'statut du visa',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            visa_status_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          visa_status_id: 'Code',
          visa_status_title: 'Valeur'
        },
        fieldValuesFormat: {
          country_title: v => formatClassNames(v, 'text-capitalize')
        }
      },
      hiddenComponents: {
        search: true
      }
    },
    message_status: {
      label: 'Statut message',
      icon: 'fa fa-envelope',
      item_key: 'msg_status_id',
      item_title: 'msg_status_title',
      title: 'statut du message',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            msg_status_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          msg_status_id: 'Code',
          msg_status_title: 'Valeur'
        },
        fieldValuesFormat: {
          msg_status_title: v => formatClassNames(v, 'text-capitalize')
        }
      },
      query: {
        id: 'message_status',
        op: 'IN'
      },
      hiddenComponents: {
        search: true
      }
    },
    video_type: {
      label: 'Type de vidéo',
      icon: 'fa fa-file-movie-o',
      item_key: 'vid_type_id',
      item_title: 'vid_type_title',
      title: 'type de vidéo',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            vid_type_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          vid_type_id: 'Code',
          vid_type_title: 'Valeur'
        },
        fieldValuesFormat: {
          vid_type_title: v => formatClassNames(v, 'text-capitalize')
        }
      },
      query: {
        id: 'vid_type',
        op: 'IN'
      },
      hiddenComponents: {
        search: true
      }
    },
    video_status: {
      label: 'Statut vidéo',
      icon: 'fa fa-play-circle',
      item_key: 'vid_status_id',
      item_title: 'vid_status_title',
      title: 'statut du vidéo',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            vid_status_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          vid_status_id: 'Code',
          vid_status_title: 'Valeur'
        },
        fieldValuesFormat: {
          vid_status_title: v => formatClassNames(v, 'text-capitalize')
        }
      },
      query: {
        id: 'vid_status',
        op: 'IN'
      },
      hiddenComponents: {
        search: true
      }
    },
    signals: {
      label: 'Signalements',
      icon: 'fa fa-warning',
      item_key: 'signaltype_id',
      item_title: 'signaltype_label',
      title: 'type de signalement',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            signaltype_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          signaltype_id: 'ID',
          signaltype_label: 'Titre'
        },
        fieldValuesFormat: {
          created_at: momentFormatDateTime
        }
      },
      query: {
        id: 'fk_signaltype_id',
        op: 'IN'
      }
    },
    users: {
      label: 'Utilisateurs',
      icon: 'fa fa-users',
      item_key: 'user_id',
      item_title: 'user_login',
      title: 'utilisateur',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            user_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          user_id: 'ID',
          user_login: 'Nom',
          user_email: 'Courriel'
        },
        fieldValuesFormat: {
          created_at: momentFormatDateTime
        }
      },
      query: {
        id: 'fk_user_id',
        from: 'feelings',
        op: 'IN'
      }
    },
    sender: {
      label: 'Expéditeur',
      icon: 'fa fa-send',
      item_key: 'user_id',
      item_title: 'user_login',
      title: 'expéditeur',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            user_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          user_id: 'ID',
          user_login: 'Expéditeur',
          user_email: 'Courriel'
        },
        fieldValuesFormat: {
          created_at: momentFormatDateTime
        }
      },
      query: {
        id: 'fk_send_id',
        op: 'IN'
      }
    },
    dest: {
      label: 'Destinataires',
      icon: 'fa fa-address-book',
      item_key: 'user_id',
      item_title: 'user_login',
      title: 'destinataire',
      _fields: {
        fieldDisplays: (v, { _WINDOW: { width: sw } }) => {
          const _values = {
            user_id: { width: '10%' },
            _default: { width: '5%' }
          }
          return _values[v]
        },
        fieldNamesAlias: {
          user_id: 'ID',
          user_login: 'Destinataire',
          user_email: 'Courriel'
        },
        fieldValuesFormat: {
          created_at: momentFormatDateTime
        }
      },
      query: {
        id: 'fk_dest_id',
        op: 'IN'
      }
    },
    date_range: {
      user_birthday: {
        query: { id: 'user_birthday' },
        title: () => 'Date de naissance'
      },
      created_at: {
        query: { id: 'created_at' },
        title: () => 'Date de création (compte)'
      },
      cat_created_at: {
        query: { id: 'created_at' },
        title: () => 'Date de création (passion mère)'
      },
      scat_created_at: {
        query: { id: 'created_at' },
        title: () => 'Date de création (passion fille)'
      },
      badge_created_at: {
        query: { id: 'created_at' },
        title: () => 'Date de réception (badge)'
      },
      wording_created_at: {
        query: { id: 'created_at' },
        title: () => 'Date de création (wording)'
      },
      message_date: {
        query: { id: 'created_at' },
        title: () => "Date d'envoi (message)"
      },
      video_date: {
        query: { id: 'created_at' },
        title: () => "Date d'envoi (video)"
      },
      signal_date: {
        query: { id: 'created_at' },
        title: () => 'Date du signalement'
      }
    }
  }
}

function momentFormatDateTime (v) {
  return moment(v).format('DD-MM-YYYY')
}

function formatClassNames (v, className) {
  return <span {...{ className }}>{v}</span>
}
