/** @format */

import LAuth from '../logics/auth'
import LBase from '../logics'
import Constants from '../constants'
import Reex from './index'

export default Reex.store({
  modules: {},
  state: {
    loading: false,
    conn: 0,
    spinnerColor: null,
    me: {},
    token: null,
    selected: {},
    message: {},
    countFeed: 0,
    menu: [
      {
        title: 'Tableau de bord',
        icon: 'fa fa-dashboard',
        model: '',
        bgc: 'aqua',
        to: '#',
        alternatePaths: ['#', '/admin'],
        level: [1, 2, 3]
      },
      {
        title: 'Utilisateurs',
        title2: 'Nouveaux utilisateurs',
        icon: 'fa fa-users',
        model: 'users',
        query: `?filter=${new URLSearchParams(
          JSON.stringify({
            user_visa: { op: '>=', value: 0 }
          })
        ).toString()}`,
        bgc: 'red',
        level: [1, 2, 3]
      },
      {
        title: 'Passions mères',
        model: 'cats',
        icon: 'fa fa-heart',
        bgc: 'seagreen',
        level: [1]
      },
      {
        title: 'Passions filles',
        title2: 'Nouvelles passions filles',
        model: 'scats',
        icon: 'fa fa-child',
        bgc: 'blue',
        level: [1]
      },
      {
        title: 'Wordings',
        model: 'wordings',
        icon: 'fa fa-hashtag',
        bgc: 'maroon',
        level: [1]
      },
      {
        title: 'Publicités',
        model: 'pubs',
        icon: 'fa fa-bullseye',
        bgc: 'green',
        query: `?filter=${new URLSearchParams(
          JSON.stringify({
            pub_type: { op: '=', value: 0 }
          })
        ).toString()}`,
        level: [1]
      },
      {
        title: 'Feeds',
        model: 'pubs',
        model2: 'pubs2',
        icon: 'fa fa-home',
        bgc: 'green',
        query: `?filter=${new URLSearchParams(
          JSON.stringify({
            pub_type: { op: '=', value: 1 }
          })
        ).toString()}`,
        level: [1]
      },
      {
        title: 'Messages',
        title2: 'Nouveaux messages',
        model: 'messages',
        icon: 'fa fa-envelope',
        bgc: 'purple',
        level: [1]
      },
      {
        title: 'Vidéos',
        title2: 'Vidéos envoyés',
        model: 'vids',
        icon: 'fa fa-video-camera',
        bgc: 'brown',
        level: [1]
      },
      {
        title: 'Blocages/Signalements',
        title2: 'Nouveaux blocages/signalements',
        model: 'signals',
        icon: 'fa fa-warning',
        bgc: 'orange',
        level: [1]
      },
      {
        title: 'Type de signalement',
        model: 'signaltypes',
        icon: 'fa fa-list',
        bgc: 'aqua',
        level: [1]
      },
      {
        title: 'Badges',
        title2: 'Badges débloqués',
        model: 'badges',
        icon: 'fa fa-flag-o',
        bgc: 'green',
        level: [1]
      },
      {
        title: 'Anniversaires',
        title2: 'Anniversaires souhaités',
        model: 'vids',
        model2: 'vids2',
        icon: 'fa fa-gift',
        query: `?filter=${new URLSearchParams(
          JSON.stringify({
            vid_type: { op: '>=', value: 1 }
          })
        ).toString()}`,
        bgc: 'yellow',
        level: [-1]
      },
      {
        title: 'Types de badges',
        model: 'badgelists',
        icon: 'fa fa-list',
        bgc: 'green',
        level: [1]
      },
      {
        title: 'Games',
        model: 'games',
        icon: 'fa fa-circle',
        bgc: 'darkgrey',
        level: [1]
      },
      {
        title: 'Modérateurs',
        model: 'modos',
        icon: 'fa fa-cogs',
        bgc: 'blueviolet',
        level: [1]
      },
      {
        title: 'Suppressions admin',
        icon: 'fa fa-users',
        model: 'users',
        model2: 'users2',
        query: `?filter=${new URLSearchParams(
          JSON.stringify({
            user_visa: { op: '=', value: -1 }
          })
        ).toString()}`,
        bgc: 'tomato',
        level: [1]
      }
    ],
    data: {},
    form: {},
    item: {},
    showItem: {},
    alias: {
      users: {
        user_id: 'ID',
        user_login: 'Login',
        user_email: 'Email',
        user_img: 'Image',
        user_gender: 'Sexe',
        user_birthday: 'Date de naissance',
        age: 'Age',
        feelings: 'Passions',
        user_nationality: 'Nationalité',
        user_game: 'Score',
        fk_game_id: 'Gamification',
        user_town: 'Ville',
        user_password: 'Mot de passe',
        user_accept: 'CGU',
        user_country: 'Pays',
        user_blockingdesc: 'Dernier motif de blocage',
        user_acceptgeo: 'Géolocalisation',
        user_confirmbirth: 'Date de naissance confirmée?',
        created_at: 'Date de création',
        user_visa: 'Etat'
      },

      cats: {
        cat_id: 'ID',
        cat_img: 'Image',
        cat_title: 'Clé',
        wordings: 'Wordings',
        scats: 'Passion filles',
        created_at: 'Date de création',
        cat_visa: 'Etat'
      },

      scats: {
        scat_id: 'ID',
        scat_img: 'Image',
        scat_title: 'Clé',
        wordings: 'Wordings',
        fk_cat_id: 'Passion mère',
        created_at: 'Date de création',
        scat_visa: 'Etat'
      },

      messages: {
        message_id: 'ID',
        message_cont: 'Contenu',
        fk_vid_id: 'Vidéo N°',
        fk_dest_id: 'Destinataire',
        fk_send_id: 'Expéditeur',
        fk_cat_id: 'Passion mère',
        fk_scat_id: 'Passion fille',
        fk_pub_id: 'Carte postal',
        created_at: 'Date de création',
        message_status: "Lu par l'utilisateur",
        message_visa: 'Etat',
        message_type: 'Type'
      },

      vids: {
        vid_id: 'ID',
        vid_img: 'Image',
        vid_file: 'Url',
        fk_send_id: 'Expéditeur',
        fk_dest_id: 'Destinataire',
        fk_cat_id: 'Passion mère',
        fk_scat_id: 'Passion fille',
        created_at: 'Date de création',
        vid_status: "Lu par l'utilisateur",
        vid_visa: 'Etat',
        vid_blocked: 'Bloqué',
        vid_type: 'Type',
        feelings: 'Passions',
        scats: 'Passions filles',
        vid_location: 'Localisation'
      },

      modos: {
        modo_id: 'ID',
        modo_email: 'Email',
        modo_level: 'Niveau',
        created_at: 'Date de création',
        updated_at: 'Date de modification',
        modo_visa: 'Etat',
        modo_password: 'Mot de passe'
      },
      signals: {
        signal_id: 'ID',
        signal_visa: 'Etat',
        fk_pub_id: 'Publicité',
        signal_cont: 'Contenu',
        vid_status: "Lu par l'utilisateur",
        fk_vid_id: 'Vidéo',
        fk_send_id: 'Par',
        fk_dest_id: 'Pour',
        fk_message_id: 'Message',
        fk_signaltype_id: 'Type de signal',
        created_at: 'Date de création'
      },

      wordings: {
        wording_id: 'ID',
        wording_locale: 'Langage',
        wording_title: 'Titre',
        wording_value: 'Valeur',
        wording_visa: 'Etat'
      },

      pubs: {
        pub_id: 'ID',
        pub_title: 'Titre',
        pub_pseudo: 'Pseudo',
        pub_img: 'Image',
        pub_description: 'Description',
        pub_video: 'Video',
        pub_url: 'Lien',
        fk_user_id: 'Utilisateur',
        created_at: 'Date de création',
        pub_type: 'Type',
        pub_visa: 'Etat'
      },

      games: {
        game_id: 'ID',
        game_title: 'Titre',
        game_value: 'Valeur',
        created_at: 'Date de création',
        game_visa: 'Etat'
      },

      badges: {
        badge_id: 'ID',
        badge_level: 'Niveau',
        badge_value: 'Compteur',
        // badge_owner: 'Propriétaire',
        badge_type: 'Type de badge',
        // badge_cat: 'Catégorie',
        fk_cat_id: 'Passion mère',
        fk_scat_id: 'Passion fille',
        fk_badgelist_id: 'Badge',
        fk_user_id: 'Utilisateur',
        created_at: 'Date de création',
        badge_status: "Lu par l'utilisateur",
        badge_visa: 'Etat'
      },

      badgelists: {
        badgelist_id: 'ID',
        badgelist_label: 'Libellé',
        badgelist_bronze: 'Image bronze',
        badgelist_gold: 'Image gold',
        badgelist_silver: 'Image argent',
        fk_cat_id: 'Passion mère',
        fk_scat_id: 'Passion fille',
        fk_user_id: 'Utilisateur',
        fk_badgelist_id: 'Badge',

        badgelist_visa: 'Etat'
      },

      signaltypes: {
        signaltype_id: 'ID',
        signaltype_label: 'Libellé',
        signaltype_description: 'Description',
        signaltype_visa: 'Etat'
      }
    },
    hideAlias: {
      user_score1: true,
      user_score2: true,
      user_score3: true,
      user_score4: true,
      user_score5: true,
      user_score6: true,
      user_score7: true,
      user_country: true,
      user_token: true,
      user_town: true,
      user_lat: true,
      user_lon: true,
      updated_at: true,
      vid_message: true,
      vid_blocked: true,
      message_type: true,
      users: {
        user_accept: true,
        user_acceptgeo: true,
        user_img: true,
        user_confirmbirth: true
      },
      cats: {
        created_at: true
      },
      scats: {
        cat_id: true,
        cat_img: true,
        cat_title: true,
        cat_visa: true,
        scats: true
      },
      vids: {
        vid_location: true
      },
      pubs: {
        // fk_user_id: true
      },
      badges: {
        // fk_user_id: true,
        fk_cat_id: true,
        fk_scat_id: true,
        // fk_badgelist_id: true,
        badge_type: true
      },
      signals: {
        signal_visa: true
      }
    },
    hideAddAlias: {
      user_score1: true,
      user_score2: true,
      user_score3: true,
      user_score4: true,
      user_score5: true,
      user_score6: true,
      user_score7: true,
      user_lat: true,
      user_lon: true,
      user_country: true,
      user_town: true,
      user_token: true,
      user_game: true,
      created_at: true,
      updated_at: true,
      vid_message: true,
      fk_user_id: true,
      vid_blocked: true
    },
    hideOneEdit: {
      user_score1: true,
      user_score2: true,
      user_score3: true,
      user_score4: true,
      user_score5: true,
      user_score6: true,
      user_score7: true,
      user_lat: true,
      user_lon: true,
      user_token: true,
      message_type: true,
      user_country: true,
      user_town: true,
      created_at: true,
      updated_at: true,
      vid_message: true,
      fk_user_id: true,
      vid_blocked: true
    },
    hideFilter: {
      badgelists: true,
      signaltypes: true,
      wordings: true
    },
    dashboard: {
      menu: [],
      hideMenu: {
        badgelists: true,
        cats: true,
        pubs: true,
        games: true,
        modos: true,
        signaltypes: true,
        wordings: true
      },
      date: {}
    },
    charts: {},
    models: [],
    city: [],
    cities: [],
    wsOpened: false,
    languages: ['Français', 'Anglais'],
    statusLabels: ['Inactif', 'Actif']
  },
  getters: {
    loading: state => {
      return state.loading
    },
    conn () {
      return 0
    },
    countFeed (state) {
      return state.countFeed
    },
    spinnerColor (state) {
      return state.spinnerColor
    },
    selected (state) {
      return state.selected
    },
    me (state) {
      return state.me
    },
    token (state) {
      return state.token
    },
    menu (state) {
      const menu = state.menu.filter(
        m => m.level.indexOf(state.me.modo_level) > -1
      )
      return menu
    },
    data (state) {
      return state.data
    },
    form: state => state.form,
    item: state => state.item,
    charts: state => state.charts,
    models: state => state.models,
    showItem: state => state.showItem,
    alias: state => state.alias,
    dashboard: state => {
      return state.dashboard
    },
    cities (state) {
      return state.cities
    },
    city (state) {
      return state.city
    },
    hideAlias (state) {
      return state.hideAlias
    },
    hideAddAlias (state) {
      return state.hideAddAlias
    },
    hideOneEdit (state) {
      return state.hideOneEdit
    },
    hideFilter (state) {
      return state.hideFilter
    },
    message (state) {
      const data = state.message.data || {}
      return {
        ...state.message,
        data: Object.values(data).reduce(
          (acc, messages) => [...acc, ...messages],
          []
        ),
        nextPage: (Math.max.apply(null, Object.keys(data)) || 0) + 1
      }
    },
    wsOpened (state) {
      return state.wsOpened
    },
    languages (state) {
      return state.languages
    }
  },
  actions: {
    getMenuData: ({ state }, model) => {
      for (let i = 0; i < state.menu.length; i++) {
        if (state.menu[i].model == model) {
          return state.menu[i]
        }
      }

      return {}
    },
    showSpinner ({ commit, getState }, color) {
      if (color) commit('set_color', color)
      return commit('set_loading', true)
    },
    hideSpinner ({ commit, getState }) {
      commit('set_color', null)
      return commit('set_loading', false)
    },
    setLoading ({ commit, getState }, v) {
      return commit('set_loading', v)
    },
    setCountFeed ({ commit, getState }, v) {
      return commit('set_count_feed', v)
    },
    setSpinnerColor ({ commit, getState }, v) {
      return commit('set_color', v)
    },
    sendMessage ({ commit, getState }, data) {
      return LBase.sendMessage(data)
    },
    async login ({ commit, getState }, data) {
      return LAuth.connect(data).then(r => {
        commit('SET_ME', r)
        return r
      })
    },
    async logout ({ commit, getState }) {
      return LAuth.logout().then(r => {
        commit('SET_ME', {})
        return commit('set_token', null)
      })
    },
    checkToken ({ commit, getState }) {
      return new Promise((resolve, reject) => {
        LAuth.checkToken()
          .then(({ r, tk }) => {
            if (r.modo_img && r.modo_img.indexOf('//') == -1) {
              r.modo_img = `${Constants.host}/uploads/users/img/${r.modo_img}`
            } else {
              if (r.modo_img && r.modo_img.indexOf('http') == -1) {
                r.modo_img = 'https:' + r.modo_img
              }
            }
            r.modo_login = r.modo_email.split('@')[0]
            commit('SET_ME', r)
            commit('set_token', tk)
            resolve(r)
          })
          .catch(e => {
            commit('SET_ME', {})
            commit('set_token', null)
            reject()
          })
      })
    },
    setTk ({ commit, getState }, tk) {
      return commit('set_token', tk)
    },
    setMe ({ commit, getState }, v) {
      return commit('SET_ME', v)
    },
    setMenu ({ commit, getState }, v) {
      return commit('set_menu', v)
    },
    setData ({ commit, getState }, v) {
      return commit('set_data', v)
    },
    async getAll ({ commit, getState }, { model, query }) {
      return LBase.getAll({ model, query }).then(r => {
        commit('set_data', r)
        return r
      })
    },
    async getMessage ({ commit, getState }, { page = 1, div = 7, dest }) {
      return LBase.getMessage({ page, div, dest })
        .then(({ payload, s }) => {
          if (s == 'ok') {
            commit('set_message', payload)
            return payload
          } else {
            return { s: 'ko' }
          }
        })
        .catch(error => {
          return error
        })
    },
    async getForm ({ commit, getState }, { model, query }) {
      return LBase.getForm({ model, query }).then(r => {
        commit('set_form', r)
        return r
      })
    },
    add ({ commit, getState }, { model, data }) {
      return LBase.addItem({ model, data })
    },
    updateItem ({ commit, getState }, { model, data, id }) {
      return LBase.updateItem({ model, data, id })
    },
    updateItems ({ commit, getState }, { model, data }) {
      return LBase.updateItems({ model, data })
    },
    async getItem ({ commit, getState }, { model, id }) {
      return LBase.getItem({ model, id }).then(r => {
        commit('set_item', r)
        return r
      })
    },
    async getShowItem ({ commit, getState }, { model, id }) {
      return LBase.getShowItem({ model, id }).then(r => {
        commit('set_showItem', r)
        return r
      })
    },
    async deleteItem ({ commit, getState }, { model, id }) {
      return LBase.deleteItem({ model, id }).then(r => {
        commit('set_item', r)
        return r /** */
      })
    },
    async deleteItems ({ commit, getState }, { model, data }) {
      return LBase.deleteItems({ model, data }).then(r => {
        commit('set_item', r)
        return r
      })
    },
    disableItems ({ commit, getState }, { model, data }) {
      return LBase.disableItems({ model, data })
    },
    switchField ({ commit, getState }, { model, data, id }) {
      return LBase.switchField({ model, data, id })
    },
    async getDashboard ({ commit, getState }, date) {
      return LBase.getDashboard(date).then(r => {
        commit('set_dashboard', r)
      })
    },
    async getCharts ({ commit, getState }, date) {
      return LBase.getCharts(date).then(r => {
        commit('set_charts', r)
      })
    },
    updateDashboard ({ commit, getState }, data) {
      commit('set_dashboard', data)
    },
    setItem ({ commit, getState }, data) {
      return commit('set_item', data)
    },
    download ({ commit, getState }, { model, query }) {
      return LBase.download({ model, query })
    },
    async get_city ({ commit }, data) {
      return LBase.getCity(data).then(r => {
        commit('set_city', r)
        return r
      })
    },
    async get_cities ({ commit }, data) {
      return LBase.getCity({ all: 1 }).then(r => {
        commit('set_cities', r)
        return r
      })
    },
    async getCountPublicVideo ({ commit }, data) {
      return await LBase.getCountPublicVideo()
    },
    send_notif ({ commit }, data) {
      return LBase.sendNotif(data)
    },
    setSelected ({ commit }, data) {
      commit('set_selected', data)
    },
    init_message ({ commit }, data) {
      commit('init_message', data)
    },
    setWsOpened ({ commit }, v) {
      commit('set_wsOpened', v)
    }
  },
  mutations: {
    set_loading (state, v) {
      state.loading = v
      console.log(state)
    },
    set_count_feed (state, v) {
      state.countFeed = v
    },
    set_color (state, v) {
      state.spinnerColor = v
    },
    SET_ME (state, payload) {
      state.me = payload
    },
    set_token (state, payload) {
      state.token = payload
    },
    set_menu (state, payload) {
      state.menu = payload
    },
    set_data (state, payload) {
      state.data = payload
    },
    set_form (state, payload) {
      state.form = payload
    },
    set_item (state, payload) {
      state.item = payload
    },
    set_showItem (state, payload) {
      state.showItem = payload
    },
    set_dashboard (state, payload) {
      const data = { ...state.dashboard }
      data.menu = state.menu.map(m => {
        if (payload.menu[m.model2 || m.model]) {
          m.total = payload.menu[m.model2 || m.model].total
          m.categories = payload.menu[m.model2 || m.model].categories
          m.variation = payload.menu[m.model2 || m.model].variation
        }
        return m
      })
      if (payload.dateInterval) {
        data.date = payload.dateInterval
      }
      state.dashboard = { ...data }
    },
    set_charts (state, { data: payload, models }) {
      const data = { ...payload }
      for (let year in data) {
        state.menu.map(m => {
          if (data[year][m.model2 || m.model]) {
            data[year][m.model2 || m.model] = {
              ...data[year][m.model2 || m.model],
              ...m
            }
          }
          return m
        })
      }
      state.charts = { ...data }
      state.models = [...models]
    },
    set_city (state, payload) {
      state.city = payload
    },
    set_cities (state, payload) {
      state.cities = payload
    },
    set_selected (state, payload) {
      state.selected = { ...payload }
    },
    set_message (state, payload) {
      state.message = state.message || { data: {} }
      state.message = {
        ...payload,
        data: { ...state.message.data, [payload.page]: payload.data }
      }
    },
    init_message (state, payload) {
      state.message = { data: {}, page: 1 }
    },
    set_wsOpened (state, v) {
      state.wsOpened = v
    }
  }
})
