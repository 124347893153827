/** @format */

import React from 'react'

import LUtils from '../../../../logics/utils'

export default function useCheckListSearch ({ fetchNext }) {
  const searchInputRef = React.useRef()

  const handleSearch = React.useCallback(
    LUtils.debounce(params => {
      fetchNext({ ...params })
    }, 200),
    [fetchNext]
  )

  const handleChange = React.useCallback(
    e => {
      handleSearch({ word: e.target.value })
    },
    [handleSearch]
  )

  return {
    handleChange,
    searchInputRef
  }
}
