/** @format */

import React from 'react'

import useBFilter from './sm/useBFilter'

import CheckList from './templates/CheckList'
import DateRangePickers from './templates/DateRangePickers'

export default props => {
  const { filterItems, propsData, dateRangeProps, _WINDOW, _minimize } =
    useBFilter(props)

  const mounted = React.useRef(false)

  const _activeFiltersCount = React.useMemo(() => {
    return (propsData || []).filter(v => v !== undefined).length
  }, [propsData])

  React.useEffect(() => {
    if (props.visible && mounted.current) {
      if (_activeFiltersCount === 0) {
        // filterItems.close()
      }
    }
    mounted.current = true
  }, [_activeFiltersCount, filterItems, props.visible])

  if (!props.visible) {
    return <React.Fragment />
  }

  return (
    <div
      style={{
        ...styles.filterContainer,
        display: _minimize ? 'none' : undefined
      }}
    >
      <div
        className={'flex-row'}
        style={{
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
          marginBottom: 5
        }}
      >
        {propsData.map((checkListProps, index) => (
          <div key={`checkList#${index}`} style={{ marginBottom: 10 }}>
            <CheckList
              {...{
                ...checkListProps,
                isAdminSuppression: props.isAdminSuppression,
                _reset: props.visible,
                filterItems,
                _WINDOW
              }}
            />
          </div>
        ))}
        <BFilterClose {...{ filterItems, onMinimize: props.onMinimize }} />
      </div>
      <div
        className={'flex-row'}
        style={{
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
          width: '100%'
        }}
      >
        <DateRangePickers
          {...{
            ...dateRangeProps,
            filterItems,
            _WINDOW
          }}
        />
      </div>
    </div>
  )
  // }
}

function BFilterClose ({
  filterItems: {
    close: handleClose,
    clear: handleClear,
    size: activeFilters = 0
  },
  onMinimize
} = {}) {
  return (
    <div style={{ marginLeft: '10px' }}>
      <button
        type={'button'}
        className={'btn bg-blue'}
        style={{
          marginRight: '5px'
        }}
        onClick={() => onMinimize({ minimize: true, activeFilters })}
      >
        <span className={'fa fa-eye-slash text-sm'}></span>
      </button>
      <button
        type={'button'}
        className={'btn btn-sm btn-danger'}
        style={{
          marginRight: '5px'
        }}
        onClick={() => handleClear({ _cleanQS: true })}
      >
        <span className={'fa fa-eraser text-sm'}></span>
      </button>
      <button
        type={'button'}
        className={'btn btn-sm btn-danger'}
        onClick={handleClose}
      >
        <span className={'fa fa-close text-sm'}></span>
      </button>
    </div>
  )
}

const styles = {
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '250px',
    overflowY: 'scroll',
    padding: 15,
    marginBottom: 0,
    border: '1px solid #ddd',
    borderRadius: 20
  }
}
