/** @format */

import Dashboard from '../pages/Dashboard'

import ListePages from '../pages/Contenu/ListePages'
import { Pages } from '../pages/Contenu/Pages'
import ListeArticles from '../pages/Contenu/ListeArticles'
import { Articles } from '../pages/Contenu/Articles'
import Medias from '../pages/Contenu/Medias'

import All from '../pages/Admin/All'

import Show from '../pages/Admin/Show'

import Edit from '../pages/Admin/Edit'

import Add from '../pages/Admin/Add'

import Plugins from '../pages/Services/Plugins'

import Login from '../pages/Authentification/Login'

import Splash from '../pages/Authentification/Splash'

const router = {
  initialRoute: '/check',

  homeRoute: '/admin',
  loginRoute: '/login',

  routes: [
    {
      path: '/admin',
      component: Dashboard
    },
    {
      path: '/',
      component: Dashboard
    },
    {
      path: '/liste-pages',
      component: ListePages
    },
    {
      path: '/page',
      component: Pages
    },
    {
      path: '/liste-articles',
      component: ListeArticles
    },
    {
      path: '/article',
      component: Articles
    },

    {
      path: '/medias',
      component: Medias
    },

    {
      path: '/liste-services',
      component: Plugins
    },

    {
      path: '/admin/:model',
      component: All
    },
    {
      path: '/admin/:model/show/:id',
      component: Show
    },

    {
      path: '/admin/:model/edit/:id',
      component: Edit
    },

    {
      path: '/admin/:model/add',
      component: Add
    },

    {
      path: '/login',
      component: Login
    },
    {
      path: '/check',
      component: Splash
    }
  ]
}

export default router
