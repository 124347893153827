/** @format */
import React from 'react'

export default function useBadgeLevelData ({ include = false, meta = {} } = {}) {
  const data = React.useMemo(() => {
    return include
      ? {
          data: [
            {
              badge_level_id: '1',
              badge_level_title: 'Bronze'
            },
            {
              badge_level_id: '2',
              badge_level_title: 'Argent'
            },
            {
              badge_level_id: '3',
              badge_level_title: 'Or'
            }
          ],
          page: 1,
          lastPage: 1,
          total: 3
        }
      : null
  }, [include])

  const fetchNext = React.useCallback(() => {}, [])

  const fetchNextById = React.useCallback(
    ({ id = '' } = {}) => {
      if (id) {
        const [{ badge_level_id, badge_level_title } = {}] =
          data.filter(({ badge_level_id } = {}) => badge_level_id == id) || []
        if (badge_level_id) {
          return Promise.resolve({
            badge_level_id,
            badge_level_title
          })
        }
      }
      return Promise.resolve(null)
    },
    [data]
  )

  return include
    ? {
        props: {
          ...data,
          ...meta,
          fetchNext,
          fetchNextById
        }
      }
    : {}
}
