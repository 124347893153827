import React from 'react'
import OGForm from '../../components/OGForm'

export const Articles = () => (
  <section className='content'>
    <div className='row pad'>
      <section className='content-header'>
        <h1>Article</h1>
      </section>
    </div>

    <div className='row article'>
      <form method='post'>
        <div className='col-md-10'>
          <section className='content'>
            <div>
              <div className='box-header'>
                <div className='col-xs-6'>
                  <div className='form-group'>
                    <input
                      id='form_title'
                      type='text'
                      name='form[title]'
                      className='form-control'
                      maxLength={250}
                      placeholder='Titre de votre article'
                      required
                    />
                  </div>
                </div>

                <div className='col-xs-12'>
                  <div className='form-group'>
                    <textarea
                      id='form_body'
                      className='ckeditor'
                      name='form[body]'
                      placeholder='Contenu de votre article'
                    />
                  </div>
                </div>
              </div>

              <div className='box-body'>
                <div className='col-xs-12'>
                  <div className='keywords'>
                    <div id='motsCles'>
                      <input
                        type='text'
                        id='form_fkTag'
                        name='form[fkTag]'
                        className='form-control'
                        placeholder='Tags'
                      />
                    </div>
                    <p className='help-block'>
                      Appuyez sur la touche Entrée après chaque tag
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='box box-info'>
              <div className='box-header with-border'>
                <h3 className='box-title'>Aperçu Google</h3>
              </div>

              <div className='box-body' id='open-graph-dynamic'>
                <OGForm />
              </div>
            </div>
          </section>
        </div>

        <div className='col-md-2'>
          <div className='box box-info'>
            <div className='box-header with-border'>
              <h3 className='box-title'>Publication</h3>
            </div>

            <div className='box-body'>
              <div className='row'>
                <div className='col-xs-7'>
                  <div className='form-group'>
                    <select
                      id='form_status'
                      className='form-control'
                      name='form[status]'
                      defaultValue={1}
                    >
                      <option value={0}>Inactif</option>
                      <option value={1}>Actif</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-12'>
                  <div className='form-group'>
                    <div className='input-group'>
                      <div className='input-group-addon'>
                        <i className='fa fa-calendar' />
                      </div>
                      <input
                        id='form_datePublish'
                        className='form-control'
                        type='date'
                        name='form[datePublish]'
                        required='required'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='box-footer'>
              <div className='row'>
                <div id='article-save' className='col-xs-12'>
                  <button
                    id='form_save'
                    className='btn-save btn btn-info'
                    type='submit'
                    name='form[save]'
                  >
                    Sauvegarder
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='box box-info'>
            <div className='box-header with-border'>
              <h3 className='box-title'>Catégories</h3>
            </div>

            <div className='box-body'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='form-group'>
                    <select
                      id='form_fkCategory'
                      name='form[fkCategory][]'
                      className='form-control'
                      multiple='multiple'
                      defaultValue={['1']}
                    >
                      <option value='1'>Ajout categorie</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='box box-info'>
            <div className='box-header with-border'>
              <h3 className='box-title'>Image</h3>
            </div>

            <div className='box-body'>
              <div className='row'>
                <div className='form-group'>
                  <input type='file' id='form_avatar' name='form[avatar]' />
                </div>

                <div className='col-xs-12'>
                  <div className='form-group'>
                    <input
                      type='text'
                      id='form_avatarAlt'
                      name='form[avatarAlt]'
                      className='form-control'
                      placeholder='Alt'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <input type='hidden' id='form__token' name='form[_token]' value='' />
      </form>
    </div>
  </section>
)
