/** @format */

console.log('ENV', process.env.REACT_APP_VERSION, process.env)

export default {
  host: process.env.REACT_APP_API_BASE_URL,
  hostWs: process.env.REACT_APP_API_BASE_WS,
  videoDir: '/uploads/pubs',
  baseColor: '#FF0133',
  baseWhite: '#FFFFFF'
}
