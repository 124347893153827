/** @format */

import React from 'react';

import useCheckListSearch from '../sm/useCheckListSearch';

export default function CheckListSearch(props) {
  const { handleChange, searchInputRef } = useCheckListSearch(props);

  if (props.hidden) {
    return null;
  }

  return (
    <div className={'input-group'}>
      <input
        type={'search'}
        ref={searchInputRef}
        onChange={handleChange}
        placeholder={'votre texte ici'}
        className={'form-control'}
      />
      <span className={'input-group-addon bg-blue'}>
        <i
          className={'glyphicon glyphicon-search'}
          style={{ width: '20px' }}
        ></i>
      </span>
    </div>
  );
}
