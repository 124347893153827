import React from 'react'
import TextareaCkEditor from '../../components/shared/TextareaCkEditor'

export const Pages = () => (
  <section className='content'>
    <div className='col-md-9'>
      <section className='content-header'>
        <h1 className='row'>Page</h1>
      </section>

      <section className='content'>
        <div className='box box-info'>
          <form method='post'>
            <div className='box-body'>
              <div className='col-xs-4'>
                <div className='form-group'>
                  <input
                    type='text'
                    name='metaTitle'
                    className='form-control'
                    maxLength='250'
                    placeholder='Titre'
                  />
                </div>
              </div>

              <div className='col-xs-9'>
                <div className='form-group'>
                  <input
                    type='text'
                    name='metaBody'
                    className='form-control'
                    maxLength='250'
                    placeholder='Meta Description'
                  />
                </div>
              </div>

              <div className='col-xs-12'>
                <div className='form-group'>
                  <TextareaCkEditor formName='json' />
                </div>
              </div>
            </div>

            <div className='box-footer'>
              <div className='col-xs-12'>
                <button
                  className='btn-save btn btn-info'
                  type='submit'
                  name='save'
                >
                  Sauvegarder
                </button>
              </div>
            </div>

            <input type='hidden' name='token' value='' />
          </form>
        </div>
      </section>
    </div>
  </section>
)
