/** @format */

import React, { useState, useEffect, useRef } from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { utils, Calendar } from '@amir04lm26/react-modern-calendar-date-picker'
import { Popover } from 'react-tiny-popover'

import LUtils from '../../logics/utils'

const today = utils().getToday()
const { isBeforeDate } = utils()

const locale = {
  months: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  weekDays: [
    {
      name: 'Lundi',
      short: 'L'
    },
    {
      name: 'Mardi',
      short: 'M'
    },
    {
      name: 'Mercredi',
      short: 'M'
    },
    {
      name: 'Jeudi',
      short: 'J'
    },
    {
      name: 'Vendredi',
      short: 'V'
    },
    {
      name: 'Samedi',
      short: 'S',
      isWeekend: true
    },
    {
      name: 'Dimanche',
      short: 'D',
      isWeekend: true
    }
  ],

  weekStartingIndex: 6,

  getToday (gregorainTodayObject) {
    return gregorainTodayObject
  },

  toNativeDate (date) {
    return new Date(date.year, date.month - 1, date.day)
  },

  getMonthLength (date) {
    return new Date(date.year, date.month, 0).getDate()
  },

  transformDigit (digit) {
    return digit
  },

  nextMonth: 'Mois prochain',
  previousMonth: 'Mois précédent',
  openMonthSelector: 'Ouvrir le sélecteur de mois',
  openYearSelector: "Ouvrir le sélecteur d' année",
  closeMonthSelector: 'Fermer le sélecteur de mois',
  closeYearSelector: "Fermer le sélecteur d' année",
  defaultPlaceholder: 'Selectionner...',

  from: 'Depuis',
  to: "Jusqu' à",
  digitSeparator: ',',
  yearLetterSkip: 0,
  isRtl: false
}

function generateDefault (value, { value: query = {}, validate }) {
  if (!validate) {
    return {
      from: null,
      to: null
    }
  }

  if (query && query.start) {
    const tabStart = query.start.split('/')
    value.from = {
      year: +tabStart[2],
      month: +tabStart[1],
      day: +tabStart[0]
    }
  } else {
    value.from = null
  }

  if (query && query.end) {
    const tabEnd = query.end.split('/')
    value.to = {
      year: +tabEnd[2],
      month: +tabEnd[1],
      day: +tabEnd[0]
    }
  } else {
    value.to = null
  }

  return value
}

const App = props => {
  const endDate = new Date()
  const beginDate = window.moment(endDate).subtract(30, 'days')

  const defaultFrom = {
    year: beginDate.year(),
    month: beginDate.month() + 1,
    day: beginDate.date()
  }

  const defaultTo = today

  const defaultRange = generateDefault(
    {
      from: null,
      to: null
    },
    props
  )

  const [selectedDayRange, setSelectedDayRange] = useState(defaultRange)

  const [isPopoverOpenBegin, setIsPopoverOpenBegin] = useState(false)
  const [isPopoverOpenEnd, setIsPopoverOpenEnd] = useState(false)

  let [mounted, setMounted] = useState(false)

  const RenderCustomInput = React.forwardRef(function (
    {
      id,
      placeholder,
      value = null,
      onClick = () => null,
      onFocus = () => null,
      onBlur = () => null
    },
    ref
  ) {
    return (
      <span className='has-float-label'>
        <input
          ref={ref}
          className='form-control searchinput'
          id={id}
          type='text'
          autoCorrect='off'
          autoComplete='off'
          defaultValue={
            value
              ? `${LUtils.pad(value.day)}/${LUtils.pad(value.month)}/${
                  value.year
                }`
              : ''
          }
          placeholder={placeholder}
          style={{ borderRadius: 5 }}
          onFocus={onClick}
          onBlur={onClick}
        />
        <label
          htmlFor={id}
          style={{ marginLeft: 15, color: 'grey', marginTop: 3 }}
        >
          {placeholder}
        </label>
      </span>
    )
  })

  function valider () {
    if (props.onChange && typeof props.onChange == 'function') {
      if (selectedDayRange.from && selectedDayRange.to) {
        props.onChange(
          {
            start: selectedDayRange.from
              ? `${LUtils.pad(selectedDayRange.from.day)}/${LUtils.pad(
                  selectedDayRange.from.month
                )}/${selectedDayRange.from.year}`
              : null,
            op: '><',
            end: selectedDayRange.to
              ? `${LUtils.pad(selectedDayRange.to.day)}/${LUtils.pad(
                  selectedDayRange.to.month
                )}/${selectedDayRange.to.year}`
              : null
          },
          props.field,
          props.model
        )
      }
    }
  }

  useEffect(() => {
    if (mounted) {
      if (!props.validate || props.useEvent) {
        valider()
      }
      if (selectedDayRange.from && selectedDayRange.to) {
        if (
          !isBeforeDate(selectedDayRange.from, selectedDayRange.to) &&
          selectedDayRange.from.day != selectedDayRange.to.day
        ) {
          setSelectedDayRange(prev => ({
            ...prev,
            to: selectedDayRange.from
          }))
        }
      }
      setIsPopoverOpenBegin(false)
      setIsPopoverOpenEnd(false)
    } else {
      setMounted(true)
    }
  }, [selectedDayRange, props.useEvent, props.validate])

  useEffect(() => {
    if (mounted) {
      setSelectedDayRange(generateDefault(selectedDayRange, props))
    }
  }, [mounted, props.value])

  return (
    <div
      className='col-xs-12 col-sm-12 col-md-12 col-lg-12'
      style={{ padding: 0 }}
    >
      <div
        className='col-xs-6 col-sm-6 col-md-6 col-lg-6'
        style={{ padding: 0, paddingRight: 5 }}
      >
        <Popover
          isOpen={isPopoverOpenBegin}
          positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
          onClickOutside={() => setIsPopoverOpenBegin(false)}
          content={
            <div>
              <Calendar
                value={selectedDayRange.from}
                onChange={v => {
                  setSelectedDayRange(prev => ({ ...prev, from: v }))
                }}
                shouldHighlightWeekends
                locale={locale}
                calendarClassName='responsive-calendar'
                renderFooter={() => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '1rem 2rem'
                    }}
                  >
                    <button
                      type='button'
                      className='btn btn-xs bg-primary'
                      onClick={() => {
                        setSelectedDayRange(prev => ({
                          ...prev,
                          from: today
                        }))
                        setIsPopoverOpenBegin(false)
                      }}
                    >
                      Aujourd'hui
                    </button>
                  </div>
                )}
              />
            </div>
          }
        >
          <RenderCustomInput
            id={'first_' + props.id}
            placeholder='Date de début'
            value={selectedDayRange.from}
            onClick={() => setIsPopoverOpenBegin(!isPopoverOpenBegin)}
          />
        </Popover>
      </div>
      <div
        className='col-xs-6 col-sm-6 col-md-6 col-lg-6'
        style={{ padding: 0, paddingLeft: 5 }}
      >
        <Popover
          isOpen={isPopoverOpenEnd}
          positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
          onClickOutside={() => setIsPopoverOpenEnd(false)}
          content={
            <div>
              <Calendar
                value={selectedDayRange.to}
                onChange={v => {
                  setSelectedDayRange(prev => ({ ...prev, to: v }))
                }}
                shouldHighlightWeekends
                locale={locale}
                minimumDate={selectedDayRange.from}
                calendarClassName='responsive-calendar'
                renderFooter={() => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '1rem 2rem'
                    }}
                  >
                    <button
                      type='button'
                      className='btn btn-xs bg-primary'
                      onClick={() => {
                        setSelectedDayRange(prev => ({ ...prev, to: today }))
                        setIsPopoverOpenEnd(false)
                      }}
                    >
                      Aujourd'hui
                    </button>
                  </div>
                )}
              />
            </div>
          }
        >
          <RenderCustomInput
            id={'end_' + props.id}
            placeholder='Date de fin'
            value={selectedDayRange.to}
            onClick={() => setIsPopoverOpenEnd(!isPopoverOpenEnd)}
          />
        </Popover>
      </div>
      {props.validate && !props.useEvent && (
        <div className='flex-row' style={{ marginLeft: 10 }}>
          <button
            type='button'
            className='btn btn-xs bg-primary'
            onClick={valider}
          >
            Valider
          </button>
        </div>
      )}
    </div>
  )
}

export default App
