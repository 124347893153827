/** @format */

import React, { useState, useEffect, useRef } from 'react'

import BagheramDaterangepicker from './Bagheram-daterangepicker'

export default props => {
  const [modeCompare, setModeCompare] = useState(false)
  const [first, setFirst] = useState({ data: props.value })
  const [second, setSecond] = useState({})

  function loadFirst (data, field, model) {
    setFirst({ data, field, model })
  }

  function loadSecond (data, field, model) {
    setSecond({ data, field, model })
  }

  function onClose (data, field, model) {
    setModeCompare(false)
    setSecond({})
    if (props.onCloseCompare && typeof props.onCloseCompare === 'function') {
      props.onCloseCompare()
    }
  }

  useEffect(() => {
    setFirst({ data: props.value })
  }, [props.value])

  function validate () {
    if (modeCompare) {
      if (props.onCompare && typeof props.onCompare === 'function') {
        if (!second.data || !second.data.start || !second.data.end) {
          return new window.BootstrapDialog()
            .setTitle('Comparaison de données')
            .setClosable(false)
            .setMessage('Veuillez remplir tout les champs avant de valider')
            .setButtons([
              {
                label: 'ok',
                action: function (dialog) {
                  dialog.close()
                }
              }
            ])
            .setType(window.BootstrapDialog.TYPE_DANGER)
            .open()
        }
        props.onCompare({
          first: first.data,
          second: second.data
        })
      }
    } else {
      if (props.onChange && typeof props.onChange === 'function') {
        props.onChange(first.data, first.field, first.model)
      }
    }
  }

  return (
    <div
      className={modeCompare ? 'row col-12' : 'flex-row col-12'}
      style={{
        ...(modeCompare
          ? {
              borderRadius: 20,
              padding: '20px 15px',
              border: 'thin solid #337ab7',
              minHeight: '100px !important'
            }
          : {
              justifyContent: 'flex-end',
              alignItems: 'center'
            })
      }}
    >
      <div
        className={modeCompare ? 'col-xs-12 col-sm-12 col-md-5 col-lg-5' : ''}
        style={modeCompare ? { padding: 0 } : { display: 'inline-block' }}
      >
        <BagheramDaterangepicker
          value={first.data}
          onChange={loadFirst}
          validate
          useEvent
        />
      </div>

      {modeCompare && (
        <div
          className='col-xs-12 col-sm-12 col-md-2 col-lg-2'
          style={{
            alignItems: 'center',
            padding: 0
          }}
        >
          <div
            className='flex-row'
            style={{
              justifyContent: 'center',
              marginTop: 10,
              marginBottom: 10
            }}
          >
            Comparé à
          </div>
        </div>
      )}
      {modeCompare && (
        <div
          className='col-xs-12 col-sm-12 col-md-5 col-lg-5'
          style={{ padding: 0 }}
        >
          <BagheramDaterangepicker onChange={loadSecond} />
        </div>
      )}
      <div
        className={modeCompare ? 'col-xs-12 col-sm-12 col-md-12 col-lg-12' : ''}
        style={
          modeCompare
            ? {
                padding: 0,
                marginTop: 10
              }
            : { display: 'inline-block' }
        }
      >
        <div className='flex-row' style={{ justifyContent: 'flex-end' }}>
          {modeCompare && (
            <button
              className='btn btn-xs bagheram-btn-grey'
              style={{ marginLeft: 10 }}
              onClick={onClose}
            >
              FERMER
            </button>
          )}
          <button
            type='button'
            className='btn btn-xs bg-primary'
            style={{ marginLeft: 10 }}
            onClick={validate}
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  )
}
