import React from 'react'
import { mapActions, mapGetters } from '../../store'
import { Link } from 'react-router-dom'
import Gravatar from 'react-gravatar'

function HeaderBar (props) {
  const location = props.history.location
  const search = location.search

  const { logout } = mapActions()
  const { me } = mapGetters()

  function pushMenu () {
    var body = document.body
    if (body.clientWidth > 768) {
      if (body.className.indexOf('sidebar-collapse') === -1) {
        body.className += ' sidebar-collapse'
      } else {
        body.className = body.className.replace(' sidebar-collapse', '')
      }
    } else {
      if (body.className.indexOf('sidebar-open') === -1) {
        body.className += ' sidebar-open'
      } else {
        body.className = body.className.replace(' sidebar-open', '')
      }
    }
  }

  return (
    <header
      className='main-header'
      style={{ width: '100%', position: 'fixed' }}
    >
      {/* Logo */}
      <Link to={location.pathname + search} className='logo'>
        {/* mini logo for sidebar mini 50x50 pixels */}
        <span className='logo-mini'>
          <img
            src={require('../../assets/img/logo-simple.png')}
            width='70%'
            height='60%'
            style={{ maxHeight: '30px' }}
          />
        </span>
        {/* logo for regular state and mobile devices */}
        <span className='logo-lg'>
          <img
            src={require('../../assets/img/logo2.png')}
            width='150'
            height='30'
          />
        </span>
      </Link>
      {/* Header Navbar: style can be found in header.less */}
      <nav className='navbar navbar-static-top' role='navigation'>
        {/* Sidebar toggle button */}
        <Link
          to={location.pathname + search}
          className='sidebar-toggle'
          data-toggle='offcanvas'
          role='button'
          onClick={pushMenu}
        >
          <span className='sr-only'>Basculer la navigation</span>
        </Link>
        <div className='navbar-custom-menu'>
          <ul className='nav navbar-nav'>
            {/* User Account: style can be found in dropdown.less */}
            <li className='user user-menu'>
              <Link to={location.pathname + search}>
                {me.modo_img && (
                  <img src={me.modo_img} className='user-image' alt='User' />
                )}
                {me.modo_email && (
                  <Gravatar email={me.modo_email} className='user-image' />
                )}
                <span className='hidden-xs text-capitalize'>
                  {me.modo_login || 'Arthur'}
                </span>
              </Link>
            </li>
            <li className='user user-menu'>
              <a href='#' onClick={logout}>
                <i className='fa fa-power-off' />
                <span className='hidden-xs'>Déconnexion</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default HeaderBar
