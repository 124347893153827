/** @format */

import React from "react"


export default function CheckListContent(props) {
  const handleClick = React.useCallback(
    (item) => {
      if (props.checkedItems.in(item[item.item_key])) {
        props.checkedItems.delete(item)
      } else {
        props.checkedItems.add(item)
      }
    },
    [props.checkedItems]
  );

  const formatDisplay = React.useCallback(
    (...args) => {
      const { _fields: { fieldDisplays = null } = {} } = props || {};
      if (fieldDisplays !== null) {
        return fieldDisplays(...args)
      } else {
        return {};
      }
    },
    [props._fields]
  );

  const _THEAD = React.useMemo(() => {
    const {
      _fields: { fieldNamesAlias = {}, fieldDisplays = null } = {},
      _WINDOW = {},
    } = props || {}
    const fieldNames = Object.keys(fieldNamesAlias || {}) || []
    if (fieldNames.length) {
      return (
        <thead key={`${"thead"}`} style={{ backgroundColor: "#ddd" }}>
          <tr>
            <th
              key={"empty-th"}
              style={{ ...formatDisplay("_default", { _WINDOW }) }}
            ></th>
            {fieldNames.map((v) => (
              <th key={`${v}`} style={{ ...formatDisplay(v, { _WINDOW }) }}>
                {fieldNamesAlias[v]}
              </th>
            ))}
          </tr>
        </thead>
      );
    }
    return null;
  }, [props, formatDisplay]);

  const _TBODY = React.useMemo(() => {
    const {
      data = [],
      item_key = "",
      item_title = "",
      checkedItems = {},
      _fields: { fieldNamesAlias = {}, fieldValuesFormat = {} } = {},
      _WINDOW = {},
    } = props || {};

    if (_THEAD !== null) {
      const fieldNames = Object.keys(fieldNamesAlias);
      return (
        <tbody key={`${"tbody"}`}>
          {data.map((item, index) => {
            return (
              <tr
                key={`row#${index}`}
                style={{ cursor: "pointer" }}
                onClick={() => handleClick({ ...item, item_key, item_title })}
              >
                <td
                  key={`empty-td#${index}`}
                  style={{
                    ...formatDisplay("_default", { _WINDOW }),
                    textAlign: "center",
                  }}
                >
                  {checkedItems.in(item[item_key]) ? (
                    <span className={"fa fa-check text-success"}></span>
                  ) : null}
                </td>
                {fieldNames.map((v, i) => {
                  const valueFormat = fieldValuesFormat[v] || null;
                  return (
                    <td
                      key={`row${index}#col${v}${i}`}
                      style={{ ...formatDisplay(v, { _WINDOW }) }}
                    >
                      {typeof valueFormat === "function"
                        ? valueFormat(item[v])
                        : item[v]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      );
    }
    return null;
  }, [_THEAD, props, formatDisplay, handleClick]);

  const _TABLE = React.useMemo(() => {
    if (_THEAD !== null) {
      return <table>{[_THEAD, _TBODY]}</table>;
    } else {
      return null;
    }
  }, [_THEAD, _TBODY]);

  if (props.total === 0) {
    return null;
  }
  return _TABLE;
}
