import React, { useEffect, useState } from 'react'

import { mapActions, mapGetters } from '../../store'

import { useHistory } from 'react-router-dom'

import router from '../../router'

export default props => {
  const history = useHistory()
  const token = mapGetters('token')
  const query = props.location.query
  const pathname = query.pathname
  const checkToken = mapActions('checkToken')

  const search = history.location.search

  const q = new URLSearchParams(search.slice(1))

  if (q.has('pathname')) {
    q.delete('pathname')
  }

  function checkSession (mounted) {
    let paths
    if (mounted) {
      paths = ['/login']
    } else {
      paths = ['/login', '/check']
    }
    const pathNeedSession = paths.indexOf(pathname) == -1
    const fullPath = `${pathname}?${q.toString()}`

    if (pathNeedSession) {
      checkToken()
        .then(r => {
          if (mounted && pathname == router.initialRoute) {
            history.entries = []
            history.index = -1
            history.push(router.homeRoute)
          } else {
            history.push(fullPath)
          }
        })
        .catch(e => {
          setTimeout(() => {
            history.entries = []
            history.index = -1
            history.push('/login')
          }, 1000)
        })
    }
  }

  useEffect(() => {
    checkSession(true)
  }, [history.location.pathname])

  return (
    <section
      className='content'
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white'
      }}
    >
      <div
        className='card col-xs-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 bagheram-rounded'
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <div className='card-body login-card-body'>
          <center className='mt-3'>
            <img
              src={require('../../assets/img/logo-small.png')}
              width='100'
              height='60'
            />
          </center>
          <h2
            className='login-box-msg mt-2 font-weight-light'
            style={{ color: '#ff0132', marginTop: '15px' }}
          >
            Bagheram Admin
          </h2>
        </div>
      </div>
    </section>
  )
}
