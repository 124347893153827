/** @format */

import axios from 'axios'

const moment = window.moment

export default {
  getAll ({ model, query = '', furtif = false }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/v1/${model}${query}${query ? '&csv=0' : '?&csv=0'}`, {
          furtif
        })
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getMessage ({ page, div, dest }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/v1/messages/mine/${dest}?page=${page}&div=${div}`)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getForm ({ model, query = '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/v1/${model}/create${query}`)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  addItem ({ model, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/v1/${model}`, data)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getItem ({ model, id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/v1/${model}/${id}/edit`)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getShowItem ({ model, id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/v1/${model}/${id}`)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  updateItem ({ model, id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/v1/${model}/${id}`, data)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  sendMessage (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/v1/messages/supporter`, data)
        .then(r => {
          console.error('valinyyyyy', r)
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  updateItems ({ model, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/v1/${model}/undoAdminSuppression`, data)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          } else {
            resolve(r.data)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  deleteItem ({ model, id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/v1/${model}/${id}`)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  deleteItems ({ model, data = {} } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/v1/${model}/multiple`, { data })
        .then(({ data: { s = 'ko', payload = {} } } = {}) => {
          if (s === 'ko') {
            reject('ko')
          }
          resolve(payload.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  disableItems ({ model, data } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/v1/${model}/multiple`, data)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          } else {
            resolve(r.data)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  switchField ({ model, id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/admin/v1/${model}/${id}`, data)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getDashboard (date) {
    return new Promise((resolve, reject) => {
      axios
        .post('/admin/v1/dashboard', date)
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data.payload)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getCharts (date) {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/v1/charts')
        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data.payload)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  download ({ model, query }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/v1/${model}${query ? '?' : ''}${query}`)

        .then(r => {
          if (r.data.s == 'ko') {
            reject(r.data.s)
          }
          resolve(r.data.payload)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getCity ({ iso, all }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/user/city?iso=${iso}&all=${all || 0}`)
        .then(r => {
          if (r.data && r.data.s == 'ok') {
            resolve(r.data.payload)
          } else {
            reject(r.data.error)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  sendNotif (data) {
    return new Promise((resolve, reject) => {
      axios
        .post('admin/v1/users/notifications', data)
        .then(r => {
          if (r.data && r.data.s == 'ok') {
            resolve(r.data.payload)
          } else {
            reject(r.data.error)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getCountPublicVideo () {
    return new Promise((resolve, reject) => {
      axios
        .get('admin/v1/count/video/campaign')
        .then(r => {
          if (r.data && r.data.s == 'ok') {
            resolve(r.data.payload)
          } else {
            reject(r.data.error)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  }
}
