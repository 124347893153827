const countries = [
  {
    country: 'Afghanistan',
    name: 'Afghanistan',
    iso: 'AF',
    sortname: 'AF',
    iso2: 'AF',
    iso3: 'AFG',
    id: 90
  },
  {
    country: 'Albania',
    name: 'Albania',
    iso: 'AL',
    sortname: 'AL',
    iso2: 'AL',
    iso3: 'ALB',
    id: 125
  },
  {
    country: 'Algeria',
    name: 'Algeria',
    iso: 'DZ',
    sortname: 'DZ',
    iso2: 'DZ',
    iso3: 'DZA',
    id: 7
  },
  {
    country: 'American Samoa',
    name: 'American Samoa',
    iso: 'AS',
    sortname: 'AS',
    iso2: 'AS',
    iso3: 'ASM',
    id: 212
  },
  {
    country: 'Andorra',
    name: 'Andorra',
    iso: 'AD',
    sortname: 'AD',
    iso2: 'AD',
    iso3: 'AND',
    id: 92
  },
  {
    country: 'Angola',
    name: 'Angola',
    iso: 'AO',
    sortname: 'AO',
    iso2: 'AO',
    iso3: 'AGO',
    id: 83
  },
  {
    country: 'Antigua And Barbuda',
    name: 'Antigua And Barbuda',
    iso: 'AG',
    sortname: 'AG',
    iso2: 'AG',
    iso3: 'ATG',
    id: 217
  },
  {
    country: 'Argentina',
    name: 'Argentina',
    iso: 'AR',
    sortname: 'AR',
    iso2: 'AR',
    iso3: 'ARG',
    id: 23
  },
  {
    country: 'Armenia',
    name: 'Armenia',
    iso: 'AM',
    sortname: 'AM',
    iso2: 'AM',
    iso3: 'ARM',
    id: 106
  },
  {
    country: 'Aruba',
    name: 'Aruba',
    iso: 'AW',
    sortname: 'AW',
    iso2: 'AW',
    iso3: 'ABW',
    id: 211
  },
  {
    country: 'Australia',
    name: 'Australia',
    iso: 'AU',
    sortname: 'AU',
    iso2: 'AU',
    iso3: 'AUS',
    id: 36
  },
  {
    country: 'Austria',
    name: 'Austria',
    iso: 'AT',
    sortname: 'AT',
    iso2: 'AT',
    iso3: 'AUT',
    id: 157
  },
  {
    country: 'Azerbaijan',
    name: 'Azerbaijan',
    iso: 'AZ',
    sortname: 'AZ',
    iso2: 'AZ',
    iso3: 'AZE',
    id: 111
  },
  {
    country: 'Bahamas, The',
    name: 'Bahamas, The',
    iso: 'BS',
    sortname: 'BS',
    iso2: 'BS',
    iso3: 'BHS',
    id: 186
  },
  {
    country: 'Bahrain',
    name: 'Bahrain',
    iso: 'BH',
    sortname: 'BH',
    iso2: 'BH',
    iso3: 'BHR',
    id: 208
  },
  {
    country: 'Bangladesh',
    name: 'Bangladesh',
    iso: 'BD',
    sortname: 'BD',
    iso2: 'BD',
    iso3: 'BGD',
    id: 136
  },
  {
    country: 'Barbados',
    name: 'Barbados',
    iso: 'BB',
    sortname: 'BB',
    iso2: 'BB',
    iso3: 'BRB',
    id: 158
  },
  {
    country: 'Belarus',
    name: 'Belarus',
    iso: 'BY',
    sortname: 'BY',
    iso2: 'BY',
    iso3: 'BLR',
    id: 121
  },
  {
    country: 'Belgium',
    name: 'Belgium',
    iso: 'BE',
    sortname: 'BE',
    iso2: 'BE',
    iso3: 'BEL',
    id: 99
  },
  {
    country: 'Belize',
    name: 'Belize',
    iso: 'BZ',
    sortname: 'BZ',
    iso2: 'BZ',
    iso3: 'BLZ',
    id: 142
  },
  {
    country: 'Benin',
    name: 'Benin',
    iso: 'BJ',
    sortname: 'BJ',
    iso2: 'BJ',
    iso3: 'BEN',
    id: 21
  },
  {
    country: 'Bermuda',
    name: 'Bermuda',
    iso: 'BM',
    sortname: 'BM',
    iso2: 'BM',
    iso3: 'BMU',
    id: 195
  },
  {
    country: 'Bhutan',
    name: 'Bhutan',
    iso: 'BT',
    sortname: 'BT',
    iso2: 'BT',
    iso3: 'BTN',
    id: 175
  },
  {
    country: 'Bolivia',
    name: 'Bolivia',
    iso: 'BO',
    sortname: 'BO',
    iso2: 'BO',
    iso3: 'BOL',
    id: 29
  },
  {
    country: 'Bosnia And Herzegovina',
    name: 'Bosnia And Herzegovina',
    iso: 'BA',
    sortname: 'BA',
    iso2: 'BA',
    iso3: 'BIH',
    id: 131
  },
  {
    country: 'Botswana',
    name: 'Botswana',
    iso: 'BW',
    sortname: 'BW',
    iso2: 'BW',
    iso3: 'BWA',
    id: 185
  },
  {
    country: 'Brazil',
    name: 'Brazil',
    iso: 'BR',
    sortname: 'BR',
    iso2: 'BR',
    iso3: 'BRA',
    id: 8
  },
  {
    country: 'Brunei',
    name: 'Brunei',
    iso: 'BN',
    sortname: 'BN',
    iso2: 'BN',
    iso3: 'BRN',
    id: 129
  },
  {
    country: 'Bulgaria',
    name: 'Bulgaria',
    iso: 'BG',
    sortname: 'BG',
    iso2: 'BG',
    iso3: 'BGR',
    id: 153
  },
  {
    country: 'Burkina Faso',
    name: 'Burkina Faso',
    iso: 'BF',
    sortname: 'BF',
    iso2: 'BF',
    iso3: 'BFA',
    id: 130
  },
  {
    country: 'Burma',
    name: 'Burma',
    iso: 'MM',
    sortname: 'MM',
    iso2: 'MM',
    iso3: 'MMR',
    id: 75
  },
  {
    country: 'Burundi',
    name: 'Burundi',
    iso: 'BI',
    sortname: 'BI',
    iso2: 'BI',
    iso3: 'BDI',
    id: 161
  },
  {
    country: 'Cabo Verde',
    name: 'Cabo Verde',
    iso: 'CV',
    sortname: 'CV',
    iso2: 'CV',
    iso3: 'CPV',
    id: 110
  },
  {
    country: 'Cambodia',
    name: 'Cambodia',
    iso: 'KH',
    sortname: 'KH',
    iso2: 'KH',
    iso3: 'KHM',
    id: 133
  },
  {
    country: 'Cameroon',
    name: 'Cameroon',
    iso: 'CM',
    sortname: 'CM',
    iso2: 'CM',
    iso3: 'CMR',
    id: 22
  },
  {
    country: 'Canada',
    name: 'Canada',
    iso: 'CA',
    sortname: 'CA',
    iso2: 'CA',
    iso3: 'CAN',
    id: 13
  },
  {
    country: 'Cayman Islands',
    name: 'Cayman Islands',
    iso: 'KY',
    sortname: 'KY',
    iso2: 'KY',
    iso3: 'CYM',
    id: 188
  },
  {
    country: 'Central African Republic',
    name: 'Central African Republic',
    iso: 'CF',
    sortname: 'CF',
    iso2: 'CF',
    iso3: 'CAF',
    id: 128
  },
  {
    country: 'Chad',
    name: 'Chad',
    iso: 'TD',
    sortname: 'TD',
    iso2: 'TD',
    iso3: 'TCD',
    id: 25
  },
  {
    country: 'Chile',
    name: 'Chile',
    iso: 'CL',
    sortname: 'CL',
    iso2: 'CL',
    iso3: 'CHL',
    id: 88
  },
  {
    country: 'China',
    name: 'China',
    iso: 'CN',
    sortname: 'CN',
    iso2: 'CN',
    iso3: 'CHN',
    id: 58
  },
  {
    country: 'Colombia',
    name: 'Colombia',
    iso: 'CO',
    sortname: 'CO',
    iso2: 'CO',
    iso3: 'COL',
    id: 103
  },
  {
    country: 'Comoros',
    name: 'Comoros',
    iso: 'KM',
    sortname: 'KM',
    iso2: 'KM',
    iso3: 'COM',
    id: 183
  },
  {
    country: 'Congo (Brazzaville)',
    name: 'Congo (Brazzaville)',
    iso: 'CG',
    sortname: 'CG',
    iso2: 'CG',
    iso3: 'COG',
    id: 156
  },
  {
    country: 'Congo (Kinshasa)',
    name: 'Congo (Kinshasa)',
    iso: 'CD',
    sortname: 'CD',
    iso2: 'CD',
    iso3: 'COD',
    id: 53
  },
  {
    country: 'Cook Islands',
    name: 'Cook Islands',
    iso: 'CK',
    sortname: 'CK',
    iso2: 'CK',
    iso3: 'COK',
    id: 117
  },
  {
    country: 'Costa Rica',
    name: 'Costa Rica',
    iso: 'CR',
    sortname: 'CR',
    iso2: 'CR',
    iso3: 'CRI',
    id: 69
  },
  {
    country: 'Croatia',
    name: 'Croatia',
    iso: 'HR',
    sortname: 'HR',
    iso2: 'HR',
    iso3: 'HRV',
    id: 151
  },
  {
    country: 'Cuba',
    name: 'Cuba',
    iso: 'CU',
    sortname: 'CU',
    iso2: 'CU',
    iso3: 'CUB',
    id: 107
  },
  {
    country: 'Curaçao',
    name: 'Curaçao',
    iso: 'CW',
    sortname: 'CW',
    iso2: 'CW',
    iso3: 'CUW',
    id: 223
  },
  {
    country: 'Cyprus',
    name: 'Cyprus',
    iso: 'CY',
    sortname: 'CY',
    iso2: 'CY',
    iso3: 'CYP',
    id: 182
  },
  {
    country: 'Czechia',
    name: 'Czechia',
    iso: 'CZ',
    sortname: 'CZ',
    iso2: 'CZ',
    iso3: 'CZE',
    id: 159
  },
  {
    country: 'Côte D’Ivoire',
    name: 'Côte D’Ivoire',
    iso: 'CI',
    sortname: 'CI',
    iso2: 'CI',
    iso3: 'CIV',
    id: 16
  },
  {
    country: 'Denmark',
    name: 'Denmark',
    iso: 'DK',
    sortname: 'DK',
    iso2: 'DK',
    iso3: 'DNK',
    id: 2
  },
  {
    country: 'Djibouti',
    name: 'Djibouti',
    iso: 'DJ',
    sortname: 'DJ',
    iso2: 'DJ',
    iso3: 'DJI',
    id: 74
  },
  {
    country: 'Dominica',
    name: 'Dominica',
    iso: 'DM',
    sortname: 'DM',
    iso2: 'DM',
    iso3: 'DMA',
    id: 215
  },
  {
    country: 'Dominican Republic',
    name: 'Dominican Republic',
    iso: 'DO',
    sortname: 'DO',
    iso2: 'DO',
    iso3: 'DOM',
    id: 119
  },
  {
    country: 'Ecuador',
    name: 'Ecuador',
    iso: 'EC',
    sortname: 'EC',
    iso2: 'EC',
    iso3: 'ECU',
    id: 70
  },
  {
    country: 'Egypt',
    name: 'Egypt',
    iso: 'EG',
    sortname: 'EG',
    iso2: 'EG',
    iso3: 'EGY',
    id: 63
  },
  {
    country: 'El Salvador',
    name: 'El Salvador',
    iso: 'SV',
    sortname: 'SV',
    iso2: 'SV',
    iso3: 'SLV',
    id: 47
  },
  {
    country: 'Equatorial Guinea',
    name: 'Equatorial Guinea',
    iso: 'GQ',
    sortname: 'GQ',
    iso2: 'GQ',
    iso3: 'GNQ',
    id: 139
  },
  {
    country: 'Eritrea',
    name: 'Eritrea',
    iso: 'ER',
    sortname: 'ER',
    iso2: 'ER',
    iso3: 'ERI',
    id: 60
  },
  {
    country: 'Estonia',
    name: 'Estonia',
    iso: 'EE',
    sortname: 'EE',
    iso2: 'EE',
    iso3: 'EST',
    id: 193
  },
  {
    country: 'Ethiopia',
    name: 'Ethiopia',
    iso: 'ET',
    sortname: 'ET',
    iso2: 'ET',
    iso3: 'ETH',
    id: 35
  },
  {
    country: 'Falkland Islands (Islas Malvinas)',
    name: 'Falkland Islands (Islas Malvinas)',
    iso: 'FK',
    sortname: 'FK',
    iso2: 'FK',
    iso3: 'FLK',
    id: 220
  },
  {
    country: 'Faroe Islands',
    name: 'Faroe Islands',
    iso: 'FO',
    sortname: 'FO',
    iso2: 'FO',
    iso3: 'FRO',
    id: 181
  },
  {
    country: 'Fiji',
    name: 'Fiji',
    iso: 'FJ',
    sortname: 'FJ',
    iso2: 'FJ',
    iso3: 'FJI',
    id: 203
  },
  {
    country: 'Finland',
    name: 'Finland',
    iso: 'FI',
    sortname: 'FI',
    iso2: 'FI',
    iso3: 'FIN',
    id: 196
  },
  {
    country: 'France',
    name: 'France',
    iso: 'FR',
    sortname: 'FR',
    iso2: 'FR',
    iso3: 'FRA',
    id: 41
  },
  {
    country: 'French Guiana',
    name: 'French Guiana',
    iso: 'GF',
    sortname: 'GF',
    iso2: 'GF',
    iso3: 'GUF',
    id: 170
  },
  {
    country: 'French Polynesia',
    name: 'French Polynesia',
    iso: 'PF',
    sortname: 'PF',
    iso2: 'PF',
    iso3: 'PYF',
    id: 213
  },
  {
    country: 'Gabon',
    name: 'Gabon',
    iso: 'GA',
    sortname: 'GA',
    iso2: 'GA',
    iso3: 'GAB',
    id: 148
  },
  {
    country: 'Gambia, The',
    name: 'Gambia, The',
    iso: 'GM',
    sortname: 'GM',
    iso2: 'GM',
    iso3: 'GMB',
    id: 132
  },
  {
    country: 'Georgia',
    name: 'Georgia',
    iso: 'GE',
    sortname: 'GE',
    iso2: 'GE',
    iso3: 'GEO',
    id: 54
  },
  {
    country: 'Germany',
    name: 'Germany',
    iso: 'DE',
    sortname: 'DE',
    iso2: 'DE',
    iso3: 'DEU',
    id: 115
  },
  {
    country: 'Ghana',
    name: 'Ghana',
    iso: 'GH',
    sortname: 'GH',
    iso2: 'GH',
    iso3: 'GHA',
    id: 28
  },
  {
    country: 'Gibraltar',
    name: 'Gibraltar',
    iso: 'GI',
    sortname: 'GI',
    iso2: 'GI',
    iso3: 'GIB',
    id: 189
  },
  {
    country: 'Greece',
    name: 'Greece',
    iso: 'GR',
    sortname: 'GR',
    iso2: 'GR',
    iso3: 'GRC',
    id: 43
  },
  {
    country: 'Greenland',
    name: 'Greenland',
    iso: 'GL',
    sortname: 'GL',
    iso2: 'GL',
    iso3: 'GRL',
    id: 4
  },
  {
    country: 'Grenada',
    name: 'Grenada',
    iso: 'GD',
    sortname: 'GD',
    iso2: 'GD',
    iso3: 'GRD',
    id: 216
  },
  {
    country: 'Guadeloupe',
    name: 'Guadeloupe',
    iso: 'GP',
    sortname: 'GP',
    iso2: 'GP',
    iso3: 'GLP',
    id: 137
  },
  {
    country: 'Guam',
    name: 'Guam',
    iso: 'GU',
    sortname: 'GU',
    iso2: 'GU',
    iso3: 'GUM',
    id: 194
  },
  {
    country: 'Guatemala',
    name: 'Guatemala',
    iso: 'GT',
    sortname: 'GT',
    iso2: 'GT',
    iso3: 'GTM',
    id: 98
  },
  {
    country: 'Guinea',
    name: 'Guinea',
    iso: 'GN',
    sortname: 'GN',
    iso2: 'GN',
    iso3: 'GIN',
    id: 145
  },
  {
    country: 'Guinea-Bissau',
    name: 'Guinea-Bissau',
    iso: 'GW',
    sortname: 'GW',
    iso2: 'GW',
    iso3: 'GNB',
    id: 123
  },
  {
    country: 'Guyana',
    name: 'Guyana',
    iso: 'GY',
    sortname: 'GY',
    iso2: 'GY',
    iso3: 'GUY',
    id: 96
  },
  {
    country: 'Haiti',
    name: 'Haiti',
    iso: 'HT',
    sortname: 'HT',
    iso2: 'HT',
    iso3: 'HTI',
    id: 166
  },
  {
    country: 'Honduras',
    name: 'Honduras',
    iso: 'HN',
    sortname: 'HN',
    iso2: 'HN',
    iso3: 'HND',
    id: 160
  },
  {
    country: 'Hong Kong',
    name: 'Hong Kong',
    iso: 'HK',
    sortname: 'HK',
    iso2: 'HK',
    iso3: 'HKG',
    id: 198
  },
  {
    country: 'Hungary',
    name: 'Hungary',
    iso: 'HU',
    sortname: 'HU',
    iso2: 'HU',
    iso3: 'HUN',
    id: 162
  },
  {
    country: 'Iceland',
    name: 'Iceland',
    iso: 'IS',
    sortname: 'IS',
    iso2: 'IS',
    iso3: 'ISL',
    id: 59
  },
  {
    country: 'India',
    name: 'India',
    iso: 'IN',
    sortname: 'IN',
    iso2: 'IN',
    iso3: 'IND',
    id: 20
  },
  {
    country: 'Indonesia',
    name: 'Indonesia',
    iso: 'ID',
    sortname: 'ID',
    iso2: 'ID',
    iso3: 'IDN',
    id: 81
  },
  {
    country: 'Iran',
    name: 'Iran',
    iso: 'IR',
    sortname: 'IR',
    iso2: 'IR',
    iso3: 'IRN',
    id: 46
  },
  {
    country: 'Iraq',
    name: 'Iraq',
    iso: 'IQ',
    sortname: 'IQ',
    iso2: 'IQ',
    iso3: 'IRQ',
    id: 32
  },
  {
    country: 'Ireland',
    name: 'Ireland',
    iso: 'IE',
    sortname: 'IE',
    iso2: 'IE',
    iso3: 'IRL',
    id: 86
  },
  {
    country: 'Isle Of Man',
    name: 'Isle Of Man',
    iso: 'IM',
    sortname: 'IM',
    iso2: 'IM',
    iso3: 'IMN',
    id: 179
  },
  {
    country: 'Israel',
    name: 'Israel',
    iso: 'IL',
    sortname: 'IL',
    iso2: 'IL',
    iso3: 'ISR',
    id: 140
  },
  {
    country: 'Italy',
    name: 'Italy',
    iso: 'IT',
    sortname: 'IT',
    iso2: 'IT',
    iso3: 'ITA',
    id: 87
  },
  {
    country: 'Jamaica',
    name: 'Jamaica',
    iso: 'JM',
    sortname: 'JM',
    iso2: 'JM',
    iso3: 'JAM',
    id: 152
  },
  {
    country: 'Japan',
    name: 'Japan',
    iso: 'JP',
    sortname: 'JP',
    iso2: 'JP',
    iso3: 'JPN',
    id: 55
  },
  {
    country: 'Jordan',
    name: 'Jordan',
    iso: 'JO',
    sortname: 'JO',
    iso2: 'JO',
    iso3: 'JOR',
    id: 64
  },
  {
    country: 'Kazakhstan',
    name: 'Kazakhstan',
    iso: 'KZ',
    sortname: 'KZ',
    iso2: 'KZ',
    iso3: 'KAZ',
    id: 57
  },
  {
    country: 'Kenya',
    name: 'Kenya',
    iso: 'KE',
    sortname: 'KE',
    iso2: 'KE',
    iso3: 'KEN',
    id: 163
  },
  {
    country: 'Kiribati',
    name: 'Kiribati',
    iso: 'KI',
    sortname: 'KI',
    iso2: 'KI',
    iso3: 'KIR',
    id: 221
  },
  {
    country: 'Korea, North',
    name: 'Korea, North',
    iso: 'KP',
    sortname: 'KP',
    iso2: 'KP',
    iso3: 'PRK',
    id: 171
  },
  {
    country: 'Korea, South',
    name: 'Korea, South',
    iso: 'KR',
    sortname: 'KR',
    iso2: 'KR',
    iso3: 'KOR',
    id: 91
  },
  {
    country: 'Kosovo',
    name: 'Kosovo',
    iso: 'XK',
    sortname: 'XK',
    iso2: 'XK',
    iso3: 'XKS',
    id: 177
  },
  {
    country: 'Kuwait',
    name: 'Kuwait',
    iso: 'KW',
    sortname: 'KW',
    iso2: 'KW',
    iso3: 'KWT',
    id: 61
  },
  {
    country: 'Kyrgyzstan',
    name: 'Kyrgyzstan',
    iso: 'KG',
    sortname: 'KG',
    iso2: 'KG',
    iso3: 'KGZ',
    id: 112
  },
  {
    country: 'Laos',
    name: 'Laos',
    iso: 'LA',
    sortname: 'LA',
    iso2: 'LA',
    iso3: 'LAO',
    id: 97
  },
  {
    country: 'Latvia',
    name: 'Latvia',
    iso: 'LV',
    sortname: 'LV',
    iso2: 'LV',
    iso3: 'LVA',
    id: 42
  },
  {
    country: 'Lebanon',
    name: 'Lebanon',
    iso: 'LB',
    sortname: 'LB',
    iso2: 'LB',
    iso3: 'LBN',
    id: 120
  },
  {
    country: 'Lesotho',
    name: 'Lesotho',
    iso: 'LS',
    sortname: 'LS',
    iso2: 'LS',
    iso3: 'LSO',
    id: 165
  },
  {
    country: 'Liberia',
    name: 'Liberia',
    iso: 'LR',
    sortname: 'LR',
    iso2: 'LR',
    iso3: 'LBR',
    id: 135
  },
  {
    country: 'Libya',
    name: 'Libya',
    iso: 'LY',
    sortname: 'LY',
    iso2: 'LY',
    iso3: 'LBY',
    id: 52
  },
  {
    country: 'Liechtenstein',
    name: 'Liechtenstein',
    iso: 'LI',
    sortname: 'LI',
    iso2: 'LI',
    iso3: 'LIE',
    id: 127
  },
  {
    country: 'Lithuania',
    name: 'Lithuania',
    iso: 'LT',
    sortname: 'LT',
    iso2: 'LT',
    iso3: 'LTU',
    id: 56
  },
  {
    country: 'Luxembourg',
    name: 'Luxembourg',
    iso: 'LU',
    sortname: 'LU',
    iso2: 'LU',
    iso3: 'LUX',
    id: 167
  },
  {
    country: 'Macau',
    name: 'Macau',
    iso: 'MO',
    sortname: 'MO',
    iso2: 'MO',
    iso3: 'MAC',
    id: 205
  },
  {
    country: 'Macedonia',
    name: 'Macedonia',
    iso: 'MK',
    sortname: 'MK',
    iso2: 'MK',
    iso3: 'MKD',
    id: 104
  },
  {
    country: 'Madagascar',
    name: 'Madagascar',
    iso: 'MG',
    sortname: 'MG',
    iso2: 'MG',
    iso3: 'MDG',
    id: 82
  },
  {
    country: 'Malawi',
    name: 'Malawi',
    iso: 'MW',
    sortname: 'MW',
    iso2: 'MW',
    iso3: 'MWI',
    id: 126
  },
  {
    country: 'Malaysia',
    name: 'Malaysia',
    iso: 'MY',
    sortname: 'MY',
    iso2: 'MY',
    iso3: 'MYS',
    id: 77
  },
  {
    country: 'Maldives',
    name: 'Maldives',
    iso: 'MV',
    sortname: 'MV',
    iso2: 'MV',
    iso3: 'MDV',
    id: 178
  },
  {
    country: 'Mali',
    name: 'Mali',
    iso: 'ML',
    sortname: 'ML',
    iso2: 'ML',
    iso3: 'MLI',
    id: 45
  },
  {
    country: 'Malta',
    name: 'Malta',
    iso: 'MT',
    sortname: 'MT',
    iso2: 'MT',
    iso3: 'MLT',
    id: 114
  },
  {
    country: 'Marshall Islands',
    name: 'Marshall Islands',
    iso: 'MH',
    sortname: 'MH',
    iso2: 'MH',
    iso3: 'MHL',
    id: 206
  },
  {
    country: 'Martinique',
    name: 'Martinique',
    iso: 'MQ',
    sortname: 'MQ',
    iso2: 'MQ',
    iso3: 'MTQ',
    id: 184
  },
  {
    country: 'Mauritania',
    name: 'Mauritania',
    iso: 'MR',
    sortname: 'MR',
    iso2: 'MR',
    iso3: 'MRT',
    id: 50
  },
  {
    country: 'Mauritius',
    name: 'Mauritius',
    iso: 'MU',
    sortname: 'MU',
    iso2: 'MU',
    iso3: 'MUS',
    id: 174
  },
  {
    country: 'Mayotte',
    name: 'Mayotte',
    iso: 'YT',
    sortname: 'YT',
    iso2: 'YT',
    iso3: 'MYT',
    id: 207
  },
  {
    country: 'Mexico',
    name: 'Mexico',
    iso: 'MX',
    sortname: 'MX',
    iso2: 'MX',
    iso3: 'MEX',
    id: 26
  },
  {
    country: 'Micronesia, Federated States Of',
    name: 'Micronesia, Federated States Of',
    iso: 'FM',
    sortname: 'FM',
    iso2: 'FM',
    iso3: 'FSM',
    id: 173
  },
  {
    country: 'Moldova',
    name: 'Moldova',
    iso: 'MD',
    sortname: 'MD',
    iso2: 'MD',
    iso3: 'MDA',
    id: 94
  },
  {
    country: 'Monaco',
    name: 'Monaco',
    iso: 'MC',
    sortname: 'MC',
    iso2: 'MC',
    iso3: 'MCO',
    id: 209
  },
  {
    country: 'Mongolia',
    name: 'Mongolia',
    iso: 'MN',
    sortname: 'MN',
    iso2: 'MN',
    iso3: 'MNG',
    id: 80
  },
  {
    country: 'Montenegro',
    name: 'Montenegro',
    iso: 'ME',
    sortname: 'ME',
    iso2: 'ME',
    iso3: 'MNE',
    id: 93
  },
  {
    country: 'Morocco',
    name: 'Morocco',
    iso: 'MA',
    sortname: 'MA',
    iso2: 'MA',
    iso3: 'MAR',
    id: 40
  },
  {
    country: 'Mozambique',
    name: 'Mozambique',
    iso: 'MZ',
    sortname: 'MZ',
    iso2: 'MZ',
    iso3: 'MOZ',
    id: 100
  },
  {
    country: 'Namibia',
    name: 'Namibia',
    iso: 'NA',
    sortname: 'NA',
    iso2: 'NA',
    iso3: 'NAM',
    id: 144
  },
  {
    country: 'Nepal',
    name: 'Nepal',
    iso: 'NP',
    sortname: 'NP',
    iso2: 'NP',
    iso3: 'NPL',
    id: 146
  },
  {
    country: 'Netherlands',
    name: 'Netherlands',
    iso: 'NL',
    sortname: 'NL',
    iso2: 'NL',
    iso3: 'NLD',
    id: 85
  },
  {
    country: 'New Caledonia',
    name: 'New Caledonia',
    iso: 'NC',
    sortname: 'NC',
    iso2: 'NC',
    iso3: 'NCL',
    id: 201
  },
  {
    country: 'New Zealand',
    name: 'New Zealand',
    iso: 'NZ',
    sortname: 'NZ',
    iso2: 'NZ',
    iso3: 'NZL',
    id: 109
  },
  {
    country: 'Nicaragua',
    name: 'Nicaragua',
    iso: 'NI',
    sortname: 'NI',
    iso2: 'NI',
    iso3: 'NIC',
    id: 149
  },
  {
    country: 'Niger',
    name: 'Niger',
    iso: 'NE',
    sortname: 'NE',
    iso2: 'NE',
    iso3: 'NER',
    id: 39
  },
  {
    country: 'Nigeria',
    name: 'Nigeria',
    iso: 'NG',
    sortname: 'NG',
    iso2: 'NG',
    iso3: 'NGA',
    id: 5
  },
  {
    country: 'Northern Mariana Islands',
    name: 'Northern Mariana Islands',
    iso: 'MP',
    sortname: 'MP',
    iso2: 'MP',
    iso3: 'MNP',
    id: 168
  },
  {
    country: 'Norway',
    name: 'Norway',
    iso: 'NO',
    sortname: 'NO',
    iso2: 'NO',
    iso3: 'NOR',
    id: 79
  },
  {
    country: 'Oman',
    name: 'Oman',
    iso: 'OM',
    sortname: 'OM',
    iso2: 'OM',
    iso3: 'OMN',
    id: 62
  },
  {
    country: 'Pakistan',
    name: 'Pakistan',
    iso: 'PK',
    sortname: 'PK',
    iso2: 'PK',
    iso3: 'PAK',
    id: 14
  },
  {
    country: 'Palau',
    name: 'Palau',
    iso: 'PW',
    sortname: 'PW',
    iso2: 'PW',
    iso3: 'PLW',
    id: 202
  },
  {
    country: 'Panama',
    name: 'Panama',
    iso: 'PA',
    sortname: 'PA',
    iso2: 'PA',
    iso3: 'PAN',
    id: 76
  },
  {
    country: 'Papua New Guinea',
    name: 'Papua New Guinea',
    iso: 'PG',
    sortname: 'PG',
    iso2: 'PG',
    iso3: 'PNG',
    id: 78
  },
  {
    country: 'Paraguay',
    name: 'Paraguay',
    iso: 'PY',
    sortname: 'PY',
    iso2: 'PY',
    iso3: 'PRY',
    id: 11
  },
  {
    country: 'Peru',
    name: 'Peru',
    iso: 'PE',
    sortname: 'PE',
    iso2: 'PE',
    iso3: 'PER',
    id: 10
  },
  {
    country: 'Philippines',
    name: 'Philippines',
    iso: 'PH',
    sortname: 'PH',
    iso2: 'PH',
    iso3: 'PHL',
    id: 68
  },
  {
    country: 'Poland',
    name: 'Poland',
    iso: 'PL',
    sortname: 'PL',
    iso2: 'PL',
    iso3: 'POL',
    id: 147
  },
  {
    country: 'Portugal',
    name: 'Portugal',
    iso: 'PT',
    sortname: 'PT',
    iso2: 'PT',
    iso3: 'PRT',
    id: 95
  },
  {
    country: 'Puerto Rico',
    name: 'Puerto Rico',
    iso: 'PR',
    sortname: 'PR',
    iso2: 'PR',
    iso3: 'PRI',
    id: 44
  },
  {
    country: 'Qatar',
    name: 'Qatar',
    iso: 'QA',
    sortname: 'QA',
    iso2: 'QA',
    iso3: 'QAT',
    id: 65
  },
  {
    country: 'Reunion',
    name: 'Reunion',
    iso: 'RE',
    sortname: 'RE',
    iso2: 'RE',
    iso3: 'REU',
    id: 218
  },
  {
    country: 'Romania',
    name: 'Romania',
    iso: 'RO',
    sortname: 'RO',
    iso2: 'RO',
    iso3: 'ROU',
    id: 71
  },
  {
    country: 'Russia',
    name: 'Russia',
    iso: 'RU',
    sortname: 'RU',
    iso2: 'RU',
    iso3: 'RUS',
    id: 9
  },
  {
    country: 'Rwanda',
    name: 'Rwanda',
    iso: 'RW',
    sortname: 'RW',
    iso2: 'RW',
    iso3: 'RWA',
    id: 164
  },
  {
    country: 'Saint Helena, Ascension, And Tristan Da Cunha',
    name: 'Saint Helena, Ascension, And Tristan Da Cunha',
    iso: 'SH',
    sortname: 'SH',
    iso2: 'SH',
    iso3: 'SHN',
    id: 180
  },
  {
    country: 'Saint Kitts And Nevis',
    name: 'Saint Kitts And Nevis',
    iso: 'KN',
    sortname: 'KN',
    iso2: 'KN',
    iso3: 'KNA',
    id: 138
  },
  {
    country: 'Saint Lucia',
    name: 'Saint Lucia',
    iso: 'LC',
    sortname: 'LC',
    iso2: 'LC',
    iso3: 'LCA',
    id: 169
  },
  {
    country: 'Saint Vincent And The Grenadines',
    name: 'Saint Vincent And The Grenadines',
    iso: 'VC',
    sortname: 'VC',
    iso2: 'VC',
    iso3: 'VCT',
    id: 200
  },
  {
    country: 'Samoa',
    name: 'Samoa',
    iso: 'WS',
    sortname: 'WS',
    iso2: 'WS',
    iso3: 'WSM',
    id: 38
  },
  {
    country: 'San Marino',
    name: 'San Marino',
    iso: 'SM',
    sortname: 'SM',
    iso2: 'SM',
    iso3: 'SMR',
    id: 30
  },
  {
    country: 'Sao Tome And Principe',
    name: 'Sao Tome And Principe',
    iso: 'ST',
    sortname: 'ST',
    iso2: 'ST',
    iso3: 'STP',
    id: 192
  },
  {
    country: 'Saudi Arabia',
    name: 'Saudi Arabia',
    iso: 'SA',
    sortname: 'SA',
    iso2: 'SA',
    iso3: 'SAU',
    id: 18
  },
  {
    country: 'Senegal',
    name: 'Senegal',
    iso: 'SN',
    sortname: 'SN',
    iso2: 'SN',
    iso3: 'SEN',
    id: 176
  },
  {
    country: 'Serbia',
    name: 'Serbia',
    iso: 'RS',
    sortname: 'RS',
    iso2: 'RS',
    iso3: 'SRB',
    id: 33
  },
  {
    country: 'Seychelles',
    name: 'Seychelles',
    iso: 'SC',
    sortname: 'SC',
    iso2: 'SC',
    iso3: 'SYC',
    id: 222
  },
  {
    country: 'Sierra Leone',
    name: 'Sierra Leone',
    iso: 'SL',
    sortname: 'SL',
    iso2: 'SL',
    iso3: 'SLE',
    id: 154
  },
  {
    country: 'Singapore',
    name: 'Singapore',
    iso: 'SG',
    sortname: 'SG',
    iso2: 'SG',
    iso3: 'SGP',
    id: 219
  },
  {
    country: 'Sint Maarten',
    name: 'Sint Maarten',
    iso: 'SX',
    sortname: 'SX',
    iso2: 'SX',
    iso3: 'SXM',
    id: 214
  },
  {
    country: 'Slovakia',
    name: 'Slovakia',
    iso: 'SK',
    sortname: 'SK',
    iso2: 'SK',
    iso3: 'SVK',
    id: 134
  },
  {
    country: 'Slovenia',
    name: 'Slovenia',
    iso: 'SI',
    sortname: 'SI',
    iso2: 'SI',
    iso3: 'SVN',
    id: 51
  },
  {
    country: 'Solomon Islands',
    name: 'Solomon Islands',
    iso: 'SB',
    sortname: 'SB',
    iso2: 'SB',
    iso3: 'SLB',
    id: 116
  },
  {
    country: 'Somalia',
    name: 'Somalia',
    iso: 'SO',
    sortname: 'SO',
    iso2: 'SO',
    iso3: 'SOM',
    id: 124
  },
  {
    country: 'South Africa',
    name: 'South Africa',
    iso: 'ZA',
    sortname: 'ZA',
    iso2: 'ZA',
    iso3: 'ZAF',
    id: 73
  },
  {
    country: 'South Georgia And South Sandwich Islands',
    name: 'South Georgia And South Sandwich Islands',
    iso: 'GS',
    sortname: 'GS',
    iso2: 'GS',
    iso3: 'SGS',
    id: 191
  },
  {
    country: 'South Sudan',
    name: 'South Sudan',
    iso: 'SS',
    sortname: 'SS',
    iso2: 'SS',
    iso3: 'SSD',
    id: 118
  },
  {
    country: 'Spain',
    name: 'Spain',
    iso: 'ES',
    sortname: 'ES',
    iso2: 'ES',
    iso3: 'ESP',
    id: 1
  },
  {
    country: 'Sri Lanka',
    name: 'Sri Lanka',
    iso: 'LK',
    sortname: 'LK',
    iso2: 'LK',
    iso3: 'LKA',
    id: 101
  },
  {
    country: 'Sudan',
    name: 'Sudan',
    iso: 'SD',
    sortname: 'SD',
    iso2: 'SD',
    iso3: 'SDN',
    id: 31
  },
  {
    country: 'Suriname',
    name: 'Suriname',
    iso: 'SR',
    sortname: 'SR',
    iso2: 'SR',
    iso3: 'SUR',
    id: 72
  },
  {
    country: 'Swaziland',
    name: 'Swaziland',
    iso: 'SZ',
    sortname: 'SZ',
    iso2: 'SZ',
    iso3: 'SWZ',
    id: 197
  },
  {
    country: 'Sweden',
    name: 'Sweden',
    iso: 'SE',
    sortname: 'SE',
    iso2: 'SE',
    iso3: 'SWE',
    id: 155
  },
  {
    country: 'Switzerland',
    name: 'Switzerland',
    iso: 'CH',
    sortname: 'CH',
    iso2: 'CH',
    iso3: 'CHE',
    id: 3
  },
  {
    country: 'Syria',
    name: 'Syria',
    iso: 'SY',
    sortname: 'SY',
    iso2: 'SY',
    iso3: 'SYR',
    id: 67
  },
  {
    country: 'Taiwan',
    name: 'Taiwan',
    iso: 'TW',
    sortname: 'TW',
    iso2: 'TW',
    iso3: 'TWN',
    id: 122
  },
  {
    country: 'Tajikistan',
    name: 'Tajikistan',
    iso: 'TJ',
    sortname: 'TJ',
    iso2: 'TJ',
    iso3: 'TJK',
    id: 15
  },
  {
    country: 'Tanzania',
    name: 'Tanzania',
    iso: 'TZ',
    sortname: 'TZ',
    iso2: 'TZ',
    iso3: 'TZA',
    id: 108
  },
  {
    country: 'Thailand',
    name: 'Thailand',
    iso: 'TH',
    sortname: 'TH',
    iso2: 'TH',
    iso3: 'THA',
    id: 84
  },
  {
    country: 'Timor-Leste',
    name: 'Timor-Leste',
    iso: 'TL',
    sortname: 'TL',
    iso2: 'TL',
    iso3: 'TLS',
    id: 49
  },
  {
    country: 'Togo',
    name: 'Togo',
    iso: 'TG',
    sortname: 'TG',
    iso2: 'TG',
    iso3: 'TGO',
    id: 113
  },
  {
    country: 'Tonga',
    name: 'Tonga',
    iso: 'TO',
    sortname: 'TO',
    iso2: 'TO',
    iso3: 'TON',
    id: 210
  },
  {
    country: 'Trinidad And Tobago',
    name: 'Trinidad And Tobago',
    iso: 'TT',
    sortname: 'TT',
    iso2: 'TT',
    iso3: 'TTO',
    id: 102
  },
  {
    country: 'Tunisia',
    name: 'Tunisia',
    iso: 'TN',
    sortname: 'TN',
    iso2: 'TN',
    iso3: 'TUN',
    id: 105
  },
  {
    country: 'Turkey',
    name: 'Turkey',
    iso: 'TR',
    sortname: 'TR',
    iso2: 'TR',
    iso3: 'TUR',
    id: 34
  },
  {
    country: 'Turkmenistan',
    name: 'Turkmenistan',
    iso: 'TM',
    sortname: 'TM',
    iso2: 'TM',
    iso3: 'TKM',
    id: 6
  },
  {
    country: 'Turks And Caicos Islands',
    name: 'Turks And Caicos Islands',
    iso: 'TC',
    sortname: 'TC',
    iso2: 'TC',
    iso3: 'TCA',
    id: 190
  },
  {
    country: 'Tuvalu',
    name: 'Tuvalu',
    iso: 'TV',
    sortname: 'TV',
    iso2: 'TV',
    iso3: 'TUV',
    id: 187
  },
  {
    country: 'Uganda',
    name: 'Uganda',
    iso: 'UG',
    sortname: 'UG',
    iso2: 'UG',
    iso3: 'UGA',
    id: 19
  },
  {
    country: 'Ukraine',
    name: 'Ukraine',
    iso: 'UA',
    sortname: 'UA',
    iso2: 'UA',
    iso3: 'UKR',
    id: 143
  },
  {
    country: 'United Arab Emirates',
    name: 'United Arab Emirates',
    iso: 'AE',
    sortname: 'AE',
    iso2: 'AE',
    iso3: 'ARE',
    id: 24
  },
  {
    country: 'United Kingdom',
    name: 'United Kingdom',
    iso: 'GB',
    sortname: 'GB',
    iso2: 'GB',
    iso3: 'GBR',
    id: 17
  },
  {
    country: 'United States',
    name: 'United States',
    iso: 'US',
    sortname: 'US',
    iso2: 'US',
    iso3: 'USA',
    id: 12
  },
  {
    country: 'Uruguay',
    name: 'Uruguay',
    iso: 'UY',
    sortname: 'UY',
    iso2: 'UY',
    iso3: 'URY',
    id: 48
  },
  {
    country: 'Uzbekistan',
    name: 'Uzbekistan',
    iso: 'UZ',
    sortname: 'UZ',
    iso2: 'UZ',
    iso3: 'UZB',
    id: 89
  },
  {
    country: 'Vanuatu',
    name: 'Vanuatu',
    iso: 'VU',
    sortname: 'VU',
    iso2: 'VU',
    iso3: 'VUT',
    id: 199
  },
  {
    country: 'Venezuela',
    name: 'Venezuela',
    iso: 'VE',
    sortname: 'VE',
    iso2: 'VE',
    iso3: 'VEN',
    id: 27
  },
  {
    country: 'Vietnam',
    name: 'Vietnam',
    iso: 'VN',
    sortname: 'VN',
    iso2: 'VN',
    iso3: 'VNM',
    id: 150
  },
  {
    country: 'Wallis And Futuna',
    name: 'Wallis And Futuna',
    iso: 'WF',
    sortname: 'WF',
    iso2: 'WF',
    iso3: 'WLF',
    id: 204
  },
  {
    country: 'West Bank',
    name: 'West Bank',
    iso: 'XW',
    sortname: 'XW',
    iso2: 'XW',
    iso3: 'XWB',
    id: 66
  },
  {
    country: 'Yemen',
    name: 'Yemen',
    iso: 'YE',
    sortname: 'YE',
    iso2: 'YE',
    iso3: 'YEM',
    id: 37
  },
  {
    country: 'Zambia',
    name: 'Zambia',
    iso: 'ZM',
    sortname: 'ZM',
    iso2: 'ZM',
    iso3: 'ZMB',
    id: 172
  },
  {
    country: 'Zimbabwe',
    name: 'Zimbabwe',
    iso: 'ZW',
    sortname: 'ZW',
    iso2: 'ZW',
    iso3: 'ZWE',
    id: 141
  }
]

export default countries
