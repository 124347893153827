/** @format */

import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import Gravatar from 'react-gravatar'

import { mapGetters, mapActions } from '../store'

function paramsToObject (entries) {
  const result = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

export const NavigationMenu = withRouter(({ location, count }) => {
  const { me, menu } = mapGetters()

  function RenderTreeview (props) {
    return (
      <>
        {menu.map((m, i) => {
          if (m.children && m.children.length > 0) {
            const path =
              m.children.filter(
                child => location.pathname == `/admin/${child.model}`
              )[0] || {}
            const paths = m.children.map(child => `/admin/${child.model}`)
            return (
              <li key={'menu_' + i} className='treeview'>
                <NavLink
                  to={`/admin/${path.model}`}
                  className='nava'
                  id='nav-seo'
                  isActive={(_, { pathname }) =>
                    paths.some(p => pathname.includes(p))
                  }
                  onClick={e => e.preventDefault()}
                >
                  <i className={m.icon || 'fa fa-search'} />{' '}
                  <span>{m.title}</span>
                  <span className='pull-right-container'>
                    <i className='fa fa-angle-left pull-right' />
                  </span>
                </NavLink>
                <ul className='treeview-menu'>
                  {m.children.map((child, j) => (
                    <li key={'child_' + i + '_' + j}>
                      <NavLink
                        to={child.model ? `/admin/${child.model}` : child.to}
                      >
                        <i className='fa fa-circle-o' />
                        {child.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            )
          }
          if (m.level && m.level === -1) {
            return null
          }

          let isActive =
            location.pathname === `/admin/${m.model}` ||
            (m.to === '#' && location.pathname === '/')

          const q = new URLSearchParams(String(m.query).slice(1))
          if (location.query && m.query && location.query.filter) {
            if (location.pathname === `/admin/${m.model}`) {
              isActive = q.get('filter') === location.query.filter
            }
          }
          let qr = {}
          try {
            qr = JSON.parse(q.get('filter') || '{}')
          } catch (e) {
            qr = {}
          }

          const showBadgeNbr = () =>
            m.model === 'pubs' &&
            qr.pub_type &&
            +qr.pub_type.value === 1 &&
            count > 0

          return (
            <li key={'menu_' + i}>
              <a
                aria-current={isActive ? 'page' : ''}
                className={isActive ? 'active' : ''}
                href={m.model ? `#/admin/${m.model}${m.query || ''}` : m.to}
              >
                <i className={m.icon || 'fa fa-folder'} />
                <span>{m.title}</span>
                {showBadgeNbr() && (
                  <span
                    class='badge badge-primary'
                    style={{
                      position: 'absolute',
                      top: '-2px',
                      background: 'red'
                    }}
                  >
                    {count || 0}
                  </span>
                )}
              </a>
            </li>
          )
        })}
      </>
    )
  }

  return (
    <aside
      className='main-sidebar'
      style={{ height: '100%', position: 'fixed', overflowY: 'scroll' }}
    >
      <section className='sidebar'>
        <div className='user-panel'>
          <div className='pull-left image'>
            {me.modo_img && (
              <img src={me.modo_img} className='img-circle' alt='User' />
            )}
            {me.modo_email && (
              <Gravatar email={me.modo_email} className='img-circle' />
            )}
          </div>
          <div className='pull-left info'>
            <p className='text-capitalize'>{me.modo_login || 'Arthur'}</p>
            <a href='#'>
              <i className='fa fa-circle text-success' /> En ligne
            </a>
          </div>
        </div>
        <ul className='sidebar-menu tree' data-widget='tree'>
          <li className='header'>NAVIGATION PRINCIPALE</li>
          <RenderTreeview />
        </ul>
      </section>
    </aside>
  )
})
