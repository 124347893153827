import React from 'react';
import { Link } from 'react-router-dom';

export const ListePagesRow = ({ id, name, metaTitle, metaBody }) => (
  <tr>
    <td>{id}</td>
    <td>{name}</td>
    <td>{metaTitle}</td>
    <td>{metaBody.slice(0, 50)}...</td>
    <td>shaouzi</td>
    <td>02/01/2019</td>
    <td>
      <Link to='/page'>
        <i className='fa fa-pencil' aria-hidden='true' />
      </Link>
    </td>
  </tr>
);
