/** @format */

import React from 'react';

import useCheckListTrigger from '../sm/useCheckListTrigger';

export default function CheckListTrigger({ _WINDOW, label, ...props }) {
  const { classNameType } = useCheckListTrigger(props);
  const _LABEL = React.useMemo(() => {
    if (typeof label === 'function') {
      return <span>{label({ _WINDOW })}</span>;
    } else {
      return <span>{label}</span>;
    }
  }, [_WINDOW, label]);

  return (
    <div style={{ marginRight: '5px' }}>
      <button
        className={`btn btn-${classNameType} text-sm`}
        onClick={props.handleOpen}
      >
        <span className={props.icon || ''}>&nbsp;</span>
        {_LABEL}
      </button>
    </div>
  );
}
