/** @format */
import React from 'react'

export default function useVisaStatusData ({ include = false, meta = {} } = {}) {
  const data = React.useMemo(() => {
    return include
      ? {
          data: [
            {
              visa_status_id: '0',
              visa_status_title: 'Inactif'
            },
            {
              visa_status_id: '1',
              visa_status_title: 'Actif'
            },
            {
              visa_status_id: '2',
              visa_status_title: 'Annulé'
            }
          ],
          page: 1,
          lastPage: 1,
          total: 3
        }
      : null
  }, [include])

  const fetchNext = React.useCallback(() => {}, [])
  const fetchNextById = React.useCallback(
    ({ id = '' } = {}) => {
      if (id) {
        const [{ visa_status_id, visa_status_title } = {}] =
          data.filter(({ visa_status_id } = {}) => visa_status_id == id) || []
        if (visa_status_id) {
          return Promise.resolve({
            visa_status_id,
            visa_status_title
          })
        }
      }
      return Promise.resolve(null)
    },
    [data]
  )

  return include
    ? {
        props: {
          ...data,
          ...meta,
          fetchNext,
          fetchNextById
        }
      }
    : {}
}
