/** @format */
import React from 'react';

import useCheckList from '../sm/useCheckList';

import BModal from '../../Bagheram-modal';
import CheckListChips from './CheckListChips';
import CheckListContent from './CheckListContent';
import CheckListFooter from './CheckListFooter';
import CheckListPagination from './CheckListPagination';
import CheckListSearch from './CheckListSearch';
import CheckListTrigger from './CheckListTrigger';
import CheckListTitle from './CheckListTitle';
import CheckListUtils from './CheckListUtils';

export default function CheckList({ _WINDOW, ...props } = {}) {
  const {
    checkedItems,
    currentItems,
    fetchNext,
    modal: { isOpen, close: handleClose, open: handleOpen },
  } = useCheckList(props);

  if (!props.data) {
    return null;
  }
  return (
    <BModal
      {...{ open: isOpen, handleClose }}
      _trigger={
        <CheckListTrigger
          {...{
            label: props.label,
            icon: props.icon,
            handleOpen,
            checkedItems,
            _WINDOW,
          }}
        />
      }
      _title={
        <div className={'row'} style={{ width: '100%' }}>
          <div
            className={'col-sm-6 col-md-8'}
            style={{
              paddingBottom: _WINDOW.width > 800 ? '5px' : '10px',
              textAlign: _WINDOW.width > 500 ? 'left' : 'center',
            }}
          >
            <CheckListTitle {...{ title: props.title || 'Titre du filtre' }} />
          </div>
          <div
            className={'row col-sm-6 col-md-4'}
            style={{ paddingBottom: '5px' }}
          >
            <CheckListSearch
              {...{
                fetchNext,
                hidden: (props.hiddenComponents || {}).search,
              }}
            />
          </div>
        </div>
      }
      _body={
        <React.Fragment>
          <NoSearchResult {...{ total: props.total, word: props.word }} />
          <ScrollView>
            <ScrollView.Sticky>
              <CheckListChips
                {...{
                  checkedItems,
                  fetchNextById: props.fetchNextById,
                  item_title: props.item_title,
                }}
              />
              <CheckListUtils {...{ currentItems, total: props.total }} />
            </ScrollView.Sticky>
            <div>
              <CheckListContent
                {...{
                  data: props.data,
                  total: props.total,
                  item_title: props.item_title,
                  item_key: props.item_key,
                  _fields: props._fields,
                  checkedItems,
                  currentItems,
                  _WINDOW,
                }}
              />
            </div>
          </ScrollView>
        </React.Fragment>
      }
      _footer={
        <Stack style={{ justifyContent: 'space-around' }}>
          <div style={{ alignSelf: 'center', marginBottom: '10px' }}>
            <CheckListPagination
              {...{
                word: props.word,
                page: props.page,
                lastPage: props.lastPage,
                total: props.total,
                fetchNext,
              }}
            />
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <CheckListFooter
              {...{
                _WINDOW,
                handleClose,
                checkedItems,
                page: props.page || 1,
              }}
            />
          </div>
        </Stack>
      }
    ></BModal>
  );
}

function NoSearchResult({ total = 0, word = '' } = {}) {
  if (total > 0) {
    return null;
  }
  return (
    <i>
      <div
        style={{
          border: 'thin solid silver',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <span style={{ fontSize: '128px' }}>:(</span>
        <span className={'h3'}>
          Aucun résultat correspond à: <b>&ldquo;{word}&rdquo;</b>
        </span>
      </div>
    </i>
  );
}

function ScrollView({ children = null } = {}) {
  return (
    <div
      style={{
        height: 'auto',
        maxHeight: '100%',
        overflowX: 'hidden',
      }}
    >
      {children}
    </div>
  );
}
ScrollView.Sticky = StickyHeader;

function StickyHeader({
  children = null,
  style: stickyHeaderCustomStyle = { top: 0, zIndex: 10000 },
} = {}) {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '10px 0px',
        position: 'sticky',
        ...stickyHeaderCustomStyle,
      }}
    >
      {children}
    </div>
  );
}

function Stack({ children, style: defaultStackStyle = {} } = {}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        ...defaultStackStyle,
      }}
    >
      {children}
    </div>
  );
}
