import React, { useEffect, useState, useCallback, useMemo } from 'react'

import Reex, { mapActions, mapGetters } from '../../store'

import { Link } from 'react-router-dom'

import Autocomplete from '../../components/admin/Bagheram-autocomplete'
import DateTimePicker from '../../components/admin/Bagheram-datetimepicker'
import BImage from '../../components/admin/Bagheram-image'
import BSwitch from '../../components/admin/Bagheram-switch'
import PlaceCity from '../../components/admin/Bagheram-city'
import PlaceCountry from '../../components/admin/Bagheram-country'
import TextareaCkEditor from '../../components/admin/Bagheram-ckeditor'
import LU from '../../logics/utils'
import constants from '../../constants'
import BagheramWording from '../../components/admin/Bagheram-wording'

function useForceUpdate () {
  const [, setTick] = useState(0)
  const update = useCallback(() => {
    setTick(tick => tick + 1)
  }, [])
  return update
}
export default props => {
  const {
    match: { params },
    history
  } = props
  const { model, id } = params
  const { getItem, updateItem, setItem, getMenuData, setCountFeed } =
    mapActions()
  var { title } = getMenuData(model)

  if (model === 'pubs' && +props.location.query.pub_type === 1) {
    title = 'Feeds'
  }

  const alias = mapGetters('alias')[model]
  const hideOneEdit = mapGetters('hideOneEdit')

  const [imagePreview, setImagePreview] = useState({})
  const [newScatsArr, addFile] = useState([])
  const [newScatsTitle, addTitle] = useState([])
  const [file, setFile] = useState(null)
  const [disable, setDisable] = useState(true)
  const [iso, setIso] = useState('')
  const [date, setDate] = useState('')

  const forceUpdate = useForceUpdate()

  const itemForm = mapGetters('item')

  const languages = useMemo(() => Reex.getState('languages'), [])
  const statusLabels = useMemo(() => Reex.getState('statusLabels'), [])

  const { [model.slice(0, -1)]: item = {}, fields } = itemForm

  const mdl = model.slice(0, -1)

  const dataItem = Object.entries(fields || {}).filter(
    ([key, value]) => item && key in item
  )

  const wordings = useMemo(() => {
    if (item.wordings && item.wordings.length) {
      return item.wordings.map(v => ({
        ...v,
        [`${model.slice(0, -1)}_id`]: id
      }))
    } else {
      return languages.map((l, i) => ({
        wording_title: item[`${model.slice(0, -1)}_title`],
        wording_value: '',
        wording_locale: i,
        [`${model.slice(0, -1)}_id`]: id
      }))
    }
  }, [item, item.wordings, languages, id, model])

  console.log(2, wordings)

  const refs = {}

  useEffect(() => {
    getItem({ model, id })
  }, [model, id])

  function isNull (val) {
    return (
      val === null ||
      val === undefined ||
      val == 'null' ||
      val == 'undefined' ||
      !val ||
      val == ''
    )
  }

  function update () {
    const formData = new FormData()
    const data = {}
    const query = props.location.query || {}

    for (const field in refs) {
      if (field == 'new_scats') continue
      let value
      if (refs[field].type == 'file') {
        value = refs[field].files[0]
      } else if (refs[field].type == 'select-multiple') {
        value = refs[field].data
      } else {
        value = refs[field].value
      }
      data[field] = value
    }

    const newItem = {}
    for (const k in data) {
      newItem[k] = item[k]
    }

    const newData = LU.objectDiff(newItem, data, false)

    delete newData[`${model.slice(0, -1)}_id`]
    for (const field in newData) {
      formData.append(field, newData[field])
    }
    if (model == 'cats') {
      for (let i = 0; i < newScatsArr.length; i++) {
        formData.append('new_scats', newScatsArr[i])
        formData.append('new_titles', newScatsTitle[i].scat_title)
      }

      if (
        itemForm &&
        itemForm.cat &&
        itemForm.cat.scats &&
        itemForm.cat.scats.length
      ) {
        formData.append(
          'del_scats',
          itemForm.cat.scats
            .filter(scat => scat.scat_visa == 0)
            .map(scat => scat.scat_id)
        )

        formData.append(
          'add_scats',
          itemForm.cat.scats
            .filter(scat => scat.scat_visa == 1)
            .map(scat => scat.scat_id)
        )
      }
    }

    if (model == 'users') {
      var deletes = [
        'user_score1',
        'user_score2',
        'user_score3',
        'user_score4',
        'user_score5',
        'user_score6',
        'user_score7',
        'user_game',
        'create_at',
        'update_at'
      ]
      if (refs.user_town && refs.user_town.values) {
        formData.set('user_town', refs.user_town.values.value)
        formData.set('user_lat', refs.user_town.values.lat)
        formData.set('user_lon', refs.user_town.values.lng)
      }

      for (var z in deletes) {
        formData.delete(deletes[z])
      }
    }

    updateItem({ model, data: formData, id })
      .then(x => {
        const dialogInstance = new window.BootstrapDialog()
          .setTitle('Operation réussi')
          .setClosable(false)
          .setMessage("L'entrée a été bien sauvegardée")
          .setType(window.BootstrapDialog.TYPE_SUCCESS)
          .open()

        setTimeout(() => {
          dialogInstance.close()
          setTimeout(() => {
            setCountFeed(0)
            if (model === 'pubs') {
              history.push(
                `/admin/${model}?filter=${JSON.stringify({
                  pub_type: { op: '=', value: query.pub_type || 0 }
                })}`
              )
            } else {
              getItem({ model, id })
            }
          }, 500)
        }, 2000)
      })
      .catch(e => {
        new window.BootstrapDialog()
          .setTitle('Operation échouée')
          .setClosable(false)
          .setMessage("Une erreur s'est produite")
          .setButtons([
            {
              label: 'ok',
              action: function (dialog) {
                dialog.close()
              }
            }
          ])
          .setType(window.BootstrapDialog.TYPE_DANGER)
          .open()
      })
  }

  function handleSubmit (e) {
    e.preventDefault()

    update()
  }

  function onChangeVideo (e, key) {
    if (e.target && e.target.files) {
      let file = e.target.files[0]
      let blobURL = URL.createObjectURL(file)
      setImagePreview(p => ({ ...p, [key]: blobURL }))
    }
  }

  function onChangeImage (e, key) {
    var reader = new FileReader()

    reader.onload = e => {
      setImagePreview(p => ({ ...p, [key]: e.target.result }))
    }
    if (e.target && e.target.files) {
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function deleteScat (scat, averna, definitif) {
    if (definitif) {
      addTitle(newScatsTitle.filter((s, i) => i != scat))
      addFile(newScatsArr.filter((s, i) => i != scat))
      return
    }

    if (itemForm[mdl] && itemForm[mdl].scats) {
      const index = itemForm[mdl].scats.indexOf(scat)
      if (itemForm[mdl].scats[index]) {
        itemForm[mdl].scats[index].scat_visa = averna ? 1 : 0
      }
      setItem(itemForm)
      forceUpdate()
    }
  }

  async function addScat () {
    const title = refs.new_scats.value
    if (!title || !file) {
      const dialogInstance = new window.BootstrapDialog()
        .setTitle('Incorrect')
        .setClosable(true)
        .setMessage('Veuillez spécifier un titre et une image')
        .setType(window.BootstrapDialog.TYPE_SUCCESS)
        .open()

      return Promise.resolve('tsisy')
    }

    const misy = newScatsTitle.filter(
      scat => scat.scat_title.toLowerCase() == title.toLowerCase()
    )
    const misy2 = item.scats.filter(
      scat => scat.scat_title.toLowerCase() == title.toLowerCase()
    )

    if (misy.length || misy2.length) {
      const msg = misy.length
        ? ' existe déjà dans votre nouvelle liste'
        : misy2.length
        ? "est déjà attribuée à l'émostion mère actuelle"
        : 'dans la base de donnée'

      const dialogInstance = new window.BootstrapDialog()
        .setTitle('Existe déjà')
        .setClosable(true)
        .setMessage(
          `Une passion portant le nom <strong>${title}</strong> ${msg}`
        )
        .setType(window.BootstrapDialog.TYPE_SUCCESS)
        .open()

      return Promise.resolve('efa')
    }

    addTitle([
      ...newScatsTitle,
      {
        scat_visa: 1,
        scat_title: title,
        scat_img: imagePreview.new_scats_img
      }
    ])
    addFile([...newScatsArr, file])

    setImagePreview(p => ({ ...p, new_scats_img: null }))
    setFile(null)
    return Promise.resolve(null)
  }

  return (
    <section className='content'>
      <section className='content-header'>
        <h1 className='row'>
          <span onClick={() => history.goBack()} className='cursor-pointer'>
            <i className='fa fa-chevron-left' aria-hidden='true' />
          </span>
          <span style={{ marginLeft: 10 }}>
            Edition {String(title).toLowerCase().slice(0, -1)} nº{id}
          </span>
        </h1>
      </section>

      <section className='content'>
        <div className='box box-info' style={{ paddingTop: '50px' }}>
          <form
            method='POST'
            action={`/admin/v1/${model}`}
            onSubmit={handleSubmit}
          >
            <div className='bagheram-row-center'>
              {dataItem.map(([key, value]) => {
                if (
                  (key.indexOf('fk_') == -1 && key.indexOf('_id') > -1) ||
                  hideOneEdit[key]
                ) {
                  return
                }

                if (key.indexOf('_visa') > -1) {
                  return (
                    <div
                      key={key}
                      className={
                        'form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 ' +
                        (key == 'wording_visa'
                          ? ' col-lg-12 col-xl-12 '
                          : ' col-lg-6 col-xl-6 ')
                      }
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BSwitch
                          field={key}
                          value={item[key]}
                          alias={statusLabels}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('_locale') > -1) {
                  return (
                    <div
                      key={key}
                      className={
                        'form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 ' +
                        (key == 'wording_locale'
                          ? ' col-lg-12 col-xl-12 '
                          : ' col-lg-6 col-xl-6 ')
                      }
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BSwitch
                          field={key}
                          value={item[key]}
                          alias={languages}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('_status') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BSwitch
                          field={key}
                          value={item[key]}
                          alias={['Lu', 'Non lu']}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('_gender') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BSwitch
                          field={key}
                          value={item[key]}
                          alias={['Homme', 'Femme']}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (
                  key.indexOf('_accept') > -1 ||
                  key.indexOf('user_confirmbirth') > -1
                ) {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BSwitch
                          field={key}
                          value={item[key]}
                          alias={['Accepté', 'En cours']}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key.indexOf('_blocked') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BSwitch
                          field={key}
                          value={item[key]}
                          alias={['Oui', 'Non']}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (
                  key.indexOf('_img') > -1 ||
                  String(item[key]).indexOf('svg') > -1
                ) {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'>
                        <BImage
                          model={
                            model === 'pubs' && +item['pub_type'] === 1
                              ? 'users'
                              : model
                          }
                          src={imagePreview[key] || item[key]}
                        />
                        {!(model === 'pubs' && +item['pub_type'] === 1) && (
                          <span className='btn btn-primary btn-file'>
                            Choisir{' '}
                            {String(item[key]).indexOf('svg') > -1 && (
                              <input
                                type='file'
                                accept='image/svg+xml'
                                ref={node => (refs[key] = node)}
                                onChange={e => onChangeImage(e, key)}
                              />
                            )}
                            {String(item[key]).indexOf('svg') == -1 && (
                              <input
                                type='file'
                                ref={node => (refs[key] = node)}
                                onChange={e => onChangeImage(e, key)}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  )
                }
                if (key.indexOf('_video') > -1) {
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderx: 'thin solid red'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'
                        style={{ borderx: 'thin solid red' }}
                      >
                        {imagePreview[key] && (
                          <video
                            width='200'
                            controls='controls'
                            preload='metadata'
                            style={{ borderRadius: 10 }}
                            src={imagePreview[key] + '#t=0.1'}
                            type='video/mp4'
                          />
                        )}
                        {!imagePreview[key] && (
                          <video
                            width='200'
                            controls='controls'
                            preload='metadata'
                            style={{ borderRadius: 10 }}
                            src={
                              constants.host +
                              '/uploads/pubs/' +
                              item[key] +
                              '#t=0.1'
                            }
                            type='video/mp4'
                          />
                        )}
                        <span className='btn btn-primary btn-file bagheram-btn-file'>
                          Choisir une video{' '}
                          <input
                            type='file'
                            accept='video/*'
                            ref={node => (refs[key] = node)}
                            onChange={e => onChangeVideo(e, key)}
                          />
                        </span>
                      </div>
                    </div>
                  )
                }

                if (['datetime', 'date'].indexOf(value.type) > -1) {
                  return (
                    <div
                      key={key + Math.random()}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <DateTimePicker
                          forwardRef={node => (refs[key] = node)}
                          field={key}
                          model={model}
                          placeholder={key}
                          defaultValue={date || item[key]}
                          onChange={val => {
                            var date_format = String(val).split('/').reverse()
                            if (date_format.length) {
                              setDate(date_format.join('-'))
                            } else {
                              setDate(val)
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                }
                if (key.indexOf('fk_cat_id') == 0 && model == 'pubs') {
                  return <></>
                }
                if (key.indexOf('fk_scat_id') == 0 && model == 'pubs') {
                  if (+item['pub_type'] === 0) {
                    return <></>
                  }
                  let defaultDatas = {}
                  if (item['fk_scat_id']) {
                    defaultDatas = {
                      text: item['scat_title'],
                      id: item['fk_scat_id'],
                      isCat: 0
                    }
                  }
                  if (+item['fk_scat_id'] === 0 && item['fk_cat_id']) {
                    defaultDatas = {
                      text: item['cat_title'],
                      id: item['fk_cat_id'],
                      isCat: 1
                    }
                  }
                  return (
                    <div
                      key={key}
                      className='form-group d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      style={{ borderx: 'thin solid red' }}
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 col-form-label text-right'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        Passion
                      </label>
                      <div className='col-xs-9 col-sm-9 col-md-10 col-lg-8 col-xl-8'>
                        <Autocomplete
                          forwardRef={node => (refs[key] = node)}
                          className='form-control'
                          model={model}
                          field={key}
                          placeholder={alias[key]}
                          defaultDatas={defaultDatas}
                          defaultValue={defaultDatas.value}
                        />
                      </div>
                    </div>
                  )
                }
                if (key.indexOf('fk_') == 0) {
                  if (key === 'fk_user_id' && model === 'pubs') {
                    return <></>
                  } else {
                    return (
                      <div
                        key={key}
                        className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                      >
                        <label
                          htmlFor='inputEmail'
                          className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                          }}
                        >
                          {key.indexOf('fk_user_id') === 0
                            ? 'Utilisateur'
                            : key.indexOf('fk_scat_id') === 0
                            ? 'Passion Fille'
                            : key.indexOf('fk_cat_id') === 0
                            ? 'Passion Mère'
                            : alias[key] || key}
                        </label>
                        <div
                          className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <Autocomplete
                            forwardRef={node => (refs[key] = node)}
                            className='form-control'
                            model={model}
                            field={key}
                            defaultValue={item[key]}
                            placeholder={key}
                            disabled={
                              model === 'pubs' && +item['pub_type'] === 0
                            }
                          />
                        </div>
                      </div>
                    )
                  }
                }
                if (key == 'user_country') {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <PlaceCountry
                          model={model}
                          field={key}
                          country
                          defaultValue={item[key]}
                          placeholder='Pays'
                          onChange={data => {
                            if (data && data.id > 0) {
                              setDisable(false)
                              setIso(data.iso)
                            } else {
                              setDisable(true)
                            }
                          }}
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'user_town') {
                  return (
                    <div
                      key={key}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <PlaceCity
                          model={model}
                          field={key}
                          disabled={disable}
                          iso={iso}
                          defaultValue={item[key]}
                          placeholder='Ville'
                          forwardRef={node => (refs[key] = node)}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'wording_value') {
                  return (
                    <div
                      key={key + Math.random()}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'stretch'
                        }}
                      >
                        {['page_cgu'].indexOf(item.wording_title) == -1 && (
                          <textarea
                            type={key}
                            className='ckeditor form-control'
                            id={key}
                            cols='100'
                            rows='4'
                            placeholder={key}
                            maxLength={value.maxLength}
                            name={key}
                            defaultValue={item[key]}
                            ref={node => (refs[key] = node)}
                          />
                        )}
                        {['page_cgu'].indexOf(item.wording_title) > -1 && (
                          <TextareaCkEditor
                            placeholder={key}
                            maxLength={value.maxLength}
                            name={key}
                            field={key}
                            defaultValue={item[key]}
                            forwardRef={node => (refs[key] = node)}
                            onChange={v => {
                              refs[key].value = v
                            }}
                            formName='json'
                          />
                        )}
                      </div>
                    </div>
                  )
                }

                if (key == 'wording_title') {
                  return (
                    <div
                      key={key + Math.random()}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <input
                          type={key}
                          className='form-control'
                          id={key}
                          placeholder={key}
                          maxLength={value.maxLength}
                          name={key}
                          defaultValue={item[key]}
                          ref={node => (refs[key] = node)}
                          style={{ borderRadius: 5, height: 40 }}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'user_blockingdesc') {
                  return (
                    <div
                      key={key + Math.random()}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <textarea
                          type={key}
                          className='form-control'
                          id={key}
                          cols='100'
                          rows='4'
                          placeholder={alias[key] || key}
                          maxLength={value.maxLength}
                          name={key}
                          ref={node => (refs[key] = node)}
                          style={{ borderRadius: '5px' }}
                          defaultValue={item[key]}
                        />
                      </div>
                    </div>
                  )
                }

                if (key == 'pub_description') {
                  return (
                    <div
                      key={key + Math.random()}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <textarea
                          type={key}
                          className='form-control'
                          id={key}
                          cols='100'
                          rows='4'
                          placeholder={alias[key] || key}
                          maxLength={value.maxLength}
                          name={key}
                          ref={node => (refs[key] = node)}
                          style={{ borderRadius: '5px' }}
                          defaultValue={item[key]}
                        />
                      </div>
                    </div>
                  )
                }

                return (
                  <>
                    <div
                      key={key + Math.random()}
                      className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                    >
                      <label
                        htmlFor='inputEmail'
                        className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {alias[key] || key}
                      </label>
                      <div
                        className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <input
                          type={key}
                          className='form-control'
                          id={key}
                          placeholder={key}
                          maxLength={value.maxLength}
                          name={key}
                          defaultValue={item[key]}
                          ref={node => (refs[key] = node)}
                          style={{ borderRadius: 5, height: 40 }}
                        />
                      </div>
                    </div>
                    {['cats', 'scats'].indexOf(model) > -1 && (
                      <>
                        {wordings.map(wording => (
                          <div
                            key={'wording_' + wording.wording_locale}
                            className='form-group row d-flex flex-row flex-wrap justify-content-center align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '
                          >
                            <label
                              className='col-xs-2 col-sm-2 col-md-2 col-lg-3 col-xl-2 col-form-label'
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                              }}
                            >
                              Titre en{' '}
                              {String(
                                languages[wording.wording_locale]
                              ).toLowerCase()}
                            </label>
                            <div
                              className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                              }}
                            >
                              <BagheramWording
                                type={'text'}
                                className='form-control'
                                placeholder={languages[wording.wording_locale]}
                                name={'wording_value_' + wording.wording_locale}
                                defaultValue={wording.wording_value}
                                forwardRef={node =>
                                  (refs[
                                    'wording_value_' + wording.wording_locale
                                  ] = node)
                                }
                                style={{ borderRadius: 5, height: 40 }}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )
              })}
            </div>

            {model == 'cats' && (
              <center
                className='form-group col-12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  className='col-12 col-form-label'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <h4>
                    <b>Passion filles</b>
                  </h4>
                </div>
                <div
                  className='col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    className='col-12 bagheram-box-shadow bagheram-rounded'
                    style={{ padding: 10, marginTop: 20 }}
                  >
                    <h5>Passion(s) fille(s)</h5>
                    <div
                      className='col-4'
                      style={{
                        maxWidth: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap'
                      }}
                    >
                      {item.scats &&
                        item.scats
                          .filter(scat => scat.scat_visa <= 1)
                          .map((scat, i) => (
                            <div
                              key={i + 'scats'}
                              className='d-flex flex-row'
                              style={{
                                maxWidth: 200,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: 5,
                                margin: 5,
                                borderRadius: 20,
                                border: `thin solid ${
                                  scat.scat_visa != 0 ? 'grey' : 'red'
                                }`
                              }}
                            >
                              {scat.scat_visa != 0 && (
                                <BImage
                                  model={model}
                                  src={scat.scat_img}
                                  alt='...'
                                  className='img-fluid img-thumbnail'
                                  style={{
                                    borderRadius: 40,
                                    height: 40,
                                    width: 40
                                  }}
                                />
                              )}
                              {scat.scat_visa == 0 && (
                                <span style={{ padding: 5 }}>
                                  <s>{scat.scat_title}</s>
                                </span>
                              )}
                              {scat.scat_visa != 0 && (
                                <span style={{ padding: 5 }}>
                                  {scat.scat_title}
                                </span>
                              )}
                              {scat.scat_visa != 0 && (
                                <button
                                  style={{ borderRadius: 20, width: 40 }}
                                  type='button'
                                  onClick={() => deleteScat(scat)}
                                >
                                  X
                                </button>
                              )}

                              {scat.scat_visa == 0 && (
                                <button
                                  style={{ borderRadius: 20, width: 100 }}
                                  type='button'
                                  className='btn bagheram-btn-grey'
                                  onClick={() => deleteScat(scat, true)}
                                >
                                  <icon className='fa fa-plus'></icon>
                                  <span style={{ marginLeft: 3 }}>Valider</span>
                                </button>
                              )}
                            </div>
                          ))}
                    </div>
                  </div>
                  <div
                    className='col-12 bagheram-box-shadow bagheram-rounded'
                    style={{ padding: 10, marginTop: 20 }}
                  >
                    <h5>Passion(s) fille(s) à sauvegarder</h5>
                    {newScatsTitle &&
                      newScatsTitle.map((scat, i) => (
                        <div
                          key={i + '_'}
                          style={{
                            maxWidth: 200,
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: 10,
                            margin: 5,
                            borderRadius: 20,
                            border: `thin solid ${
                              scat.scat_visa != 0 ? 'grey' : 'red'
                            }`
                          }}
                        >
                          <BImage
                            model={model}
                            src={scat.scat_img}
                            alt='...'
                            className='img-fluid img-thumbnail'
                            style={{ borderRadius: 40, height: 40, width: 40 }}
                          />
                          {scat.scat_visa == 0 && (
                            <span style={{ padding: 5 }}>
                              <s>{scat.scat_title}</s>
                            </span>
                          )}
                          {scat.scat_visa != 0 && (
                            <span style={{ padding: 5 }}>
                              {scat.scat_title}
                            </span>
                          )}
                          {scat.scat_visa != 0 && (
                            <button
                              style={{ borderRadius: 20, width: 40 }}
                              type='button'
                              onClick={() => deleteScat(i, false, true)}
                            >
                              X
                            </button>
                          )}
                        </div>
                      ))}
                    {(!newScatsTitle || !newScatsTitle.length) && (
                      <div>Vide</div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 10,
                      margin: 5,
                      borderRadius: 20,
                      marginTop: 20,
                      border: 'thin solid grey'
                    }}
                  >
                    <div className='col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                      <div
                        className='btn-file'
                        style={{
                          padding: 0,
                          margin: 0,
                          width: 50,
                          height: 50,
                          cursor: 'pointer'
                        }}
                      >
                        {!imagePreview.new_scats_img && !item.new_scats_img && (
                          <button
                            style={{
                              fontSize: 8,
                              height: 50,
                              borderRadius: 10
                            }}
                          >
                            Ajouter une image
                          </button>
                        )}
                        {(!!imagePreview.new_scats_img ||
                          !!item.new_scats_img) && (
                          <img
                            alt='Choisir Image'
                            style={{
                              padding: 0,
                              margin: 0,
                              width: 50,
                              height: 50
                            }}
                            src={
                              imagePreview.new_scats_img || item.new_scats_img
                            }
                          />
                        )}
                        <input
                          type='file'
                          ref={node => (refs.new_scats_img = node)}
                          accept='image/*'
                          onChange={e => {
                            if (e && e.target) {
                              setFile(e.target.files[0])
                            }
                            onChangeImage(e, 'new_scats_img')
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className='col-xs-8 col-sm-8 col-md-10 col-lg-8 col-xl-8'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <input
                        type='text'
                        className='form-control'
                        id='new_scats'
                        placeholder='Nouvelle fille'
                        name='new_scats'
                        ref={node => (refs.new_scats = node)}
                        style={{ borderRadius: 5, height: 40 }}
                      />
                    </div>

                    <button
                      type='button'
                      onClick={async x => {
                        const error = await addScat()
                        if (!error) {
                          window.document.getElementById('new_scats').value = ''
                        }
                      }}
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </center>
            )}
            <br />

            <div className='form-group row'>
              <div
                className='col-sm-12'
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <button
                  type='submit'
                  className='btn btn-primary'
                  style={{ backgroundColor: '#783fbd !important' }}
                >
                  Sauvegarder
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </section>
  )
}
