/** @format */
import React from 'react'

export default function useVideoTypeData ({ include = false, meta = {} } = {}) {
  const data = React.useMemo(() => {
    return include
      ? {
          data: [
            {
              vid_type_id: '0',
              vid_type_title: 'Surprise'
            },
            {
              vid_type_id: '1',
              vid_type_title: 'Anniversaire'
            }
          ],
          page: 1,
          lastPage: 1,
          total: 2
        }
      : null
  }, [include])

  const fetchNext = React.useCallback(() => {}, [])

  const fetchNextById = React.useCallback(
    ({ id = '' } = {}) => {
      if (id) {
        const [{ vid_type_id, vid_type_title } = {}] =
          data.filter(({ vid_type_id } = {}) => vid_type_id == id) || []
        if (vid_type_id) {
          return Promise.resolve({
            vid_type_id,
            vid_type_title
          })
        }
      }
      return Promise.resolve(null)
    },
    [data]
  )

  return include
    ? {
        props: {
          ...data,
          ...meta,
          fetchNext,
          fetchNextById
        }
      }
    : {}
}
