import Ws from '@adonisjs/websocket-client'
import Constants from '../constants'
import { dispatch } from '../store'
const ws = Ws(Constants.hostWs, {
  reconnection: true
})

ws.on('open', () => {
  dispatch('setWsOpened', true)
})

ws.on('close', () => {
  dispatch('setWsOpened', false)
})

ws.connect()

export default ws
