/** @format */

import React from 'react';

import BDateRangePicker from '../../Bagheram-daterangepicker';

export default function DateRangePickers({
  data,
  filterItems,
  _WINDOW: { width: sw } = {},
} = {}) {
  const _defaultValue = useDateRangeDefaultValue({ filterItems });
  return (
    <React.Fragment>
      {(data || []).map((metaData = {}, index) => (
        <DateRangePicker
          key={`dateRangePicker#${index}`}
          {...{
            ...metaData,
            filterItems,
            _defaultValue,
            sw,
          }}
        />
      ))}
    </React.Fragment>
  );
}

function DateRangePicker({ sw, ...props }) {
  const { id, handleChange, handleClear, _value } = useDateRangePicker(props);

  const _TITLE = React.useMemo(() => {
    const { title } = props || {};
    if (typeof title === 'function') {
      return (
        <span style={{ marginLeft: '5px' }}>
          <span className={'fa fa-calendar'}>&nbsp;</span>
          <span>{title()}</span>

          <span onClick={handleClear} style={{ cursor: 'pointer' }}>
            &nbsp;&nbsp;
            <span className={'fa fa-eraser text-danger'} />
          </span>
        </span>
      );
    } else {
      return <span>{title || '-'}</span>;
    }
  }, [props.title]);

  return (
    <div
      style={{
        flexGrow: 1,
        width: '100%',
        maxWidth: sw > 1500 ? '600px' : sw > 1400 ? '500px' : '400px',
        marginBottom: '10px',
        marginLeft: '15px',
      }}
    >
      <div style={{ marginBottom: '5px' }}>
        <span className={'text-bold text-info'}>{_TITLE}</span>
      </div>
      <div>
        <BDateRangePicker
          id={id}
          onChange={handleChange}
          value={_value || {}}
          validate
          useEvent
        />
      </div>
    </div>
  );
}

function useDateRangePicker({ filterItems, query, _defaultValue } = {}) {
  const handleClear = React.useCallback(() => {
    filterItems.delete({ id: query.id });
  }, [filterItems, query]);

  const handleChange = React.useCallback(
    (date = {}) => {
      filterItems.update({
        ...query,
        value: { ...date },
      });
    },
    [filterItems, query]
  );

  const _value = React.useMemo(() => {
    return (_defaultValue && _defaultValue[query.id]) || null;
  }, [_defaultValue]);

  return {
    id: query.id,
    handleClear,
    handleChange,
    _value,
  };
}

function useDateRangeDefaultValue({ filterItems: { data = {} } = {} } = {}) {
  return { ...data };
}
