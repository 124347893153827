/** @format */

import React from 'react'

export default function useCheckListTrigger ({
  checkedItems: { keys = [] }
} = {}) {
  const classNameType = React.useMemo(() => {
    return keys.length > 0 ? 'success' : 'warning'
  }, [keys])

  return {
    classNameType
  }
}
