/** @format */

import React from 'react'

import { mapGetters } from '../../store'
import BModal from './Bagheram-modal'

const moment = window.moment

export default ({ title, ...props } = {}) => {
  const {
    DATA,
    _ACTIONS,
    _WINDOW,
    model,
    modal: { isOpen, close: handleClose },
    tempList
  } = useBGroupItems(props)

  const _LISTITEMS = React.useMemo(() => {
    if (!DATA.data.length && !DATA.tempData.length) {
      return null
    } else {
      return (
        <div
          className='flex-row'
          style={{
            height: 'inherit',
            maxHeight: '100%',
            width: '100%'
          }}
        >
          <div
            style={{
              flexBasis: 1,
              flexGrow: 1,
              flexShrink: 0,
              height: 'inherit',
              maxHeight: 'inherit',
              overflowY: 'scroll',
              margin: '0px 8px',
              borderRadius: '5px'
            }}
          >
            {!tempList.isOpen ? (
              <BGroupItemsList {...{ DATA, _WINDOW, tempList, model }} />
            ) : (
              <BGroupItemsTemporaryList
                {...{ DATA, _WINDOW, tempList, model }}
              />
            )}
          </div>
        </div>
      )
    }
  }, [DATA, model, tempList, _WINDOW])

  const _TOPACTIONS = React.useMemo(() => {
    return (
      <React.Fragment>
        <h2>{title}</h2>
        <BGroupItemsTopActions
          {...{
            DATA,
            _WINDOW,
            handleClose,
            hiddenActions: props.hiddenActions
          }}
        />
      </React.Fragment>
    )
  }, [DATA, _WINDOW, handleClose, props.hiddenActions])

  const _BOTTOMACTIONS = React.useMemo(() => {
    return <BGroupItemsBottomActions {...{ handleClose }} />
  }, [handleClose])

  if (!_LISTITEMS) {
    return null
  }
  return (
    <BModal
      {...{
        open: isOpen,
        handleClose,
        _trigger: _ACTIONS,
        _title: _TOPACTIONS,
        _body: _LISTITEMS,
        _footer: _BOTTOMACTIONS
      }}
    ></BModal>
  )
}

function BGroupItemsList ({
  DATA: { data = [], tempData = [], handleRemove, handleRemoveAll },
  _WINDOW: { width: sw },
  tempList,
  model
} = {}) {
  const alias = mapGetters('alias')[model]

  const ITEMS = React.useMemo(() => {
    if (!data.length) {
      return null
    }
    return (
      <table>
        <thead>
          <tr style={{ backgroundColor: '#eee' }}>
            {genItemInfoHeads({ model, alias })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr
                key={`${model}#${index}`}
                style={{ cursor: 'pointer' }}
                onClick={() => handleRemove(item._key)}
              >
                <td>
                  <span
                    className={'fa fa-minus text-success'}
                    style={{ marginRight: '10px' }}
                  ></span>
                </td>
                {genItemInfoTemplate({
                  model,
                  item
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }, [data, model, alias, handleRemove])

  return (
    <div className={'list-group'} style={{ position: 'relative' }}>
      <React.Fragment key={`remove#${model}`}>
        <div
          className={'list-group-item text-white bg-green'}
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 21
          }}
        >
          <div className={'flex-row'}>
            <div>
              <span>
                Sélection actuelle&nbsp;<b>( {data.length} )</b>
              </span>
              <span style={{ margin: '0px 10px' }}>|</span>
              <span style={{ cursor: 'pointer' }} onClick={tempList.open}>
                <span style={{ textDecoration: 'underline' }}>
                  Entrées enlevées
                </span>
                <span>
                  <b>&nbsp;( {tempData.length} )</b>
                </span>
              </span>
            </div>
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleRemoveAll}
            >
              <span className={'fa fa-eraser'}></span>
              {sw >= 800 && <span style={{ marginLeft: '5px' }}>Vider</span>}
            </div>
          </div>
        </div>
      </React.Fragment>
      <div style={{ maxWidth: '100%', overflowX: 'scroll' }}>{ITEMS}</div>
    </div>
  )
}

function BGroupItemsTemporaryList ({
  DATA: { data = [], tempData = [], handleRestore, handleRestoreAll },
  _WINDOW: { width: sw },
  tempList,
  model
} = {}) {
  const alias = mapGetters('alias')[model]

  const ITEMS = React.useMemo(() => {
    if (!tempData.length) {
      return null
    }
    return (
      <table>
        <thead>
          <tr style={{ backgroundColor: '#eee' }}>
            {genItemInfoHeads({ model, alias })}
          </tr>
        </thead>
        <tbody>
          {tempData.map((item, index) => {
            return (
              <tr
                key={`${model}#${index}`}
                style={{ cursor: 'pointer' }}
                onClick={() => handleRestore(item._key)}
              >
                <td>
                  <span
                    className={'fa fa-minus text-info'}
                    style={{ marginRight: '10px' }}
                  ></span>
                </td>
                {genItemInfoTemplate({ model, item })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }, [alias, model, tempData, handleRestore])

  return (
    <div className={'list-group'}>
      <React.Fragment key={`remove#${model}`}>
        <div
          className={'list-group-item text-white bg-blue'}
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 21
          }}
        >
          <div className={'flex-row'}>
            <div>
              <span>
                {sw >= 700 ? (
                  <span>Eléments enlevés de la sélection actuelle&nbsp;</span>
                ) : (
                  <span>Eléments enlevés&nbsp;</span>
                )}
                <span>( {tempData.length} )</span>
              </span>
              <span style={{ margin: '0px 10px' }}>|</span>
              <span style={{ cursor: 'pointer' }} onClick={tempList.close}>
                {sw >= 700 ? (
                  <span style={{ textDecoration: 'underline' }}>
                    Entrées actuellement sélectionnées
                  </span>
                ) : (
                  <span>...</span>
                )}
                <span>
                  <b>&nbsp;( {data.length} )</b>
                </span>
              </span>
            </div>
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleRestoreAll}
            >
              <span className={'fa fa-undo'}></span>
              {sw >= 800 && <span style={{ marginLeft: '5px' }}>Rétablir</span>}
            </div>
          </div>
        </div>
      </React.Fragment>
      <div style={{ maxWidth: '100%', overflowX: 'scroll' }}>{ITEMS}</div>
    </div>
  )
}

function BGroupItemsTopActions ({
  DATA: { data, handleExport, handleAdminSuppression },
  _WINDOW: { width: sw },
  handleClose,
  hiddenActions
}) {
  const handleDelete = React.useCallback(() => {
    handleClose({ _delete: true })
  }, [handleClose])

  const handleUndoAdminSuppression = React.useCallback(() => {
    handleAdminSuppression()
    handleClose()
  }, [handleClose, handleAdminSuppression])

  const _ACTION = React.useMemo(() => {
    if (hiddenActions._delete) {
      return (
        <button
          className={'btn btn-warning'}
          style={{
            height: 'auto',
            display: hiddenActions._delete ? 'block' : 'none'
          }}
          disabled={!data.length}
          onClick={handleUndoAdminSuppression}
        >
          <span className={'fa fa-undo'}></span>
          {sw >= 1300 && (
            <span style={{ marginLeft: '10px' }}>Restaurer la sélection</span>
          )}
        </button>
      )
    } else {
      return (
        <button
          className={'btn btn-danger'}
          style={{
            height: 'auto',
            display: hiddenActions._delete ? 'none' : 'block',
            marginRight: '10px'
          }}
          disabled={!data.length}
          onClick={handleDelete}
        >
          <span className={'fa fa-trash'}></span>
          {sw >= 800 && (
            <span style={{ marginLeft: '10px' }}>Supprimer la sélection</span>
          )}
        </button>
      )
    }
  }, [hiddenActions, handleUndoAdminSuppression, handleDelete, sw])

  return (
    <div
      className='flex-row'
      style={{
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      <button
        className={'btn btn-success'}
        style={{ height: 'auto', marginRight: '10px' }}
        onClick={handleExport}
      >
        <span className={'fa fa-download'}></span>
        {sw >= 800 && (
          <span style={{ marginLeft: '10px' }}>Exporter en CSV</span>
        )}
      </button>
      {_ACTION}
    </div>
  )
}

function BGroupItemsBottomActions ({ handleClose }) {
  return (
    <div
      className='flex-row'
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <button
        className={'btn'}
        style={{
          height: 'auto',
          marginRight: '10px',
          background: 'grey',
          color: 'white'
        }}
        onClick={handleClose}
      >
        <span className={'fa fa-close'} style={{ marginRight: '10px' }}></span>
        <span>Fermer</span>
      </button>
    </div>
  )
}

function useBGroupItems ({
  children = null,
  trigger = null,
  model = '',
  ...props
} = {}) {
  const { _window: _WINDOW } = useWindowDimensions()
  const { tempList } = useToggleTempList()
  const { DATA } = useData({ ...props, model, tempList })
  const { modal } = useModal({ DATA, tempList })
  const { _ACTIONS } = useTrigger({
    _WINDOW,
    children,
    trigger,
    DATA,
    modal,
    model,
    hiddenActions: props.hiddenActions
  })

  return {
    modal,
    model,
    DATA,
    tempList,
    _ACTIONS,
    _WINDOW
  }
}

function useData ({
  items,
  model,
  doRemoveItems,
  doDisableItems,
  doDownloadItemsToCsv,
  doUndoAdminSuppression,
  updateItems,
  tempList
}) {
  const model_pk = React.useMemo(() => {
    return model ? `${model.slice(0, -1)}_id` : ''
  }, [model])

  const [tempData, setTempData] = React.useState([])

  const data = React.useMemo(() => {
    if (typeof items === 'object' && !Array.isArray(items)) {
      return Object.keys(items).reduce((acc, _key) => {
        if (items[_key]) {
          acc.push({ ...items[_key], _key })
        }
        return [...acc]
      }, [])
    }
  }, [items])

  const clearTempData = React.useCallback(() => {
    setTempData([])
  }, [setTempData])

  const handleRestore = React.useCallback(
    (..._keys) => {
      if (_keys.length) {
        setTempData(tempDt => {
          const { tempData, updateData } = _keys.reduce(
            ({ tempData, updateData }, _key) => {
              const _index = tempData.findIndex(item => item._key == _key)
              if (_index > -1) {
                updateData[_key] = tempData[_index]
                tempData.splice(_index, 1)
              }
              return {
                tempData,
                updateData
              }
            },
            { tempData: [...tempDt], updateData: {} }
          )
          updateItems({ ...items, ...updateData })
          return [...tempData]
        })
      }
    },
    [items, setTempData, updateItems]
  )

  const handleRestoreAll = React.useCallback(() => {
    handleRestore(...tempData.map(v => v._key))
  }, [tempData, handleRestore])

  const handleRemove = React.useCallback(
    (..._keys) => {
      if (_keys.length) {
        const { tempData, updateData } = _keys.reduce(
          ({ tempData, updateData }, _key) => {
            if (items && items[_key]) {
              tempData.push({ ...items[_key], _key })
              updateData[_key] = false
            }
            return { tempData, updateData }
          },
          { tempData: [], updateData: {} }
        )
        setTempData(tempDt => [...tempDt, ...tempData])
        updateItems({ ...items, ...updateData })
      }
    },
    [items, updateItems, setTempData]
  )

  const handleRemoveAll = React.useCallback(() => {
    handleRemove(...Object.keys(items || {}))
  }, [items, handleRemove])

  const handleDelete = React.useCallback(() => {
    if (data.length) {
      const _keys = data.map(v => v._key)
      doRemoveItems({
        data: { [model_pk]: { op: 'IN', value: [..._keys] } },
        next: (data = []) => {
          handleRemove(...data)
          clearTempData()
        }
      })
    }
  }, [data, model_pk, doRemoveItems, handleRemove, clearTempData])

  const handleDisable = React.useCallback(async () => {
    if (model === 'users') {
      doDisableItems({
        data: {
          [model_pk]: data.map(v => v._key),
          update: { user_visa: 0 }
        },
        next: (data = []) => {
          handleRemove(...data)
          clearTempData()
        }
      })
    }
  }, [data, model, model_pk, handleRemove, clearTempData, doDisableItems])

  const handleExport = React.useCallback(() => {
    doDownloadItemsToCsv({
      data: {
        [model_pk]: { op: 'IN', value: data.map(v => v._key) }
      }
    })
  }, [data, model_pk, doDownloadItemsToCsv])

  const handleAdminSuppression = React.useCallback(async () => {
    if (model === 'users') {
      doUndoAdminSuppression({
        data: {
          [model_pk]: data.map(v => v._key),
          update: { user_visa: 1 }
        },
        next: (data = []) => {
          handleRemove(...data)
          clearTempData()
        }
      })
    }
  }, [
    data,
    model,
    model_pk,
    handleRemove,
    clearTempData,
    doUndoAdminSuppression
  ])

  React.useEffect(() => {
    if (tempList.isOpen) {
      if (!tempData.length && data.length) {
        tempList.close()
      }
    } else {
      if (!data.length && tempData.length) {
        tempList.open()
      }
    }
  }, [data, tempData, tempList])

  return {
    DATA: {
      data,
      tempData,
      clearTempData,
      handleRemove,
      handleRestore,
      handleRemoveAll,
      handleRestoreAll,
      handleDelete,
      handleDisable,
      handleExport,
      handleAdminSuppression
    }
  }
}

function useModal ({
  DATA: { data, tempData, clearTempData, handleDelete },
  tempList
} = {}) {
  const [open, setOpen] = React.useState(false)

  const handleClose = React.useCallback(
    ({ _delete = false } = {}) => {
      if (_delete) {
        handleDelete()
      }
      if (tempList.isOpen) {
        tempList.close()
      }
      setOpen(false)
    },
    [handleDelete, tempList]
  )

  const handleOpen = React.useCallback(() => {
    clearTempData()
    setOpen(true)
  }, [])

  React.useEffect(() => {
    if (!open && !data.length) {
      if (tempData.length) {
        clearTempData()
      }
    }
  }, [open, data, tempData, clearTempData])

  return {
    modal: {
      isOpen: open,
      close: handleClose,
      open: handleOpen
    }
  }
}

function useTrigger ({
  _WINDOW,
  children,
  trigger,
  DATA: { data, handleDisable, handleAdminSuppression },
  modal: { close: handleClose, open: handleOpen },
  hiddenActions,
  model
} = {}) {
  const { width: sw } = React.useMemo(() => {
    return { ..._WINDOW }
  }, [_WINDOW])

  const _limits = React.useMemo(() => {
    const _lw = model === 'users' && !hiddenActions._delete ? 1150 : 1000
    const _uw = model === 'users' && !hiddenActions._delete ? 1500 : 1300
    return {
      _lw,
      _uw
    }
  }, [sw, model, hiddenActions])

  const handleDelete = React.useCallback(() => {
    handleClose({ _delete: true })
  }, [handleClose])

  const _DISABLE = React.useMemo(() => {
    if (hiddenActions._delete || model !== 'users') {
      return null
    }
    return (
      <div
        key={'disableSelection'}
        className={'btn btn-danger mgt-15'}
        style={{
          marginRight: '10px'
        }}
        disabled={!data.length}
        onClick={handleDisable}
      >
        <span className={'fa fa-ban'}></span>
        {sw >= _limits._uw && (
          <span>&nbsp;{'Rendre'} inactif la sélection</span>
        )}
        {sw >= _limits._lw && sw < _limits._uw && (
          <span>&nbsp;{'Rendre inactif'}</span>
        )}
        {sw >= _limits._lw && <span>&nbsp;( {data.length} )</span>}
      </div>
    )
  }, [data, handleDisable, hiddenActions, sw, _limits])

  const _DELETE = React.useMemo(() => {
    return (
      <div
        key={'deleteSelection'}
        className={'btn btn-danger mgt-15'}
        style={{
          marginRight: '10px'
        }}
        disabled={!data.length}
        onClick={!hiddenActions._delete ? handleDelete : handleAdminSuppression}
      >
        <span className={'fa fa-trash'}></span>
        {sw >= _limits._uw && (
          <span>
            {!hiddenActions._delete ? ' Supprimer' : ' Restaurer'} la sélection
          </span>
        )}
        {sw >= _limits._lw && sw < _limits._uw && (
          <span>
            &nbsp;{!hiddenActions._delete ? ' Supprimer' : ' Restaurer'}
          </span>
        )}
        {sw >= _limits._lw && <span>&nbsp;( {data.length} )</span>}
      </div>
    )
  }, [data, handleDelete, hiddenActions, handleAdminSuppression, sw, _limits])

  const _TRIGGER = React.useMemo(() => {
    return (
      <React.Fragment key={'viewSelection'}>
        {typeof trigger === 'function' ? (
          <div onClick={handleOpen}>{trigger({ data, _WINDOW, _limits })}</div>
        ) : (
          children
        )}
      </React.Fragment>
    )
  }, [children, trigger, data, handleOpen, _WINDOW, _limits])

  const _ACTIONS = React.useMemo(() => {
    return (
      <div key={'_actionsOnSelections'} className={'flex-row'}>
        {[_DISABLE, _DELETE, _TRIGGER]}
      </div>
    )
  }, [_DISABLE, _DELETE, _TRIGGER])

  return { _ACTIONS }
}

function useToggleTempList () {
  const [openTempList, setOpenTempList] = React.useState(false)

  const handleCloseTempList = React.useCallback(() => {
    setOpenTempList(false)
  }, [])

  const handleOpenTempList = React.useCallback(() => {
    setOpenTempList(true)
  }, [])

  const toggleTempList = React.useCallback(() => {
    setOpenTempList(open => !open)
  }, [setOpenTempList])

  return {
    tempList: {
      isOpen: openTempList,
      close: handleCloseTempList,
      open: handleOpenTempList,
      toggle: toggleTempList
    }
  }
}

function useWindowDimensions () {
  const [dimensions, setDimensions] = React.useState({})

  const getWindowDimensions = React.useCallback(() => {
    return {
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    }
  }, [])

  React.useEffect(() => {
    function handleWindowResize (e = null) {
      if (e) {
        e.preventDefault()
      }
      setDimensions(() => getWindowDimensions())
    }

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    _window: {
      ...dimensions
    }
  }
}

function genItemInfoHeads ({ alias, model }) {
  return [
    <th key={model}></th>,
    (FIELDS[model] || []).map((field, index) => {
      return (
        <th
          key={`${field}#${index}`}
          className={'text-capitalize'}
          style={{ marginRight: '5px', textAlign: 'justify' }}
        >
          {alias[field]}
        </th>
      )
    })
  ]
}

function genItemInfoTemplate ({ item, model, addOn = null } = {}) {
  return (FIELDS[model] || []).map((field, index) => {
    let _value = item[field]
    const _format = FORMATS[field] || null
    if (_format !== null) {
      if (typeof _format === 'function') {
        _value = _format(_value)
      } else if (typeof _format === 'object') {
        _value = _format[_value]
      }
    }
    return (
      <td
        key={`${field}#${index}`}
        className={'text-capitalize'}
        style={{ marginRight: '5px', textAlign: 'justify' }}
      >
        {index === 0 ? addOn : null} {_value || '-'}
      </td>
    )
  })
}

const FIELDS = {
  ['users']: ['user_id', 'user_login', 'user_gender', 'created_at'],
  ['cats']: ['cat_id', 'cat_title', 'created_at'],
  ['scats']: ['scat_id', 'scat_title', 'fk_cat_id', 'created_at'],
  ['wordings']: ['wording_value', 'created_at'],
  ['messages']: [
    'message_id',
    'fk_send_id',
    'fk_dest_id',
    'created_at',
    'fk_scat_id',
    'fk_cat_id',
    'message_cont'
  ],
  ['vids']: [
    'vid_id',
    'fk_send_id',
    'fk_dest_id',
    'created_at',
    'fk_scat_id',
    'fk_cat_id',
    'vid_file'
  ],
  ['signals']: [
    'signal_id',
    'fk_signaltype_id',
    'fk_send_id',
    'fk_dest_id',
    'created_at'
  ],
  ['signaltypes']: [
    'signaltype_id',
    'signaltype_label',
    'signaltype_description'
  ],
  ['badges']: ['badge_id', 'badge_level', 'badge_cat', 'badge_owner'],
  ['badgelists']: ['badgelist_id', 'badgelist_label'],
  ['games']: ['game_id', 'game_title', 'game_value']
}

const FORMATS = {
  user_gender: {
    [-1]: 'Secret',
    0: 'Femme',
    1: 'Homme'
  },
  created_at: date => moment(date).format('DD/MM/YYYY'),
  badge_level: {
    1: 'Bronze',
    2: 'Argent',
    3: 'Or'
  }
}
