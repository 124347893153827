/** @format */
import React from 'react'

export default function useCheckList ({
  data,
  model,
  query,
  item_key,
  item_title,
  fetchNext: fetchNextData,
  filterItems = {},
  isAdminSuppression = false,
  _reset = false
} = {}) {
  const { currentItems } = useCurrentItems({
    data,
    item_key,
    item_title,
    query
  })
  const { checkedItems, currentItems: crtItems } = useCheckedItems({
    query,
    currentItems,
    filterItems
  })
  const { savedItems } = useSavedItems({ checkedItems })
  const { modal } = useModal({
    checkedItems,
    savedItems,
    filterItems,
    query
  })

  const fetchNext = React.useCallback(
    ({ word = '', page }) => {
      if (fetchNextData) {
        fetchNextData({
          word,
          page
        })
      }
    },
    [fetchNextData]
  )

  const handleInitFilters = React.useCallback(() => {
    checkedItems.clear()
    savedItems.clear()
    filterItems.clear()
    fetchNext({ word: '', page: 1 })
  }, [checkedItems, savedItems, filterItems])

  React.useEffect(() => {
    if (model) {
      handleInitFilters({ clearQueryFilter: true })
    }
  }, [_reset, model, isAdminSuppression])

  React.useEffect(() => {
    if (_reset && filterItems.isVoid) {
      handleInitFilters()
    }
  }, [_reset, filterItems.isVoid])

  return {
    currentItems: { ...crtItems },
    checkedItems,
    fetchNext,
    modal
  }
}

function useCurrentItems ({
  data = [],
  item_key = '',
  item_title = '',
  query: { id = '' } = {}
} = {}) {
  const currentItems = React.useMemo(() => {
    return (data || []).reduce(
      (acc, item) => {
        const key = item[item_key]
        return {
          ...acc,
          ['data']: {
            ...acc.data,
            [key]: { ...item, item_key, item_title }
          },
          ['keys']: [...acc.keys, key]
        }
      },
      { data: {}, keys: [], id, item_key, item_title }
    )
  }, [data, item_key, item_title, id])

  return {
    currentItems
  }
}

function useSavedItems ({ checkedItems }) {
  const [savedItems, setSavedItems] = React.useState({})

  const isEmptySavedItems = React.useMemo(() => {
    return Object.keys(savedItems || {}).length === 0
  }, [savedItems])

  const updateSavedItems = React.useCallback(
    cb => {
      const savedItems = checkedItems.data.reduce((savedItems, item) => {
        return {
          ...savedItems,
          [item[item.item_key]]: { ...item }
        }
      }, {})
      setSavedItems({ ...savedItems })
      if (typeof cb === 'function') {
        cb({
          savedItemsKeys: checkedItems.keys.length ? checkedItems.keys : null
        })
      }
    },
    [checkedItems]
  )

  const clearSavedItems = React.useCallback(() => {
    !isEmptySavedItems && setSavedItems({})
  }, [isEmptySavedItems])

  return {
    savedItems: {
      data: { ...savedItems },
      clear: clearSavedItems,
      update: updateSavedItems,
      isEmpty: isEmptySavedItems
    }
  }
}

function useCheckedItems ({
  currentItems,
  query: { id = '' } = {},
  filterItems: { data: activeFilters = {} }
} = {}) {
  const [checkedItems, setCheckedItems] = React.useState({})
  const checkedItemsOnly = React.useMemo(() => {
    return Object.keys(checkedItems).reduce(
      (r, k) => {
        const item = checkedItems[k]
        if (item) {
          r['keys'].push(item[item.item_key])
          r['data'].push({ ...item })
        }
        return { ...r }
      },
      {
        keys: [],
        data: []
      }
    )
  }, [checkedItems])

  const isCheckedAllCurrentItems = React.useMemo(() => {
    return (currentItems || {}).keys.reduce((ans, item_id) => {
      if (ans) {
        return checkedItemsOnly.keys.indexOf(item_id) > -1
      } else {
        return false
      }
    }, true)
  }, [checkedItemsOnly, currentItems])

  const isInCheckedItems = React.useCallback(
    item_id => {
      return checkedItemsOnly.keys.indexOf(item_id) > -1
    },
    [checkedItemsOnly]
  )

  const isEmptyCheckedItems = React.useMemo(() => {
    return checkedItemsOnly.keys.length === 0
  }, [checkedItemsOnly])

  const resetCheckedItems = React.useCallback(({ init = {} }) => {
    setCheckedItems({ ...init })
  }, [])

  const clearCheckedItems = React.useCallback(() => {
    !isEmptyCheckedItems && resetCheckedItems({})
  }, [isEmptyCheckedItems])

  const updateCheckedItems = React.useCallback(
    (...updates) => {
      if (updates.length > 0) {
        setCheckedItems(checkedItems => {
          return {
            ...checkedItems,
            ...updates.reduce(
              (acc, { item: { item_key, ...item } = {}, checked } = {}) => {
                return {
                  ...acc,
                  [item[item_key]]: checked ? { item_key, ...item } : null
                }
              },
              {}
            )
          }
        })
      }
    },
    [setCheckedItems]
  )

  const addToCheckedItems = React.useCallback(
    (item = {}) => {
      updateCheckedItems({ item, checked: true })
    },
    [updateCheckedItems]
  )

  const deleteFromCheckedItems = React.useCallback(
    (item = null) => {
      updateCheckedItems({ item, checked: false })
    },
    [updateCheckedItems]
  )

  const checkCurrentItems = React.useCallback(
    ({ uncheck = false } = {}) => {
      setCheckedItems(checkedItems => {
        return {
          ...checkedItems,
          ...(currentItems || []).keys.reduce((acc, item_id) => {
            return {
              ...acc,
              [item_id]: uncheck ? null : { ...currentItems.data[item_id] }
            }
          }, {})
        }
      })
    },
    [currentItems]
  )

  const uncheckCurrentItems = React.useCallback(() => {
    checkCurrentItems({ uncheck: true })
  }, [checkCurrentItems])

  React.useEffect(() => {
    if (id && activeFilters[id] && currentItems['id'] === id) {
      const { data = {}, item_key = '' } = currentItems || {}
      const _updates = activeFilters[id].value.reduce((_updates, _key) => {
        if ((checkedItemsOnly.keys || []).indexOf(_key) == -1) {
          _updates.push({
            item: {
              item_key,
              ...(data[_key] ||
                data[item_key] || {
                  [item_key]: _key
                })
            },
            checked: true
          })
        }
        return _updates
      }, [])

      if (_updates.length > 0) {
        updateCheckedItems(..._updates)
      }
    }
  }, [activeFilters])

  return {
    checkedItems: {
      ...checkedItemsOnly,
      isEmpty: isEmptyCheckedItems,
      in: isInCheckedItems,
      add: addToCheckedItems,
      clear: clearCheckedItems,
      cancel: resetCheckedItems,
      update: updateCheckedItems,
      delete: deleteFromCheckedItems
    },
    currentItems: {
      ...currentItems,
      checkAll: checkCurrentItems,
      unCheckAll: uncheckCurrentItems,
      isAllChecked: isCheckedAllCurrentItems
    }
  }
}

function useModal ({ checkedItems, savedItems, filterItems, query }) {
  const [showModal, setShowModal] = React.useState(false)

  const handleModalToggle = React.useCallback(() => {
    setShowModal(showModal => !showModal)
  }, [filterItems])

  const handleModalOpen = React.useCallback(() => {
    setShowModal(true)
  }, [])

  const handleModalClose = React.useCallback(
    ({ update = false } = {}) => {
      setShowModal(false)
      if (update) {
        savedItems.update(({ savedItemsKeys = null } = {}) => {
          if (savedItemsKeys !== null) {
            filterItems.update({
              ...query,
              value: savedItemsKeys
            })
          } else {
            filterItems.delete({ ...query })
          }
        })
      } else {
        checkedItems.cancel({ init: { ...savedItems.data } })
      }
    },
    [checkedItems, savedItems, filterItems, query]
  )

  React.useEffect(() => {
    if (showModal && savedItems.isEmpty) {
      if (checkedItems.data.length > 0) {
        savedItems.update()
      }
    }
  }, [showModal])

  return {
    modal: {
      isOpen: showModal,
      close: handleModalClose,
      open: handleModalOpen,
      toggle: handleModalToggle
    }
  }
}
