/** @format */
import React from 'react'

export default function useMessageStatusData ({
  prefix = 'msg',
  include = false,
  meta = {}
} = {}) {
  const data = React.useMemo(() => {
    return include
      ? {
          data: [
            {
              [`${prefix}_status_id`]: '1',
              [`${prefix}_status_title`]: 'Lu'
            },
            {
              [`${prefix}_status_id`]: '0',
              [`${prefix}_status_title`]: 'Non Lu'
            }
          ],
          page: 1,
          lastPage: 1,
          total: 2
        }
      : null
  }, [include])

  const fetchNext = React.useCallback(() => {}, [])
  const fetchNextById = React.useCallback(
    ({ id = '' } = {}) => {
      if (id) {
        const [_item] =
          data.filter(({ [`${prefix}_status_id`]: _id } = {}) => _id == id) ||
          []
        if (_item) {
          return Promise.resolve({
            ..._item
          })
        }
      }
      return Promise.resolve(null)
    },
    [data, prefix]
  )

  return include
    ? {
        props: {
          ...data,
          ...meta,
          fetchNext,
          fetchNextById
        }
      }
    : {}
}
