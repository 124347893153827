import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import csc from '../../logics/csc'

const { getAllCountries } = csc

export default withRouter(props => {
  const [id, setId] = useState(`${props.model}_${props.field}`)

  function genCountries () {
    var arr = getAllCountries()
    return arr.map(v => ({ text: v.name, value: v.id, iso: v.iso }))
  }

  const [item, setItem] = useState([])

  function load () {
    setItem(genCountries())
  }

  function onChange (data) {
    if (props.onChange && typeof props.onChange == 'function') {
      props.onChange({
        id: parseInt(data.value),
        iso: data.iso,
        text: data.text
      })
    }
  }

  const groupBy = (items, key, keyValue) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: keyValue
          ? item[keyValue]
          : [...(result[item[key]] || []), item]
      }),
      {}
    )

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    window
      .jQuery(`#${id}`)
      .select2({
        placeholder: props.placeholder || 'TESSSSS',
        allowClear: true,
        tags: true,
        tokenSeparators: [',', ' '],
        width: 'resolve'
      })
      .val(props.defaultValue)
      .trigger('change')

    window.jQuery(`#${id}`).on('change', function (e) {
      let data = {}

      if (
        !!e.target.options[e.target.selectedIndex] &&
        e.target.options[e.target.selectedIndex].attributes
      ) {
        const arr =
          Object.values(e.target.options[e.target.selectedIndex].attributes) ||
          []
        data = {
          text:
            e.target.options[e.target.selectedIndex] &&
            e.target.options[e.target.selectedIndex].text,
          ...groupBy(arr, 'name', 'value')
        }
      }

      onChange(data, props.defaultValue != e.target.value)

      e.target.values = window.jQuery(`#${id}`).select2('data')

      e.target.clear = function () {
        window.jQuery(`#${id}`).val(null).trigger('change')
      }
    })
  }, [item])

  return (
    <>
      <select
        id={id}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={onChange}
        ref={props.forwardRef}
        className='form-control'
        style={{ width: '200px' }}
        multiple={props.multiple}
      >
        {item.map((item, i) => (
          <option key={i} value={item.value} iso={item.iso}>
            {item.text}
          </option>
        ))}
      </select>
    </>
  )
})
