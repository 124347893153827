/** @format */
import React from 'react'

export default function useGenderData ({ include = false, meta = {} } = {}) {
  const data = React.useMemo(() => {
    return include
      ? {
          data: [
            {
              gender_id: '0',
              gender_title: 'Femme'
            },
            {
              gender_id: '1',
              gender_title: 'Homme'
            },
            {
              gender_id: '2',
              gender_title: "C'est un secret"
            }
          ],
          page: 1,
          lastPage: 1,
          total: 3
        }
      : null
  }, [include])

  const fetchNext = React.useCallback(() => {}, [])
  const fetchNextById = React.useCallback(
    ({ id = '' } = {}) => {
      if (id) {
        const [{ gender_id, gender_title } = {}] =
          data.filter(({ gender_id } = {}) => gender_id == id) || []
        if (gender_id) {
          return Promise.resolve({
            gender_id,
            gender_title
          })
        }
      }
      return Promise.resolve(null)
    },
    [data]
  )

  return include
    ? {
        props: {
          ...data,
          ...meta,
          fetchNext,
          fetchNextById
        }
      }
    : {}
}
