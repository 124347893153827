import React from 'react'
import { ListeArticlesRow } from '../../components/tables/ListeArticlesRow'
import FilAriane from '../../components/shared/FilAriane'

class ListeArticles extends React.Component {
  createList () {
    const table = []
    for (let i = 0; i < 2; i++) {
      table.push(
        <ListeArticlesRow
          key={i}
          id={i + 1}
          title='Mon premier article'
          category='Ajout catégorie'
          createdAt='28/02/2019'
          status='Publié'
          views={50}
        />
      )
    }
    return table
  }

  componentDidMount () {
    const navarray = document.getElementsByClassName('nava')
    for (let i = 0; i < navarray.length; i++) {
      navarray[i].className = 'nava'
    }
    document.getElementById('nav-contenu').className += ' active'
  }

  render () {
    return (
      <section className='content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='block-white flex-row'>
              <h1 className='title-page'>
                <i className='fa fa-info' aria-hidden='true' />
                Liste des articles
              </h1>
              <a href='#'>
                <div className='btn btn-add mgt-15'>Ajouter</div>
              </a>
            </div>
          </div>
        </div>

        <FilAriane parent='Contenu' page='Articles' />

        <div className='row'>
          <div className='col-md-12'>
            <table>
              <tbody>
                <tr>
                  <th>ID </th>
                  <th>Titre</th>
                  <th>Catégorie</th>
                  <th>Date</th>
                  <th>Statut</th>
                  <th>Vue</th>
                  <th>Éditer/Supprimer/Afficher</th>
                </tr>

                {this.createList()}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    )
  }
}

export default ListeArticles
