/** @format */

import React from 'react'

import FILTERS from './sm.conf'
import LUtils from '../../../../logics/utils'

import useBadgeData from '../api/useBadgeData'
import useBadgeLevelData from '../api/useBadgeLevelData'
import useCountryData from '../api/useCountryData'
import useFeelingData from '../api/useFeelingData'
import useGameData from '../api/useGameData'
import useGenderData from '../api/useGenderData'
import useMessageStatusData from '../api/useMessageStatusData'
import useSignalsData from '../api/useSignalsData'
import useUserData from '../api/useUserData'
import useVideoStatusData from '../api/useVideoStatusData'
import useVideoTypeData from '../api/useVideoTypeData'
import useVisaStatusData from '../api/useVisaStatusData'

export default function useBFilter (props) {
  const { filterItems } = useFilterItems(props)
  const { props: usersProps } = useUserData({
    ...genModelFilters(props.model, 'users'),
    model: 'users'
  })
  const { props: senderProps } = useUserData({
    ...genModelFilters(props.model, 'sender'),
    model: 'users'
  })
  const { props: destProps } = useUserData({
    ...genModelFilters(props.model, 'dest'),
    model: 'users'
  })
  const { props: badgesProps } = useBadgeData({
    ...genModelFilters(props.model, 'badges'),
    model: 'badgelists'
  })
  const { props: badgeLevelsProps } = useBadgeLevelData({
    ...genModelFilters(props.model, 'badgeLevels')
  })
  const { props: gamesProps } = useGameData({
    ...genModelFilters(props.model, 'games'),
    model: 'games'
  })
  const { props: catsProps } = useFeelingData({
    ...genModelFilters(props.model, 'cats'),
    model: 'cats'
  })
  const { props: scatsProps } = useFeelingData({
    ...genModelFilters(props.model, 'scats'),
    model: 'scats'
  })
  const { props: gendersProps } = useGenderData({
    ...genModelFilters(props.model, 'genders')
  })
  const { props: countriesProps } = useCountryData({
    ...genModelFilters(props.model, 'countries')
  })
  const { props: signalsProps } = useSignalsData({
    ...genModelFilters(props.model, 'signals'),
    model: 'signalTypes'
  })
  const { props: visaStatusProps } = useVisaStatusData({
    ...genModelFilters(props.model, 'visa_status')
  })
  const { props: messageStatusProps } = useMessageStatusData({
    ...genModelFilters(props.model, 'message_status')
  })
  const { props: videoStatusProps } = useVideoStatusData({
    ...genModelFilters(props.model, 'video_status')
  })
  const { props: videoTypeProps } = useVideoTypeData({
    ...genModelFilters(props.model, 'video_type')
  })
  const { props: dateRangeProps } = useDateRange({
    ...genModelFilters(props.model, 'date_range')
  })

  const { _window: _WINDOW } = useWindowDimensions()
  useQuerySync({ ...props, filterItems })

  return {
    filterItems,
    propsData: [
      usersProps,
      senderProps,
      destProps,
      gamesProps,
      gendersProps,
      countriesProps,
      signalsProps,
      visaStatusProps,
      messageStatusProps,
      videoStatusProps,
      videoTypeProps,
      catsProps,
      scatsProps,
      badgesProps,
      badgeLevelsProps
    ],
    dateRangeProps,
    _WINDOW,
    _minimize: props.minimizeWindowFilter
  }
}

function useFilterItems ({ onClear, onClick: applyFilter, onMinimize } = {}) {
  const [filterItems, setFilterItems] = React.useState({})

  const isEmptyFilterItems = React.useMemo(() => {
    return Object.keys(filterItems || {}).length === 0
  }, [filterItems])

  const sizeOfFilterItems = React.useMemo(() => {
    return Object.keys(filterItems || {}).length
  }, [filterItems])

  const updateFilterItems = React.useCallback(
    (...updates) => {
      if (updates.length > 0) {
        setFilterItems((data = {}) => {
          const filterItems = cleanEmptyFilterItems({
            ...data,
            ...updates.reduce((acc, { value, id, op, from } = {}) => {
              return {
                ...acc,
                [id]: op != null ? { op, value, from } : value
              }
            }, {})
          })
          if (typeof applyFilter === 'function') {
            applyFilter(filterItems)
          }
          return { ...(filterItems || {}) }
        })
      }
    },
    [applyFilter]
  )

  const deleteFilterItems = React.useCallback(
    (..._ids) => {
      if (_ids.length) {
        setFilterItems((data = {}) => {
          const filterItems = cleanEmptyFilterItems({
            ...data,
            ..._ids.reduce((acc, { id }) => {
              return {
                ...acc,
                [id]: null
              }
            }, {})
          })
          applyFilter(filterItems)
          return { ...(filterItems || {}) }
        })
      }
    },
    [applyFilter]
  )

  const clearFilterItems = React.useCallback(
    ({ _cleanQS = false } = {}) => {
      !isEmptyFilterItems && setFilterItems({})
      if (_cleanQS) {
        applyFilter({})
      }
    },
    [isEmptyFilterItems]
  )

  const closeFilterItems = React.useCallback(() => {
    clearFilterItems()
    if (typeof onClear === 'function') {
      onClear()
    }
  }, [onClear, clearFilterItems])

  React.useEffect(() => {
    onMinimize({ minimize: false, activeFilters: !isEmptyFilterItems })
  }, [isEmptyFilterItems])

  return {
    filterItems: {
      data: filterItems,
      size: sizeOfFilterItems,
      clear: clearFilterItems,
      close: closeFilterItems,
      delete: deleteFilterItems,
      update: updateFilterItems,
      isVoid: isEmptyFilterItems
    }
  }
}

function useQuerySync ({ active = '', visible = false, filterItems = {} } = {}) {
  React.useEffect(() => {
    if (visible) {
      const filter =
        LUtils.parseQueryFilter({
          filter: active
        }) || {}
      delete filter.user_visa

      const _lateUpdates = Object.keys(filter).reduce((_updates, _key) => {
        _updates.push({
          id: _key,
          ...(filter[_key].value
            ? { ...filter[_key] }
            : { value: filter[_key] })
        })
        return _updates
      }, [])
      if (_lateUpdates.length > 0) {
        filterItems.update(..._lateUpdates)
      }
    }
  }, [active, visible])
}

function useDateRange ({ include: dateRangeFiltersObj, meta = {} } = {}) {
  const data = React.useMemo(() => {
    return Object.keys(dateRangeFiltersObj || {})
      .filter(v => dateRangeFiltersObj[v])
      .map(date_range_key => meta[date_range_key])
  }, [dateRangeFiltersObj])
  return { props: { data } }
}

function cleanEmptyFilterItems (filterItems) {
  const { data, count } = Object.keys(filterItems || {})
    .filter(key => filterItems[key])
    .reduce(
      ({ data, count }, key) => {
        return {
          data: {
            ...data,
            [key]: filterItems[key]
          },
          count: count + 1
        }
      },
      { data: {}, count: 0 }
    )
  return count > 0 ? { ...data } : null
}

function genModelFilters (model, filter) {
  const include = (FILTERS['MODELS'][model] || {})[filter]
  const meta = include ? FILTERS['META'][filter] : {}
  return include
    ? {
        include,
        meta:
          typeof include === 'object'
            ? { ...meta, query: { ...(include['query'] || {}) }, model }
            : { ...meta, model }
      }
    : null
}

function useWindowDimensions () {
  const [dimensions, setDimensions] = React.useState({})

  const getWindowDimensions = React.useCallback(() => {
    return {
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    }
  }, [])

  React.useEffect(() => {
    function handleWindowResize (e = null) {
      if (e) {
        e.preventDefault()
      }
      setDimensions(() => getWindowDimensions())
    }

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    _window: {
      ...dimensions
    }
  }
}
