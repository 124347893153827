import React, { useEffect, useState } from 'react'

import { mapActions } from '../../store'

export default props => {
  const [id, setId] = useState(`switch-${props.field}${props.id || ''}`)
  const [value, setValue] = useState(props.value || 0)

  const switchField = mapActions('switchField')

  function onChange (value) {
    if (props.onChange && typeof props.onChange == 'function') {
      props.onChange(props.field, value)
    }
  }

  useEffect(() => {
    let { alias = [], value = 0, field } = props

    value = parseInt(value)

    window
      .jQuery(`#${id}`)
      .bootstrapToggle({
        on: alias[1] || 'Public',
        off: alias[0] || 'Brouillon',
        size: 'small',
        height: 30
      })
      .bootstrapToggle(value ? 'on' : 'off')

    window.jQuery(`#${id}`).change(function () {
      const v = window.jQuery(this).prop('checked') ? 1 : 0
      if (props.showAll && v != value) {
        switchField({
          model: props.model,
          data: { [field]: v },
          id: props.id
        })
          .then(r => {
            onChange(v)
            setValue(v)
          })
          .catch(e => {
            onChange(0)
            setValue(0)
          })
      } else {
        onChange(v)
        setValue(v)
      }
    })
  }, [props.field, props.value, props.alias])

  return (
    <>
      <input
        type='checkbox'
        id={id}
        ref={props.forwardRef}
        data-size='small'
        value={value || 0}
      />
    </>
  )
}
